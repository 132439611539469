import constants from '@/mixins/constants';

export default {
    changeEmail: {
        title: "Change Email address",
        Email: "Email address before the change",
        NextEmail: "Email address after the change",
        change: "Change",
    },

    changePassword: {
        title: "Register or change your password",
        Email: "Email",
        Password: "New Email",
        nextPassword: "New Password (Confirm)",
        dontUse: "Don't use the same password for other services.",
        update: "Update",
    },

    adminLogin: {
        changeEmailAddress: "Change Email address",
        changeEmailAddressPopup: "After submitting, an authentication email will be sent to you.",
        changeEmailAddressPopupHeader: "Change Email address",
        changePassword: "Change password",
        changePasswordPopup: "After submitting, an authentication email will be sent to you.",
        changePasswordPopupHeader: "Change register or password",
        invalidLogin: "Wrong email address or password",
        privacy: "Privacy",
        article: "Article",
    },

    admin: {
        willEndFormTemplate: "There is a form that will be closed soon. You can change the end date in \"Created\"."
    },

    invited: {
        registedSuccess: "Added a new user to your department(See \"List\")",
        registedFailed: "User registration failed.",
        registedDepartment: "Added a new department to your organization(See \"List\")\nNext, add a new user to this department",
        registedCompany: "Created a new organization (See \"List\")\nNext, add a new department to this organization",
    },

    draft: {
        saved: "Save",
        confirmed: "Confirmed (See \"Created\")",
        saved_detail: "To confirm, click the \"Confirm (To \"Created\")\" button",
        confirmed_detail: "Get the URL in the created status",
        garbage: "Moved to the \"Trash box\" (See \"Trash box\")",
        copied: "Copied (See \"Draft\")",
        requiredFieldError: "'Destination department' is required",
        header: {
            createdUserEmail: "Creator",
            createdUserEmailTooltip: "The user who clicked \"Create New\" \n* Refer to List (Change)> \"Organization\"> \"Department\"> Change User Information (Icon)",
            createdAndUpdatedDate: "Created/Updated Date:",
            templateId: "Template ID",
            formId: "Document ID (Issued upon receipt)",
            confirmedDate: "Confirmed Date:",
            garbageDate: "Trash Date:",
            templateIdInfo: "Unique ID issued when a document is created (1 ID per document)",
            formIdInfo: "Unique ID issued when a document is sent / received (1 ID per transmission / reception)"
        },
        overWriteConfirm: "Another user is editing this form on another screen. Overwrite it?",
        documentName: "Document Name",
        toDepartment: "To Department",
        publicPeriod: "Public Period", 
        publicRangeLabel: "Public range",
        publicRangeLabelInfo: "You can change this after publishing.\n(1) Show input form of \"Individual\" and \"Corporate\":\nBefore the input form is displayed, a screen to select \"Individual\" or \"Corporate\" will be displayed. When \"Individual\" is selected, a personal form (a form created by selecting \"Individual\" in \"Select application type\" below) will be displayed, and when \"Corporate\" is selected, a corporate form (a form created by selecting \"Corporate\" in \"Select application type\" below) will be displayed.\n (2) Show input form of \"Individual\": \nBefore the input form is displayed, a screen to select \"Individual\" or \"Corporate\" is not displayed, and a personal form (a form created by selecting \"Individual\" in \"Select application type\" below) is displayed.\n(3)  Show input form of \"Corporate\": \n Before the input form is displayed, a screen to select \"Individual\" or \"Corporate\" is not displayed, and a corporate form (a form created by selecting \"Corporate\" in \"Select application type\" below) is displayed.\n* Click \"Get URL\" > Paste the URL at the top of the browser > Switch between (1), (2) and (3) > Refresh the browser > You can check the difference in appearance between (1), (2) and (3).",
        canBeChangeAfterPublication: "You can change this after publishing.\nYou can specify the publication period.\n* Click \"Get URL\" > Paste the URL at the top of the browser > Change the disclosure period (before the end date and after the end date, etc.) > Refresh the browser > You can check the difference in appearance before the end date and after the end date.",
        type: "Type display (optional): ",
        typeNotion: "※The selected items will be displayed on the input screen.",
        selectAppType: "Select application type: ",
        kojin: "Individual",
        houjin: "Corporate",
        cantCopyInPreview: "※Cannot copy (because it is in preview)",
        cantSaveInPreview: "※Cannot save (because it is in preview)",
        previewing: "Previewing",
        example: "Example",
        add: "Add",
        preview: "Preview",
        temporarySaving: "Save",
        minConponentText: "At least one",
        copyHoujinTemplate: "Copy \"Corporate\"",
        copyKojinTemplate: "Copy \"Individual\"",
        copyAndOverWrite: "Copy and overwrite",
        deleteComponentDialogMessage: "Delete it ?",
        copyComponentDialogMessage: "Copy it ?",
        toDepartmentInfoTooltip: "To change the name of \"To department\", use \"List\"> Pen icon.  You can share this form with users in that department by selecting \"To Department\" and Saving.  If you delete the \"To Department\" and \"Save\" it blank, only you (the creator) can see this form.  Only the creator can select and save.",
        sortNumberLabel: "Sort key (by department)",
        sortNumberInfoTooltip: "A serial number will be issued for each department when the draft documents are finalized. By overwriting, you can customize the order of documents for each department.  ",
        controlTextLabel: "Control Number",
        failChangeToDepartment: "Only creator (the user who clicked “Create New”) can change the “To Department",

        draftPublicPeriodTooltip: "After clicking \"Confirm\", set/change in \"Created\".",
        isVerification: "Verification",
        publicFormat: "Format",
        isVerificationTooltip: "* By having the form sender authenticate the email address, you can send an email to the form sender after receiving the form.  \nForm senders can check the submitted form and status \"Sent (Unread)\", \"Sent (Read)\", \"Received (Read)\" by verifying their email address.",
        publicFormatTooltip: "When you publish a form, you can choose to publish it in form format or web page format (this can be changed after publishing).\n* Click \"Get URL\" > Paste the URL at the top of the browser > Switch between \"Publish in Form format\" and \"Publish in Web page format\" > Refresh the browser > You can check the difference in appearance.",
        publicFormatList: [
            {
                label: "Publish in Form format (Show \"Send\" button)",
                value: "form"
            },
            {
                label: "Publish in Web page format (Hide \"Send\" button)",
                value: "webpage"
            }
        ],
        verificationList: [
            {
                label: "Display the email address input screen before the form input screen",
                value: true
            },
            {
                label: "Do not display the email address input screen before the form input screen",
                value: false
            }
        ],
        toChangeRegistrationInfoLinkText: "Rename",
        defaultInbox: "In-tray sorting",
        defaultInboxTooltip: "The received form will be automatically sorted to the status/subfolder selected here.\n\nTo use this function, select the newly created status/subfolder from this pull-down after completing (1) and (2) below.\n (1) Select one department instead of \"All departments\" from the pull-down menu at the top left of the screen.\n (2) Click the icon to the right of the \"Create New\" button to create a new status/subfolder.",
        defaultInboxListBox: "Not specified (Inbox)",
        toChangeRegistrationInfoLinkTextClick: "Click the pen icon to rename",
    },

    postedFormScreen: {
        updateStatus: "Withdrawal completed",
        failedUpdateStatus: "Status has already been changed", 
        failedMoveStatus: "Failed to change the status",
        AcceptForm: "Accepted (See \"Accepted\")",
        RejectForm: "Rejected (See \"Rejeted\")",
        DoneForm: "Moved to the \"Storage\" (See \"Storage\")",
        DeleteForm: "Deleted (See \"Deleted\")",
        ButtonStatusText: {
            "Sent": "Sent",
            "Withdrawal": "Withdrawal",
            // "Accepted": "Accepted",
            // "Rejected": "Not accepted",
            "Accepted": "Received",
            "Rejected": "Received",
            "AlreadyWithdrawal": "Already withdrawn",
        },
        Withdrawal: "Withdrawal",
        confirmed: "Confirmed(See the added icon)",
        cancellation: "Cancelled(icon removed)",
        alreadyConfirmed: "Already confirmed",
        alreadyCancellation: "already cancelled",
        notSharedAndCantConfirm: "Not shared (invited). Cannot confirm.",
        header: {
            sendDate: "SendDate",
            templateId: "Template ID",
            formId: "Document ID",
            withdrawal: "Withdrawal (cancellation of transmission): ",
        },
        documentName: "Document Name",
        toDepartment: "To Department",
        publicPeriod: "Public Period", 
        type: "Type: ",
        selectAppType: "Select application type: ",
        kojin: "Individual",
        houjin: "Corporate",
        example: "Example",
        noEmailAddress: "From: None (Cause: The email address input screen is not displayed. See Created > Verification)",
        return2SentBox: "< Sent List",
        isWithdrawal: "withdrawal?",
        isReadForm: "Read",
        notIsReadForm: "Unread",
        BeforeDoneForm: "Move to \"Storage\"?\nAll operations will be disabled.",
        BeforeDeleteForm: "Move to \"Deleted\"?\nAll operations will be disabled.",
    },

    door: {
        Send: "Send",
        Authentication: "Verify",
        AuthenticationNumber: "Certification Number",
        inputAuthNumber: "Enter the \"Authentication Number\" provided in the email.",
        inputInfo: "Enter an email address where we can contact you.",
        ToInputPanel: "Authenticate your email and go to the input screen.",
        failedCertificationNumber: "Incorrectly entered or expired \nTry again.",

    },

    formValidationDoors: {
        inputInfo:  "Password protected.\nEnter your password.",
    },

    inputViewFooter: {
        text1: "We are not the author of this content.",
        author: "The creator of this content is {Author}",
        cantSendAmend: "Don't send a password",
    },

    inputForm: {
        topText: "Input only on this page",
        notion4Phone: "data may be automatically deleted during input if the memory capacity is low.",
        plsConfirm: "Please confirm. Not yet sent.",
        copy: "Copy",
        copyToBottom: "Copy to bottom",
        select: "Select",
        type: "Type: ",
        amend: "Change",
        finish: "Done",
        toConfirmPanel: "Go to confirmation screen",
        back: "back",
        Send: "Send",
        SendComplate: "Sent !",
        cantSendAmend: "Cannot send while changing",
        formStatus_Sending: "Sending",
        formStatus_End: "Sent",
        formStatus_Failed: "Failed to send",
        isForward: "You have not filled in the required fields. \nWould you like to proceed?",
        required: "required",
        optional: "optional",
        blank: "blank",
        kojin: "Individual",
        houjin: "Corporation",
        backToDoor: "Please try again from the authentication screen.",
        exceededMaxFileStorage: "Up to {MB}MB/file",
        inputDescriptionCopyEmailAgain: "Send a copy of the content again (multiple)",
        inputDescriptionCopyEmail: "Send a copy of the content (multiple)",
        descriptionCopyEmailPlaceholder: "Email address", 
        jump2Homepage: "Go to the link?",
        dataSaving: "Saving...",
        dataSaved: "Draft saved (file unsaved)",
        sendEmail2ExistUser: "(An auto-reply email was sent to you)",
        showQRcode: "Show QR code",
        importFormButton: "Copy",
        importFormButtonTooltip: "Click to ”Sign in” and this form will be copied to your ”Draft”. If you don't have an account, it will be copied to your ”Draft” as soon as you create a new account.",
        notSaved: "You can’t go back. Click “Cancel”.",
        selectUserAttrMessage: "Select \"Individual\" or \"Corporation\" to proceed.",
    },

    postedForm: {
        inputEmail: "Please enter the email address you verified when you sent the message.",
        sendMailFailed: "Please enter the email address that you authenticated at the time of submission."
    },

    top: {
        createNewCard: "Create new card",
        deleteCard: "Delete card",
        deleteCardForever: "Delete?",
        deleteCardForeverInfo: "You won’t be able to re-open the card.\nThere is no undo.",
        Company: "Organization",
        Department: "Department",
        Personal: "My Notes",
        createNewBoardList: "Create new list",
        deleteBoardList: "Delete this list",
        deleteBoardListConfirm: "Delete this list?",
        createNewBoardListInfo: "Create a list. The list will be shared by the members of the  \"organization/department\". The list of \"Personal\" can only be seen by you.",
        BoardTypeTextToolTip: {
            Company: "The following cards are shared with users in all \"departments\" of this \"organization\".\n*You can change the name of \"Organization\" in \"List (Change)\" at the bottom left.",
            Department: "The following cards are shared with all users in this \"department\".\n*You can change the name of \"Department\" in \"List (Change)\" at the bottom left.",
            Personal: "The following cards are only visible to you."
        },
        card: {
            Attachment: "Attachments",
            AddAttachment: "Attachments",
            Description: "Details",
            isNotSaved: "Not yet saved.\nSave button/Cancel",
            addAttachment: "Add an Attachment",
            deleteAttachment: "Delete",
            deleteAttachmentText: "Delete?\nOnce the attachment is deleted, it cannot be undone.",
            commentAttachment: "Comment",
            changeFileNameAttachment: "Change file name",
            setHeader: "Set header",
            removeHeader: "Remove header",
        },
        quickCard: {
            "pinButton": "Pin",
            "unPinButton": "Unpin",
            "labelButton": "Label",
            "moveButton": "Move",
            "deleteButton": "Delete",
            "open": "Open",
        },
        anotherUserModified: "Another user is editing on another screen. Overwrite it?\n(Recently edited by {USEREMAIL})",
    },

    EditCardDialog: {
        header: "Card",
        notion: "Select \"Organization\" to view all users in the organization, \"Department\" to view only users in that department, and \"Personal\" to view only yourself. If you select \"Personal\", only you can view the page.",
        reload: "Update",
        pinnedCard: "Fixed display ON",
        clearPinnedCard: "Fixed display OFF",
        tagPopPanel: {
            title: "Labels",
            info: "Click to paste, then click again to remove."
        },
        cardMovePopPanel: {
            title: "Move",
            info: "Specify the destination",
            updateButton: "Move",
            list: "List",
            position: "Position",
            moved: "Moved",
            isPined: " (pinned)",
            isCurrentCardNo: " (current)",
        },
        invalidFileNameLetter: "The following characters are not allowed in file names:\n{INVALID}",
        fileLength: "Maximum 255 characters",
        invalidTitleLength: "Up to " + constants.maxCardTitleLength.toLocaleString() + " characters",
        invalidContentLength: "Up to " + constants.maxCardContentLength.toLocaleString() + " characters",
    },

    downloadCsvDialog: {
        header: "Download",
        infoText: "Download the CSV file and attachments of the selected documents as a ZIP file.<br>CSV file: Downloaded for each \"Template ID\" and \"Corporation\" or \"Individual\" <br> Attachment file: Downloaded for each \"Document ID\"",
        selectCharacterCode: "Select character code",
        download: "Download",
    },

    stampedUserListOverPanel: {
        confirmedUser: "Checked User",
        notConfirmedUser: "Unchecked users",
        toShareView: "Click here to see the departments and people who share this document.>>",
    },

    formDataTable: {
        SortNumberByTemplate: "Control Number",
        Filter: "Filter",
        ForgetFilter: "Not found. No data or forgot to unfilter.",
        Cancel: "Cancel",
        Clear: "Clear",
        DocumentName: "Document Name",
        ControlText: "Control Number",
        SentDocumentName: "Document Name",
        ToDepartment: "To Department Name",
        ToCompanyName: "To Organization Name",
        OpenBegin: "Start",
        OpenEnd: "End",
        UpdatedDate: "Created/Updated Date",
        TemplateNote: "Note(1)",
        ConfirmedUserDisplayInCreated: "Checked",
        FromEmail: "From Email (verified)",
        CreatorName: "Creator",
        ConfirmedUserDisplayInReceived: "Checked",
        SendDate: "Send Date",
        FormNote: "Note(2)",
        isNotSave: "Not saved. Click \"Cancel\" to go back to save, or click \"OK\" to proceed without saving.",
        isNotSaveClickRow: "Not saved.",
        isNotSaveAccept: "Proceed without saving",
        isNotSaveBack: "Go back to save",
        SenderFormNote: "Sender's Note",
        FormStatusInSender: "Status",
        NotShowFromEmail: "None (no email authentication)",
        SenderScreen: "Sent",
        IsShare: "Sharing with users other than the users in the \"To Department\" (For invited departments and individuals, see \"List\" > \"List of users by document\" tab)",
        blankCreatorName: "(The user's name is not registered)",
        blankDepartmentName: "(The department name is not registered)",
        blankCompanyName: "(The organization name is not registered)",
        isCreatorName: "The user who clicked \"Create New\" \n* Displayed when \"To department\" is selected and temporarily saved\n* Refer to List (Change)> \"Organization\"> \"Department\"> Change User Information (Icon)",
        selectAll: "All",
        selectAllInfo: " (1)If you uncheck even one, change to select all on the page \n(2)if you move to the page, deselect all",
        unSelectAll: "None",
        FromEmailInfo: "(1) You can send an email to \"From Email (verified)\", and you can check the sent email in the \"Sent Mail List\". (2) \"From Email (verified)\" is displayed only in forms published with \"Display email address input screen before form input screen\" selected (\"Created, URL\" > Select each form > \"Verification\").",
        SentEmailTooltipText: "E-mail has been sent (see \"Sent Mail List\")",
        IsBell: "It is set to receive an email when this form is received, withdrawn, or at the end of the publishing period.",
        globalSearch: "Search (filter) the Document Name, Control Number, To Department Name, To Organization Name, Note, From Email, Template ID and Document ID of the forms in the selected status.",
        globalSearchInputTextTooltip: "Press Enter to search forms",
        rowEnd: "**END**",
        IsPasswordLockedTooltip: "The setting to display the password input screen for viewers and form fillers is \"ON\".",
        IsBeforeShowMessageTooltip: "The setting to display a message/CAPTCHA, etc. before viewing/inputting is set to \"ON\" for viewers/form-fillers.",
        IsAfterShowMessageTooltip: "The setting to display a message after sending is set to \"ON\" for viewers and form fillers",
    },

    operationButtonBar: {
        editURLButtonText: "Specify the URL for publication",
        passURLButtonText: "Switch the form",
        garbageBox: "To \"Trash box\"",
        copy: "Copy",
        confirm: "Confirm(To \"Created\")",
        invite: "Invite",
        notifications: "Notifications",
        messageAfterFormSubmission: "Message after sending",
        shortUrl: "Shorten the URL",
        confirmedUserDisplayInCreated: "Checked",
        confirmedUserDisplayInReceived: "Checked",
        cancelConfirmed: "Undo",
        cancelConfirmedInReceived: "Cancel",
        getURL: "Get URL",
        replaceURL: "Replace",
        toTemplateListText: "The link to this form/webpage is below. Copy this link and share it on your website, email, etc.\n* Passwords, terms of use, CAPTCHAs, messages, and notifications can be added or changed after the link is shared.",
        toTemplateListText2: "After the applicant clicks on this link, they will be asked to authenticate, enter, and submit their email",
        garbageTemplateLinkText: "The link to this form/webpage is below. Copy this link and share it on your website, email, etc.\n* Passwords, terms of use, CAPTCHAs, messages, and notifications can be added or changed after the link is shared.",
        garbagedFormURLTextInfo: "This URL will link to the form that has stopped publishing or the form after switching.",
        toPreviewWindowLinkText: "A link to preview this document. Copy this link and access it on the web or smartphone.",
        toEmailURL: "Authenticate form sender email address",
        reject: "Reject",
        accept: "Accept",
        toReject: "To \"Rejected\"",
        toAccept: "To \"Accepted\"",
        toDeleted: "Delete",
        toDone: "To \"Storage\"",
        sendAcceptedEmail: "Mail (Accept)",
        sendRejectedEmail: "Mail (Reject)",
        sendDonedEmail: "Mail (Done)",
        moveToGarbege: "Move to \"Trash box\"?\nPublishing will be stopped and all items such as \"Public period\" will not be able to be changed.",
        alreadySent_once: "Already sent it. Do you want to send it again?",
        cautionConfirmTemplateForm: "Either \"Individual\" or \"Corporate\" or both may not have been created. \nAre you sure?",
        cautionConfirmTemplateForm2: "Publication start date: Specify a date after the present\nPublication end date: The date that can be specified depends on the price plan.",
        cautionConfirmTemplateForm2: "See \"Created\", you can do the following.\n\n(1) Notification settings\n(2) Getting public/shared links\n(3) Public Period settings\n      *Publication start date: Specify a date after the present\n      *Publication end date: The date that can be specified depends on the price plan.",
        previewURL: "Preview URL",
        overwriteURL: "Change/overwrite URL",
        ope: "ope",
        toEnableSkipEmailAuth: "* By asking the form sender to authenticate the email, the form sender can receive the email after submitting and view the form sent from the link on the email.",
        toEnableSkipEmailAuthTooltip: "* If the form sender does not authenticate the email, the form sender will not be able to see the form submitted after submission.",
        previewURLInfo: "After seeing the preview screen, if you need to make corrections, return to this screen and make corrections. After making corrections, click \"Save\", look at the preview screen again, and click the refresh button on your browser to reflect the corrections. When the modification is completed, click the \"Confirm (To \"Created\")\" button to move it to \"Created\" (it can not be overwritten).",
        overwriteURL: "Change/overwrite URL",
        sentNormalBulk: "Send bulk email",
        moveDetailStatusForm: "Move",
        cantMoveDetailStatus: "There is no destination status / subfolder (You can set it by clicking the icon next to the \"Create New\" button)",
        copiedQrcode: "QR code copied",
        qrcodeTooltip: "Click to copy this QR code (png)",
        addPasswordTooltip: "Add Password",
        messageBeforeShowFormDialog: "Message to viewers and form fillers (Terms of use, CAPTCHA, etc.)"
    },

    previewWindow: {
        saveConfirm: "Changes are not reflected. Click \"Save\" and then click again."
    },

    EditURLDialog: {
        header: "URL change/overwrite",
        topText: "You can specify the URL to be used for publishing. If you specify the URL that is already in use (URL inheritance), the form published at that URL will be suspended and moved to the \"Trash box\". URL inheritance can only be done in the same form as the \"To department\". When you take over, you will see a confirmation pop-up with a form that will stop publishing.",
        bottomText: "Publish the form at this URL. If this URL has already been used to publish another form, that form will be hidden and the new form will be displayed (old form will be unpublished & moved to “Trash box”).",
        Publish: "Publish at this URL",
        confirmPopupText: "Currently, \"{DOCUMENTNAME}\" is available at this URL. Do you want to overwrite? If you overwrite, this form will be unpublished and moved to the \"Trash box\".",
        overwrite: "Overwrite",
        urlEditInfo: "* Half-width alphanumericals, Japanese, etc. can be used (at least 1 characters)",
    },

    PassURLDialog: {
        header: "Replacement of this form",
        PublishButtonText: "Replace",
        info: "Select the post-replacement form from the following. You can replace to the form of the same \"To Department\" in \"Created\" status. After replacing the form, the pre-replacement form will be stopped from publishing and moved to the \"Trash box\".",
        passedTemplate: "The post-replacement form",
        passingTemplate: "The pre-replacement form",
        SuccessPublish: "The replacement of this form has been completed.\nMoved the pre-replacement form to \"Trash box\".",
        beforePublishingConfirm: "Due to the ”Replace”, the settings (”Invite”, Password, Messages, Notifications) before replacement will be reset. Please make a note if necessary. Please set again after replacement.",
    },

    mailDialog: {
        header: {
            sentEmail: "Sent Email",
            receivedEmail: "Send email",
            notReceivedEmail: "Send email",
            doneEmail: "Send email"
        },
        WriterBrowserLanguage: "Recipient language is {WriterBrowserLanguage}",
        sendDate: "Send Date: ",
        subject: "Subject: ",
        sendEmailDialodText1: "Send the following email.",
        sendEmailDialodText2: "You can add a reason, etc.",
        forceClose: "Close (will not be saved)",
        FromNameInfo: "You can overwrite the \"From\" displayed to the mail recipient.",
        ReplyToAddressInfo: "If you do not receive a reply from the email recipient, use \"no-reply{'@'}bankinvoice.com\". If you receive a reply from an email recipient, use your email address as the reply email address.  ",
        ToBulkMailText: "(Each email is addressed to \"From Email (verified)\" on each form)",
        BCCEmailsPlaceholder: "E-mail address (If there are multiple, separate them with \",\")",
        blankBulkCompanyName: "(\"To Organization Name\" of each form is displayed.) ",
        bulkMailInfo: "Emails will be sent to the email address displayed in \"From Email (verified)\" of the selected form. \nNo email will be sent to forms that display \"None (no email authentication)\" in \"From Email (verified)\".",
        from: "Sender:",
        replayTo: "From,Reply To:",
        bulkFrom: "Sender:",
        bulkReplayTo: "From,Reply To:",
    },

    templateNote: {
        placeHolder: "Notes field (will not be published)",
        anotherUserEditted: "Another user is editing on another screen. Overwrite it?\n(Recently edited by {USEREMAIL})",
    },

    allDepartmentUser: {
        departmentHeader: "List of users by department by organization ({count} department)",
        documentHeader:"List of Shared users by form ({length} document)",
        dontShowDocument: "Documents are not displayed\nDocuments that have not been shared will not be displayed.",
        focusedDocument: "The document name is highlighted in yellow. Scroll down if you can't find it. See above for sharer.",
    },

    allDepartmentUserDataTable: {
        what: "A list of \"organizations\", all \"departments\" of that organization, and \"users\" belonging to each department. Here you can do the following: <br>(1) Add a \"user\" to the \"department\" (User--Plus icon) <br>(2) Register a new \"department\" (+ icon)<br>(3) Change department name or organization name (pen icon), etc.",
        onlyOne: "Currently you are not a member of any organization or department.",
        empty: "Currently you are not a member of any organization or department and cannot create or view forms until you receive an invitation to join a department from another user.",
        header: {
            name: "Organization/Department/User Name",
            userDepartment: "User Department",
            userEmail: "Email",
            changeCompanyNameTooltip: "Organization name change (by users in this organization)",
            changeDepartmentNameTooltip: "Department name change (by users in this department and users in the \"operation department\")",
            changeUserInfoTooltip: "Change user information (by users in this department and users in the \"operation department\")",
            deleteUserInfoTooltip: "Delete (by users in this department and users in the \"operation department\")",
            AddNewDepartmentTooltip: "Add new department (by users in operations department)",
            AddNewUserTooltip: "Add new user (by users in this department and users in the \"operation department\")",
            changePricePlan: "Change price plan (by users in this department and users in the \"operation department\")",
            IsCompany: "Organization",
            IsOPEDepartment: "Operation Department",
            IsDepartment: "Department",
            blankCreatorName: "(The user's name is not registered)",
            blankDepartmentName: "(The department name is not registered)",
            blankCompanyName: "(The organization name is not registered)",
            InfoDepartment_OPE: "Users belonging to the \"operation department\" can do the following.\n(1) Addition of other departments\n(2) Add new users to other departments\n(3) Withdrawal of users from other departments\n(4) Organization name change\n(5) Rename of this department\n(6) Add new users to this department\n(7) Change of rate plan for this department\n(8) Change the names, departments, and emails of other users in this department.\n(9) Withdrawal of other users in this department",
            InfoDepartment: "Users belonging to the \"department\" can do the following:\n(1) Organization name change\n(2) Rename of this department\n(3) Add new users to this department\n(4) Change of rate plan for this department\n(5) Change the names, departments, and emails of other users in this department.\n(6) Withdrawal of other users in this department",
        },
        companyNameInfoDialog: {
            header: "Users registered with this organization can change the organization name.",
            companyName: "Organization Name"
        },
        departmentNameInfoDialog: {
            header: "Users registered in this department can change the department name.",
            departmentName: "Department Name",
            departmentCode: "Department Code"
        },
        userInfoDialog: {
            header: "User information",
            name: "Name:",
            department: "User Department:",
            useCaseName: "* Displayed as \"Creator\" in \"Draft\"",
        },
        editInfoDialog: {
            header: "Organization and Department",
            companyName: "Organization Name",
            departmentName: "Department Name",
            departmentCode: "Department Code",
            changeableAtAnyTime: "Changeable after registration",
        },
        deleteUser: "Delete this user ?",
        cantChangeDepartmentName: "You cannot change the name of a department that you do not belong to.",
        cantChangeCompanyName: "You cannot change the name of an organization to which you do not belong.",
        pleaseUpdateInit: "Please register the name of the organization and department to which you belong"
    },

    FormDataTable: {
        dontExistCompany: "An organization that no longer exists or a different organization",
        dontExistCompanyName: "Organization name has changed",
        dontExistDepartment: "A department that no longer exists or a different organization",
        dontExistDepartmentName: "The department name has changed",
        LinkDepartmentToAllInfo: "Link to list? \nYou can:\n- Check the users belonging to the department\n- Change Department Name\n- Change User information, Add/Delete User\n- Change rate plan, etc.\n* If the department name has been changed, it will link to the renamed Department.",
        LinkCompanyToAllInfo: "Link to list? \nYou can:\n- Check the Departments belonging to the Organization\n- Change Organization Name\n- Change Department information, Add/Delete Department\n- Change rate plan, etc.\n* If the Organization Name has been changed, it will link to the renamed Organization.",
        LinkToAllInfo: "Do you want to link to the list?"
    },

    invitedFormDataTable: {
        what: "A list of forms that appear in \"Created\" and you can share (some forms, not all forms in your organization) and a list of users sharing those forms. <br> The users who share each form are either (1) users in the \"To department\", (2) users in the department invited to the form, or (3) users invited to the form. <br> For the user of (1), refer to \"List of users by department by organization\". For users (2) and (3), refer to the breakdown for each form below.",
        documentName: "Document Name",
        departmentName: "To Department Name",
        companyName: "To Organization Name",
        cancelShare: "Unsharpen this document.",
        successRemoveInvitedUser: "Unshared this document",
        isInvitedDepartment: "Invited department",
        isInvitedUser: "Invited user",
        isInvitedUserName: "(Share this form personally)",
        showRemoveUserInTemplate: "Unshare\n * To share (invite), \"Created\"> Select this form> Click \"Invite\""
    },

    sentEmailView: {
        title: "List of sent Emails (45 days)",
        info: "You can click to see the details.",
        copyTable: "Copy this page",
        copedTable: "Copied!",
        toEmail: "To",
        subject: "Subject",
        sendUser: "Sender",
        sendDate: "Send Date",
        content: "Contents",
    },

    sentBox: {
        plzPC: "On smartphones, only sent documents can be viewed. Sign in on your PC to create and view the documents you have received."
    },

    inviteUserView: {
        title: "User Registration",
        info: "Select the \"Organization Name\" and \"Department Name\" for the user to be registered, and enter the user's e-mail address and name.  You can see the registered users in the menu > List (change) (you can also cancel the registration or make other changes).  *If there is no department to register in the \"Department Name\", you can create (add) a new department by going to Menu > Register Department.<br>*You can change the department name in Menu > List (change). ",
        organizationName: "Organization Name",
        departmentName: "Department Name",
        email: "Email",
        name: "Name",
        inviteEmailText: "※A password notification email will be sent to the user.",
        register: "Registration",
        departmentEmptyMessage: "Select an organization first",
        companyEmptyMessage: "Currently you are not a member of any organization or department and cannot create or view forms until you receive an invitation to join a department from another user.",
    },

    createDepartmentView: {
        title: "Department Registration",
        note: "Add a new department in the following organization (organization name can be changed in \"List (change)\" )",
        note2: "Only users of the Operation Department can register (add) departments.",
        organizationName: "Organization Name",
        departmentName: "Department Name",
        departmentCode: "Department Code",
        optional: "optional",
        newDepartmentNameInputPlaceholder: "Name of the new department to be created.",
        newDepartmentCodeInputPlaceholder: "Code of the new department to be created.",
        register: "Registration",
        doneCreatingDepartmentInfo: "After \"Department Registration\", click List > Organization, confirm that the department was created, and add a user (User--plus icon)."
    },

    createOrganizationView: {
        title: "Organization",
        organizationName: "Organization Name",
        departmentName: "Department Name",
        register: "Registration",
        DepartmentNamePlaceholder: "Enter the Operation Department Name",
    },

    inviteUserDialog: {
        header: " Invite departments and individuals to share the selected documents\n Select the department or enter the email address of the individual in the pull-down menu below.\n*The user or individual in the invited (shared) department will be able to \"View\" the created document,\n\"View\" the received document, and \"Invite\" the user or individual.",
        invite: "Invitation",
        shareMemberText: "The department and co-owner with whom you are currently sharing this document",
        department: "Destination department",
        creator: "(Creator)",
        sharedDepartment: "(Department)",
        sharedUser: "(User)",
        toShareView: "Click here to see the departments and people who share this document.>>",
        inviteByEmail: "Invite By Email ",
        isDeleteShareMember: "Unshare this document",
        unShareButtonTooltip: "Unshare",
        inviteFieldPlaceholder: "Enter the department name or email address to invite",
    },

    formNotificationDialog: {
        title: "notifications",
        emailFormText: "You will receive an email notification when this form is received, withdrawn, or nearing the end of the public period. Checked users can receive notifications, unchecked users cannot.",
        addMember: "Add users to receive notifications >",
        createWebhook: "Create Webhook",
        cancel: "Close",
        blankCreatorName: "(The user's name is not registered)",
        emailFormTextInfo: "You can receive notifications on this form in the following ways: \n(1) Click \"List\" and you are invited to \"To Department\" of this form. \n(2) Click the \"Invite\" button on this form, and your department receives the invitation or you personally receive the invitation.",
        notworkingByPublic: "This form will not be submitted because \"Publish in Web page format(Hide \"Send\" button)\" is currently selected.",
    },

    setting: {
        title: "Setting",
        lang: {
            label: "language"
        },
        update: "Update",
        otherSetting: "setting",
    },

    accountSetting: {
        setting: "Setting",
        workspace: "Work space",
        upgrade: "Upgrade",
        signout: "Sign out",
        allDepartment: "All Departments",
    },

    workspace: {
        select: "select",
        useSelectedWorkspace: "display the organization you selected"
    },

    policy: {
        title: "Terms of Use: BankInvoice Paper ＊＊＊＊＊ City",
        subTitle: "＊＊＊＊＊ Municipality (hereinafter referred to as \"the Municipality\") BankInvoice Paper ＊＊＊＊＊\" is an official service provided by ＊＊＊＊＊ (hereinafter referred to as \"the Municipality\") to its citizens as part of ＊＊＊＊＊＊＊＊＊＊, which allows citizens to apply to the Municipality using an electronic data processing system (hereinafter referred to as \"electronic application\"). This service is provided by the municipality as part of its  program. Bank Invoice Co. Bank Invoice Co., Ltd. develops and provides the system necessary for this service.",
        subTitle2: "In order to use this service, you must agree to all of the terms and conditions of the following Terms of Use (\"Terms\"). In order to use this service, you must agree to all of the terms and conditions of the following Terms of Use (the \"Terms\"). Please be sure to read and understand the Terms before using the Service.",
        article1: "Article 1 (Purpose)",
        article1_li: "The purpose of this agreement is to set forth the necessary matters regarding the conditions of provision and the relationship of rights and obligations regarding this service between the municipality and the users of this service, and it applies to all relationships between the users and the municipality regarding the use of this service.",
        article2: "Article 2 (Agreement to this Agreement)",
        article2_li: "Users must use the Service in accordance with the provisions of this Agreement. Users of the Service shall be deemed to have agreed to all of the provisions of this Agreement at the time they begin using the Service.",
                article2_li_liList: [
            "To provide and develop our products and services",
            "To provide information about our products and services and various campaigns",
            "To conduct marketing, research and analysis for the purpose of improving and enhancing the quality of our products and services",
            "To provide our maintenance and support services",
            "To notify you of changes to the terms and conditions of our services",
            "To respond to violations of our terms and conditions, policies, etc. (hereinafter referred to as \"terms and conditions, etc.\") regarding our services. To respond to violations of our terms and conditions, policies, etc. (hereinafter referred to as \"Terms and Conditions\")",
            "To confirm the identity of the account holder at the financial institution",
            "To confirm the transfer of funds to financial institutions",
            "To contact you in case of emergency",
            "For other purposes incidental to the above purposes of use",
        ],
        article2_li_liList_length: "10",
        article3: "Article 3 (Changes to the Purposes of Use of Personal Information)",
        article3_li: "The Company may change the purpose of use of personal information to the extent that it is reasonably considered to be of considerable relevance, and in the event of such change, the Company will notify the customer or make a public announcement if the Company deems the change to be of high importance." ,
        article4: "Article 4 (Registration of BankInvoice Paper Account, etc.)",
        article4_li: " In order for a user to make an application on the Service, the user must register for a shared account provided by Bank Invoice Corporation (\"BankInvoice Paper Account\"). (hereinafter referred to as \"BankInvoice Paper Account\") or an email address that can be received by the user. In order to register for a BankInvoice Paper Account as specified in the preceding paragraph, the user must agree to the BankInvoice Paper Account Terms and Conditions (https://＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊) in advance. ",
        article5: "Article 5 (Scope of Application)",
        article5_li: "In addition to these Terms of Use, the rules and various regulations (including help and FAQ) regarding the use of the Service posted in the Service. These rules and regulations (including help and FAQ, hereinafter referred to as \"individual regulations\") constitute part of these Terms. In addition to these Terms, the rules and regulations (including help and FAQ) for using the service posted within the service (hereinafter referred to as \"Individual Regulations\") shall constitute a part of these Terms. In the event that the contents of these Terms differ from the contents of the Individual Rules, the provisions of the Individual Rules shall take precedence." ,
        article6: "Article 6 (Handling of Personal Information)",
        article6_li: "The handling of the user's personal information by this municipality is in accordance with the \"＊＊＊＊＊ Municipal Personal Information Protection Ordinance (Ordinance No. 11 of 2004)\" and the handling of that personal information by Bank Invoice Corporation, which provides the system necessary for this service, is in accordance with the \"Bank Invoice Ltd. has published on its website, \"Privacy Policy and Handling of Personal Information\" (https://＊＊＊＊＊＊＊＊＊＊＊＊＊＊). The user agrees that the municipality and Bank Invoice Co., Ltd. will handle the user's personal information in accordance with the respective regulations. In addition, the personal information of the user obtained through this service will be used for the purpose of administrative processing of the application using this service." ,
        article7: "Article 7 (Receipt of Application)",
        article7_li: "When a user submits an electronic application to the municipality using this service, such electronic application shall be deemed to have reached the municipality when it is recorded in the file on the computer used by the municipality during office hours, or on the following office opening day after office hours. In the event of an emergency, it shall be deemed to have reached the municipality on the following day.",
        article8: "Article 8 (Suspension of Service, Suspension, etc.)",
        article8_li: "The municipality may suspend, discontinue, or interrupt the provision of all or part of this service without prior notice to the user in any of the following cases When it is necessary to maintain or repair the Service. In the event of an emergency inspection or maintenance of the computer system related to the Service. When computers or communication lines are stopped due to an accident. When the operation and provision of this service cannot be performed due to force majeure such as earthquakes, lightning, fires, windstorms, power outages, natural disasters, and terrorism. In any other cases where the municipality reasonably deems it necessary to stop, suspend, or discontinue the service. The municipality shall not be liable for any damage caused to the user as a result of the suspension, cessation, or interruption of this service in accordance with the preceding paragraph, unless there is a reason attributable to the municipality." ,
        article9: "Article 9 (Change of service contents and termination of service)",
        article9_li: "The municipality may, at its discretion, change the contents of this service or terminate the provision of this service without prior notice to the user. The municipality shall not be liable for any damages caused to users or other third parties due to the actions taken by the municipality in accordance with the preceding paragraph, unless there are reasons attributable to the municipality." ,
        article10: "Article 10 (Disclaimer)",
        article10_li: "However, the municipality does not guarantee that the service is free from programming errors and other defects, that it has the functions, accuracy, and usefulness that users expect, that it is free from defects, or that its contents are accurate and safe. The Company does not guarantee the accuracy and safety of the contents of this service, and assumes no responsibility whatsoever. The use of this service is at the user's own risk, and the municipality shall not be held responsible for any damages allegedly incurred by the user due to the use of this service, unless there is a reason attributable to the municipality. You are responsible for your own decision to use this service, and the municipality shall not be responsible for any damage that you may have suffered as a result of using this service, except for reasons attributable to the municipality. Depending on your terminal or browser version, you may not be able to use all or part of this service properly. The municipality is not obligated to repair, maintain, or update the service. The municipality shall not be responsible for any damage caused to the user due to the user's inability to use this service due to bak, inadequate functions, errors, failures, communication conditions, or upgrading of the OS of the terminal, unless there are reasons attributable to the municipality. In no event shall the municipality be liable for any damages caused by the user's inability to use this service, except for reasons attributable to the municipality." ,
        article11: "Article 11 (Changes to this Agreement, etc.)",
        article11_li: "The Municipality may, when it deems necessary, change this agreement or add new provisions in accordance with the provisions of the law. The user agrees that the revised terms and conditions shall be applied from the date when the municipality makes the revised terms and conditions known to the user by an appropriate method, or from the date of commencement of the application of the revised terms and conditions." ,
        article12: "Article 12 (Governing Law and Jurisdiction)",
        article12_li: "The validity, interpretation and performance of these Terms shall be governed by and construed in accordance with the laws of Japan. Any and all disputes arising out of or relating to the Service or these Terms shall be subject to the exclusive jurisdiction of the Tokyo District Court as the court of first instance." ,
    },

    xtype: {
        textField: {
            title: "1 item 1 input",
            unit: "Unit",
        },
        textField2: {
            title: "1 item 2 input",
        },
        textField3: {
            title: "1 item 3 input",
        },
        textAreaField: {
            title: "1 item 1 input (long sentences supported)"
        },

        multiSelectField: {
            title: "Pull-down (multiple selection)",
            placeHolder: "Please select (multiple selections)",
            // optionLabel: "Choice {n} (enter choices one per line, select one)",
            optionLabel: "Choices are created by line breaks",
            otherLabel: "Other",
            otherInputPlaceholder: "If you select \"Other\", please fill in the details in this blank.",
        },
        dropDownField: {
            placeHolder: "Please select one (select one)",
            title: "Pull down (select one)",
            // optionLabel: "Choice {n} (enter choices one per line, select one)",
            optionLabel: "Choices are created by line breaks",
            otherLabel: "Other",
            otherInputPlaceholder: "If you select \"Other\", please fill in the details in this blank.",
        },
        checkBoxGroupField: {
            title: "Checkbox (multiple selection)",
            otherLabel: "Other",
            otherInputPlaceholder: "If you select \"Other\", please fill in the details in this blank.",
        },
        radioBoxGroupField: {
            title: "Radio button (select one)",
            otherLabel: "Other",
            otherInputPlaceholder: "If you select \"Other\", please fill in the details in this blank.",
        },
        yearDateField: {
            title: "Date",
        },
        timeField: {
            title: "Time",
        },
        timeRangeField: {
            title: "Time range",
        },
        periodDateField: {
            title: "Period",
        },
        dateField: {
            yearTitle: "Date",
            periodTitle: "Period",
        },
        fileField: {
            title: "Files can be attached",
            enable: "\n-\nPDF,OpenDocument Format (odt,ods,odp)\nPNG,GIF,JPEG,BMP,TIFF\ntxt,CSV\nWord 2003(doc)/After 2010(docx,docm),Excel 2003(xls)/After 2010(xlsx,xlsm),Powerpoint 2003(ppt)/After 2010(pptx,pptm),Rich Text Format(rtf)\n (jtd),(jhd),(jsd)\nMP3,MP4,Windows Media (wma),QuickTime (mov)\nJW_CAD (jww),DXF,AutoCAD (dwg),SFC,P21",
            label: "Select File",
            invalidFile: "Invalid file",
            tooLongFile: "File name up to {LEN} character",
            download: "Download the file",
            clear: "Clear"
        },
        editor: {
            title: "Display of notes, guidance, texts, etc. \n* For form creators. After publication, the input person cannot input or overwrite (CSV download is not possible).",
            iconText: "Non-editable by Viewers and Form Fillers"
        },
        editor4Applicant: {
            title: "Free entry field \n* For form fillers. After publication, the input person can input and overwrite (CSV download is not possible).",
            iconText: "Editable by Form Filler"
        },
        blankText: "This field is not displayed if left blank",
        item1Text: "Question/Request(This field is not displayed if left blank)",
        sortIconTooltip: "Move up and down",
        removeComponentButtonTooltip: "Remove",
        copyButtonTooltip: "Copy",
        addButtonTooltip: "Add input field",
        upComponentButtonTooltip: "Move up one",
        downComponentButtonTooltip: "Move down one"
    },

    messageAfterSendingFormDialog: {
        title: "Message after sending",
        info: "The following message will be displayed on the screen that appears after a form that has been \"Publish in Form format\" is submitted. Enter your message.",
        placeholder: "Thank you for submitting.  Next, Fill out the form below and send it.\nhttps://paper.bankinvoice.com/*******",
        notworkingByPublic: "This form will not be submitted because \"Publish in Web page format(Hide \"Send\" button)\" is currently selected.",
    },

    EditFormPasswordDialog: {
        header: "Password protected (restricted access)",
        info: "In order to limit the users to be published, the password input screen is displayed first(overwriteable). If it is \"ON\", only people who know the password can see the link destination after clicking the link. If it is \"OFF\", anyone who clicks the link can see the link destination.",
        copyButton: "Copy",
    },

    utils: {
        success: "Success",
        failed: "Fail",
        error: "Error",
        update: "Updated",
        registerAndUpdate: "Registered/Updated",
        isUpgrade: "Upgrade?",
        register: "Register",
        details: "Details",
        send: "Send",
        save: "save",
        close: "close",
        delete: "Delete",
        change: "Change",
        submit: "Submit",
        cancel: "Cancel",
        ok: "OK",
        later: "Later",
        retry: "Try again",
        sendMailSuccess: "Mail has been sent",
        sendMailSuccessPostStore: "Mail has been sent (See \"Sent Mail List\")",
        sendMailFailed: "Failed to send mail",
        sendMailFailedByForm: "Missing 'From email' or other reason",
        sendError: "Send Error",
        registed: "Registered (to list)",
        copied: "Copied",
        sendMailForUserVerify: "An email was sent to verify your identity",
        deleteSuccess: "Deleted",
        invalidEmailAddress: "The email address is not valid",
        invalidMaxTextLength: "Maximum length is " + constants.maxTextLength + " characters",
        anyBlank: "Please enter",
        finishedFormText: "Publication has been suspended or terminated.",
        beforePublicFormText: "Before the start of publication",
        notSaved: "Will not be saved",
        createNewTemplate: "Create New",
        department: "department",
        document: "document",
        appType: {
            new: "New",
            update: "Update",
            change: "Change",
            abolished: "Abolished",
            other: "Other",
        },
        isInitTemplateConfirm: "Either the individual or the corporation has not been created. Confirm?",
        signout: "Sign out",
        next: "Next",
        Yes: "Yes",
        No: "No",
        refresh: "Refresh",
        notFoundFormText: "You can't find the linked Paperer(form)",
        notFoundFormText2: "(URL: {LINK})",
        clear: "Clear",
        notFound: "Not found.",
        selectAForm: "Click the form above",
        back: "Back",
        save_ctrl: "Ctrl+Enter to Save",
        backInInputForm: "Back?",
        reconnecting: "Reconnecting.",
        disconnected: "You are not connected to the Internet",
        moveThisPage: "Would you like to move from this page?",
        password: "password",
        blank: "blank",
    },

    logout: {
        logoutConfirm: "Logout"
    },

    upgradePopup: {
        upgradeButton: "Upgrade >",
    },

    selectMovedDetailFormStatusDialog: {
        title: "Move To"
    },

    menuPanelConfigPanel: {
        info: "(1) You can create a status/subfolder for each status.\n(2) You can receive the form in the status/subfolder created in \"Inbox\".(\"Created, URL\"> Select each form> \"In-tray sorting\")",
        subfolderInfo: "(1) For example, in the \"Inbox\", add statuses such as \"Confirming\", \"Approved by Mr. A\", and \"Approved by Mr. B\", or add subfolders such as \"Mr. A\" and \"Mr. B\" in the \"Inbox\".  (2) You can set the received form to be automatically sorted to the status / subfolder created here instead of \"Inbox\" (\"Created\" > \"In-tray sorting\" for each form > select status/folder).",
        whatDiv: "Status/subfolder settings for each department (This icon can be clicked by selecting one department instead of \"All departments\" in the pull-down menu at the top left of the screen)",
        ReceivedBoxInfo: "Select the status/subfolder to receive the form below.\n\"Created, URL\"> Select each form> \"In-tray sorting\"",
        addedBelow: "Added below",
        plusIconInfo: "Create status/subfolder",
        moveIconInfo: "Move up and down",
        deleteIconInfo: "Delete this status/subfolder",
        showIconInfo: "Show/hide this status/subfolder",
        deleteDetailStatus: "Delete\nThe forms in this status/subfolder will be moved to \"{STATUS}\".",
        whatActiveDepartment: "The departments you were invited to will be displayed. When you select a specific (one) department,\n(1) You can add status / subfolders for each department (See the icon next to the \"Create New\" button).\n(2) You can automatically sort and receive the form in the status / subfolder added to the \"Inbox\" (See the \"In-tray sorting\" column in \"Created\").",
        userInfoTooltip: "Email settings\nChange password\nLanguage\nTime Zone\nDate format\nSign out\n\nSwitch apps\nUpgrade",
    },

    socketAuto: {
        autoChangedStatus: "The status of this form has been changed by another user on another screen.",
        autoChangedStatus2Confirm: "This form has been confirmed by another user on another screen. Cannot be changed. (See \"Created\")"
    },

    MessageBeforeShowFormDialog: {
        title: "Message to viewers and form fillers (Terms of use, CAPTCHA, etc.)",
        messageInfo: "You can display messages, terms of use, requests for consent to terms of use, CAPTCHA, etc. to viewers and form fillers.",
        message1Placeholder: "Message 1/2 (ON: Display this message, OFF: Hide this message)\nEx) Please read the following Terms of Use before viewing or filling out the form.\n･ If you agree to the terms of use, please check the box below.\n ･ You can proceed to the next screen (form input screen) only when all of the following items are checked.",
        message2Placeholder: "Message2/2 (ON: Display this message, OFF: Hide this message)\n** Terms of use and other information here **",
        checkboxInfo: "Please list below what viewers and form fillers should agree to.\n* Only when all boxes are checked, they can proceed with the \"Next >\" button.",
        checkbox1Placeholder: "Approve the Terms of Use and Privacy Policy before applying.",
        checkbox2Placeholder: "If there are any changes after sending, I will promptly create and send again.",
        checkbox3Placeholder: "(Leave blank to hide this entire line)",
        authenticationInfo:  "Authentication",
        whatAuthenticationInfo:  "This authentication is an alternative to CAPTCHA. If you turn it \"ON\", you can prevent unauthorized use by viewers and form fillers.\n* When the viewer/form-filler sees the above message, authentication is performed and the following is displayed.",
    },

    preFormMessage: {
        conditionActiveNextButton: "*Check all boxes"
    },

    plans: {
        upgradeButtonText: "Upgrade now",
        changePlanButtonText: "Change plan",
        currentPlanButtonText: "Current plan",
        inquiryText: "Contact Us",
        inquiryButtonText: "Contact >",
        inquiryButtonURL: "https://app.paper.bankinvoice.com/inputforms/adCOqxg8vC/NwuFrjvwLD",
        paidButtonText: "Start now",
        basePriceUnit: "USD/month",
        priceUnit: "department/month",
        people: "人",
        currency: "USD",
        basePriceYearUnit: "USD/year",
        priceYearUnit: "USD/year",
        mainFunc: "Main Features",
        freeConfirm1: "After downgrading, it will be changed to the following conditions",
        freeConfirmList1: "Up to 30 days public period per form",
        freeConfirmList2: "No email address verification function for forms",
        freeConfirmList3: "Up to 10 members per department",
        freeConfirmAs1: "*The public period is up to one month from the date of downgrade (today).",
        freeConfirmAs2: "*Email address verification of forms (including published forms) will be skipped",
        freeConfirmAs3: "*If the number of department members exceeds 10, the number of members cannot be increased any more.",
        free: {
            title: "Free",
            forUser: "For individual or teams",
            fee: {
                year: "0 USD/month",
            },
            members: "Up to 10 people per department",
            receiveDataAmount: "Unlimited",
            receiveDataStatus: true,
            publicFormAmount: "300",
            publicFormRange: "Up to 1 month",
            csv: false,
            funcInfo: "Included in Free:",
            funcList: [
                "Up to 10 members per department",
                "Up to 300 public forms per department",
                "Unlimited received forms",
                "Form status management",
                "Up to 30 days public period per form",
                "Memo/search of created form",
                "CSV export",
                "Password protection",
                "Display of terms of use, CAPTCHA, etc.",
                "Display message after sending",
                "Form Switching (Individual/Corporation)",
                "Replacing a published form",
                "Preview when creating a form",
            ]
        },
        business: {
            title: "Business",
            forUser: "For teams of up to 50 members",
            fee: {
                year: "130 USD/year",
                month: "13 USD/month",
            },
            members: "Up to 50 people per department",
            receiveDataAmount: "Unlimited",
            receiveDataStatus: true,
            publicFormAmount: "Unlimited",
            publicFormRange: "Unlimited",
            csv: false,
            funcInfo: "Everything in Free, plus:",
            funcList: [
                "Up to 50 members per department",
                "Unlimited storage up to 20MB / file",
                "Unlimited publication period for each form",
                "CSV output Latest 1,000 cases / department",
                "Number of forms that can be viewed Latest 1,000 / department",
                "Verification of form sender email address",
                "Displaying submitted forms to form submitters",
                "Status display to form submitters",
                "Withdrawal by form sender (cancellation of submission)",
                "Notes to the form by the form submitter",
                "Send an email to the form sender",
                "Displaying the creator (organization name) at the bottom of the form",
                "Priority support (answer within 48 hours)",
            ]
        },
        enterprise: {
            title: "Enterprise",
            forUser: "Customization and data linkage to existing systems",
            fee: {
                year: "10 USD/month",
                month: "12 USD/month",
            },
            members: "Unlimited",
            receiveDataAmount: "Unlimited",
            receiveDataStatus: true,
            publicFormAmount: "Unlimited",
            publicFormRange: "Unlimited",
            csv: true,
            funcInfo: "",
            funcList: [
                "Unlimited number of members per department",
                "Unlimited storage",
                "Unlimited CSV output",
                "Unlimited number of forms that can be viewed",
                "API linkage to existing system",
                "Advanced security",
                "Access control",
                "Prevention of cyber wonders",
                "Priority support (answer within 10 hours)",
            ]
        },
    },

    locale: {
        today: "Today",
        save: "Save",
        now: "Now",
        clear: "Clear",
    },

    inputFormsRedirect: {
        confirmText: "The clicked link looks different than the actual link destination.\nThis may be used to track whether a link has been clicked, but be aware that it may be a scam.\n\nDisplayed link:\n{TEXTURL}\nActual link:\n{URL}",
        openRedirectUrl: "open {URL}",
        openRedirectUrlText: "open {URL}"
    },
}
