<template>
    <div class="p-grid p-jc-center">
        <div class="p-col-9 p-md-9 p-lg-9 ">
            <div class="p-d-flex p-flex-column p-grid p-nogutter">
                <div class="p-col-12 p-field p-grid">
                    <div class="p-d-flex text-left">
                        <span v-if="FromEmail" class="link" @click="onClickFromEmail" >{{"From: " + FromEmail}}</span>
                        <span v-else >{{$t("postedFormScreen.noEmailAddress")}}</span>
                    </div>
                    <div class="p-col text-right">
                        <!-- <div class="p-col text-right"> -->
                        <!--     {{$t('postedFormScreen.header.sendDate')}}{{$filters.formatDate(SendDate, null, "HH:mm:ss")}}  -->
                        <!-- </div> -->
                        <!-- <div class="p-col-12 p-d-flex text-right align-item-end" style="align-items: center;"> -->
                        <!--     {{$t('postedFormScreen.header.templateId')}} -->
                        <!--     <i v-tooltip="$t('draft.header.templateIdInfo')" class="pi pi-info-circle"></i>: -->
                        <!--     {{TemplateId}} -->
                        <!-- </div> -->
                        <!-- <div class="p-col-12 p-d-flex text-right align-item-end" style="align-items: center;"> -->
                        <!--     {{$t('postedFormScreen.header.formId')}} -->
                        <!--     <i v-tooltip="$t('draft.header.formIdInfo')" class="pi pi-info-circle"></i>: -->
                        <!--     {{FormId}} -->
                        <!-- </div> -->
                        <template v-if="Status == $constants.FormStatus.Dropped">
                            <div class="p-col text-right" style="color: red;">
                                {{$t('postedFormScreen.header.withdrawal')}}{{$filters.formatDate(DroppedDate, null, "HH:mm:ss" )}}
                            </div>
                        </template>
                    </div>
                </div>
                <div class="p-col-12 p-grid p-nogutter">
                    <div class="p-col-12 p-lg-6  p-fluid p-formgrid p-grid p-nogutter" style="    align-content: flex-start;">
                        <div class="p-col-12 p-field p-grid">
                            <label class="p-col-fixed" style="width:100px;">{{$t('postedFormScreen.documentName')}}</label>
                            <div class="p-col text-ellipsis ">
                                <div class=" text-ellipsis " style="display: inline-block; max-width: 100%;" v-tooltip.top="DocumentName" >
                                    <span class=""  style="font-weight: bold;" > {{DocumentName}} </span>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-12 p-field p-grid">
                            <label class="p-col-fixed" style="width:100px">{{$t('postedFormScreen.toDepartment')}}</label>
                            <div class="p-col text-ellipsis " >
                                <div class=" text-ellipsis " style="display: inline-block; max-width: 100%;" v-tooltip.top="Department" >
                                    <span class=""   > {{Department}} </span>
                                </div>
                            </div>
                        </div>
                        <div class="p-field p-grid">
                            <label class="p-col-fixed" style="width:100px; margin-bottom: 0px; align-self: center;" >{{$t('postedFormScreen.publicPeriod')}}</label>
                            <div class="p-col text-left" style=" align-self: center;">
                                <!-- {{ $filters.formatDate(OpenBegin, null, "HH:mm:ss") }} ~ {{  $filters.formatDate(OpenEnd, null, "HH:mm:ss")  }} -->
                                ~ 
                            </div>
                        </div>
                    </div>
                    <div class="p-col-12 p-lg-6  p-field p-grid p-nogutter">
                        <div  class="p-col-12 p-field p-grid">
                            <editTemplateNoteInplace  :Type="'post'" />
                        </div>
                    </div>
                    <div class="p-col-12 p-grid p-nogutter">
                        <div class="p-col-6  " ></div>
                        <div class="p-col-6  p-grid p-nogutter">
                            <div  class="p-col-12 p-field p-grid p-nogutter ">
                                <label  class="p-col-fixed" style="width: 120px">{{$t('draft.controlTextLabel')}} </label>
                                    <span class="p-col-fixed " style="width:150px"   v-tooltip="ControlText"> {{ControlText}} </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="p-field p-grid">
                    <div class="p-formgroup-inline">
                        <label>{{$t('postedFormScreen.type')}}</label>
                        <div v-for="type in AppTypeList" class="p-field-checkbox">
                            <Checkbox :id="type.id" name="type1" :value="type.value" v-model="appType" :disabled="true" />
                            <label :for="type._id" v-if="type.isActive" > {{ type.label }}</label>
                            <s v-else ><label :for="type._id"> {{ type.label }}</label></s>
                        </div>
                    </div>
                </div>
                <div class="p-field p-grid">
                    <div class="p-formgroup-inline">
                        <label style="margin-right: 4px;">{{$t('postedFormScreen.selectAppType')}}</label>
                        <div class="p-field-checkbox">
                            <RadioButton value="kojin" v-model="userAttr" :disabled="true" />
                            <label>{{$t('postedFormScreen.kojin')}}</label>
                        </div>
                        <div class="p-field-checkbox">
                            <RadioButton value="houjin" v-model="userAttr" :disabled="true" />
                            <label>{{$t('postedFormScreen.houjin')}}</label>
                        </div>
                    </div>
                </div>

                <div class="p-d-flex p-flex-column">
                    <template v-for="parts in componentList">
                        <templatePanel :item="parts" :Id="parts.Id" :readOnly=true showComponentName=false>
                            <div class="p-col-12 p-grid no_margin no_padding" v-bind:class="{'no_padding': parts.ComponentType == 'Editor'}">
                                <template v-for="(item, index_parts) in parts.items" :key="item.Id" >
                                    <component 
                                        v-bind:is="item.Xtype" 
                                        :item="item" 
                                        :Id="parts.Id" 
                                        :readOnly=true 
                                    > </component>
                                </template>
                                <div v-if="parts.HasExample" class="p-col p-grid no_margin">
                                    <label class="p-col-3"> {{$t('postedFormScreen.example')}} </label>
                                    <InputText class="p-col-9" :placeholder="$t('xtype.blankText')" v-model="parts.Example" v-on:input="editExample($event, parts.Id)"/>
                                </div>
                            </div>
                        </templatePanel>
                    </template>
                </div>
            </div>
        </div>
    </div>
    <mailDialog :displayDialog="showMailDialog" @closeDialog="closeMailDialog($event)" :beforeSendView=true :OPE="OPE" />
</template>

<script>
import InputText from 'primevue/inputtext';
import RadioButton from 'primevue/radiobutton';
import Checkbox from 'primevue/checkbox';
import Calendar from 'primevue/calendar';
import textField from '@/components/templateFormParts/textField'
import spaceField from '@/components/templateFormParts/spaceField'
import textAreaField from '@/components/templateFormParts/textAreaField'
import simpleText from '@/components/templateFormParts/simpleText';
import dropDownField from '@/components/templateFormParts/dropDownField';
import multiSelectField from '@/components/templateFormParts/multiSelectField';
import dateField from '@/components/templateFormParts/dateField';
import timeField from '@/components/templateFormParts/timeField';
import fileField from '@/components/templateFormParts/fileField' 
import radioBoxGroupField from '@/components/templateFormParts/radioBoxGroupField' 
import checkBoxGroupField from '@/components/templateFormParts/checkBoxGroupField' 
import templatePanel from '@/components/templateFormParts/templateForm'
import editTemplateNoteInplace from '@/components/templateFormParts/editTemplateNoteInplace';
import fullEditor from '@/components/templateFormParts/fullEditor';
import fullEditor4Applicant from '@/components/templateFormParts/fullEditor4Applicant';


import mailDialog from '@/components/mailDialog'
import Button from 'primevue/button';
import Tooltip from 'primevue/tooltip'



export default {
    name: 'viewPostedForm',
    props: ['items'],
    components: {
        InputText,
        RadioButton,
        Checkbox,
        Calendar,
        Button,
        textField,
        spaceField,
        textAreaField,
        simpleText,
        dropDownField,
        multiSelectField,
        dateField,
        timeField,
        checkBoxGroupField,
        fileField,
        radioBoxGroupField, 
        templatePanel,  
        editTemplateNoteInplace,
        fullEditor,
        fullEditor4Applicant,
        mailDialog,
    },
    data() {
        return {
            // userAttr: null,
            // openDate: null,
            // endDate: null,
            selectedFormType: null,
            showMailDialog: false,
        }
    },
    watch: {
    },
    computed: {
        TemplateId: {
            get: function() {
                return this.$store.getters["viewPostedForm/getTemplateId"]
            }
        },
        FormId: {
            get: function() {
                return this.$store.getters["viewPostedForm/getFormId"]
            }
        },
        CanStamp: {
            get: function() {
                return this.$store.getters["viewPostedForm/getCanStamp"]
            }
        },
        Stamped: {
            get: function() {
                return this.$store.getters["viewPostedForm/getStamped"]
            }
        },
        showStampFormButton: {
            get: function() {
                const Status = this.$store.getters["viewPostedForm/getFormStatus"]
                return Status == this.$constants.FormStatus.Received || Status == this.$constants.FormStatus.Accept || Status == this.$constants.FormStatus.Reject
            }
        },
        DroppedDate: {
            get: function() {
                return this.$store.getters["viewPostedForm/getDroppedDate"]
            }
        },
        SendDate: {
            get: function() {
                return this.$store.getters["viewPostedForm/getSendDate"]
            }
        },
        FromEmail: {
            get: function() {
                const FromEmail = this.$store.getters["viewPostedForm/getFromEmail"]
                if(FromEmail) {
                    return FromEmail;
                }
                return ""
            }
        },
        isSendAcceptedMail: {
            get: function() {
                return this.$store.getters["viewPostedForm/getSendAcceptedMail"]
            },
        },
        isSendRejectedMail: {
            get: function() {
                return this.$store.getters["viewPostedForm/getSendRejectedMail"]
            },
        },
        isSendDoneMail: {
            get: function() {
                return this.$store.getters["viewPostedForm/getSendDoneMail"]
            },
        },
        visibleAcceptBtn: {
            get: function() {
                const Status = this.$store.getters["viewPostedForm/getFormStatus"]
                return Status == this.$constants.FormStatus.Reject || Status == this.$constants.FormStatus.Received
            }
        },
        isAcceptedStatus: {
            get: function() {
                const Status = this.$store.getters["viewPostedForm/getFormStatus"]
                return Status == this.$constants.FormStatus.Accept
            }
        },
        isReceivedStatus: {
            get: function() {
                const Status = this.$store.getters["viewPostedForm/getFormStatus"]
                return Status == this.$constants.FormStatus.Received
            }
        },
        visibleRejectBtn: {
            get: function() {
                const Status = this.$store.getters["viewPostedForm/getFormStatus"]
                return Status == this.$constants.FormStatus.Accept || Status == this.$constants.FormStatus.Received
            }
        },

        visibleAcceptMailBtn: {
            get: function() {
                const Status = this.$store.getters["viewPostedForm/getFormStatus"]
                return Status == this.$constants.FormStatus.Accept || Status == this.$constants.FormStatus.Storage
            }
        },

        visibleRejectMailBtn: {
            get: function() {
                const Status = this.$store.getters["viewPostedForm/getFormStatus"]
                return Status == this.$constants.FormStatus.Reject || Status == this.$constants.FormStatus.Deleted
            }
        },

        visibleDoneRequestMailBtn: {
            get: function() {
                const Status = this.$store.getters["viewPostedForm/getFormStatus"]
                return Status == this.$constants.FormStatus.Storage
            }
        },

        visibleDoneRequestBtn: {
            get: function() {
                const Status = this.$store.getters["viewPostedForm/getFormStatus"]
                return Status == this.$constants.FormStatus.Accept
            }
        },

        visibleDeleteBtn: {
            get: function() {
                const Status = this.$store.getters["viewPostedForm/getFormStatus"]
                return Status == this.$constants.FormStatus.Reject
            }
        },

        Status: {
            get: function() {
                console.log(this.$store.getters["viewPostedForm/getFormStatus"])
                return this.$store.getters["viewPostedForm/getFormStatus"]
            },
        },
        DocumentName:  {
            get: function() {
                return this.$store.getters["viewPostedForm/getDocumentName"]
            },
            set: function(value) {
                this.$store.commit('viewPostedForm/setDocumentName', value)
            }
        },
        Department: {
            get: function() {
                return this.$store.getters["viewPostedForm/getDepartmentName"]
            },
            set: function(value) {
                this.$store.commit('viewPostedForm/setDepartmentname', value)
            }
        },
        userAttr: {
            get: function() {
                return this.$store.getters["viewPostedForm/getUserAttr"]
            },
            set: function(value) {
                this.$store.commit('viewPostedForm/setUserAttr', value)
            }
        },
        AppTypeList: {
            get: function() {
                console.log(this.$store.getters["viewPostedForm/getAppTypeList"])
                return this.$store.getters["viewPostedForm/getAppTypeList"]
            },
            set: function(value) {
                this.$store.commit('viewPostedForm/setAppTypeList', value)
            }
        },
        OpenBegin: {
            get: function() {
                return this.$store.getters["viewPostedForm/getOpenBegin"]
            },
            set: function(value) {
                this.$store.commit('viewPostedForm/setOpenBegin', value)
            }
        },
        OpenEnd: {
            get: function() {
                return this.$store.getters["viewPostedForm/getOpenEnd"]
            },
            set: function(value) {
                this.$store.commit('viewPostedForm/setOpenEnd', value)
            }
        },
        ControlText: {
            get: function() {
                return this.$store.getters["viewPostedForm/getControlText"]
            },
            set: async function(value) {
                this.$store.commit('viewPostedForm/setControlText', value)
            }
        },
        appType: {
            get: function() {
                const AppType = this.$store.getters["viewPostedForm/getAppType"]
                return AppType
            },
            set: function(value) {
                const list = []
                Object.keys(value).forEach(function (key) {
                    list.push(value[key])
                    // list.push(value)
                });
                this.$store.commit('viewPostedForm/setAppType', list)
            }
        },
        componentList: {
            get: function() {
                return this.$store.getters["viewPostedForm/getComponents"]
            },
        }
    },
    methods: {
        onClickFromEmail: async function() {
            const res = await this.$store.dispatch("sentMailStore/getBulkMailTemplate", {OPE: "NormalBulk"})
            if(res.Success) {
                if (this.FromEmail) {
                    this.$store.commit("sentMailStore/setTo", this.FromEmail)
                } else {
                    this.$store.commit("sentMailStore/setTo", undefined)
                }
                const selectedData = this.$store.getters["gridDataStore/getSelectedData"]
                const CompanyName = selectedData.CompanyName
                this.$store.commit("sentMailStore/setFromCompanyName", CompanyName)
                this.showMailDialog = true
            } else {
                this.$toast.add({
                    severity:'error', 
                    summary: this.$i18n.tc("utils.sendMailFailed"),
                    detail: this.$i18n.tc("utils.sendMailFailedByForm"), 
                    life: this.$constants.successHelperLife,
                    group: this.$constants.defaultToastGroup
                });
            }
        },

        async closeMailDialog(e={}) {
            if(e.sent) {
                const subjectText = this.$store.getters['sentMailStore/getSubject']
                const defaultText = this.$store.getters['sentMailStore/getDefaultText']
                const CCGroupEmails = this.$store.getters["sentMailStore/getCCGroupEmails"]
                const BCCGroupEmails = this.$store.getters["sentMailStore/getBCCGroupEmails"]
                const FromCompanyName = this.$store.getters["sentMailStore/getFromCompanyName"]
                const ReplyToAddress = this.$store.getters["sentMailStore/getReplyToAddress"]
                const params = {
                    subjectText,
                    defaultText,
                    CCGroupEmails,
                    BCCGroupEmails,
                    FromCompanyName,
                    ReplyToAddress,
                }
                this.sendBulkMail(params)
            } else {
                this.showMailDialog = false
            }
        },

        async sendBulkMail(params) {
            params.BulkCheckFormList = [this.$store.getters["getSelectRowId"]]
            params.isSelectedAll = false
            params.Status = this.$store.getters["viewPostedForm/getFormStatus"]
            params.OPE = "NormalBulk"
            const res = await this.$store.dispatch('sentMailStore/sendBulkMail', params)

            if(res.Success) {
                this.$toast.add({severity:'success', summary: res.Message, life: this.$constants.successHelperLife, group: this.$constants.defaultToastGroup});
                this.showMailDialog = false
            } else if(res.ConfirmMessage) {
                this.$confirm.require({
                    group: "break",
                    isShowRejectButton: false,
                    header: this.$i18n.tc("utils.sendError"),
                    message: res.ConfirmMessage,
                    accept: function() {
                    },
                    reject: () => {
                    }
                })
            } else {
                this.$toast.add({severity:'error', summary: this.$i18n.tc("utils.sendMailFailed"), detail: this.$i18n.tc("utils.sendMailFailedByForm"), life: this.$constants.successHelperLife, group: this.$constants.defaultToastGroup});
                this.showMailDialog = false
            }
        },
    },
    directives: {
        'tooltip': Tooltip
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.opacityIcon {
    opacity: 0.4;
}
</style>

