<template>
    <template v-if="loading">
        <div class="progressSpinnerContainer">
            <ProgressSpinner  />
        </div>
    </template>
    <template v-else>
        <Panel class="notvisibility-header-panel inputFormPanel">
            <div class="p-grid p-jc-center">
                <div class="container width-100-phone" style="">
                    <div style="margin-bottom: 10px;" class="p-d-flex">
                        <div>
                            <div v-if="edit" style="display: inline-block; padding: 2px 10px; color: rgba(0, 0, 0, 0.26); border: 1px solid rgba(0, 0, 0, 0.26); white-space: nowrap;">
                                {{$t('inputForm.topText')}}
                            </div>
                        </div>
                        <div style="flex: 1;"></div>
                        <div style="">
                            <Dropdown v-model="selectedLanguage" :options="languages" optionLabel="name" optionValue="value" class="selectLanguageInputFormDropdown" panelClass="selectLanguageInputFormDropdownPanel" />
                        </div>
                    </div>
                    <div  style="width: 100%; text-align: right; position: relative;">
                        <Button :label="$t('inputForm.showQRcode')" class="p-button-secondary p-button-text" @click="onShowQRcodeClick"/>
                        <OverlayPanel ref="op" class="qrcodeOverlayPanel" >
                            <VueQrcode :value="$constants.local+$route.fullPath" tag="img" class="cursor_pointer " :options="qrOption" ref="qrcode" @click="onQrcodeClick"  v-tooltip="$t('operationButtonBar.qrcodeTooltip')" />
                        </OverlayPanel>
                        <div class="position-absolute-center">
                            <Button icon="pi pi-refresh" class="p-button-rounded p-button-text p-button-secondary p-button-raised" @click="onRefreshIconClick" />
                        </div>
                    </div>
                    <InlineMessage v-if="!userAttrVal" severity="info" class="p-col-12" style="background-color: #f7f8fa; color: #495057; margin-top: 8px;"> {{$t('inputForm.selectUserAttrMessage')}} </InlineMessage>
                    <h1 class="p-col-12" style="text-align: left; font-size: 20px; font-weight: bold; padding-left: 8px; border-left: 4px solid #4a525a;" v-if="DocumentName" >{{DocumentName}}</h1>
                    <div style="display: none;">{{editingForm}}</div>
                    <transition-group name="in-out-translate-fade" mode="out-in" key="transition-group1">
                        <div  key="on" class="p-d-flex p-flex-column p-ac-stretch" style="margin-top: 8px;">
                            <div class="p-d-flex p-flex-column">
                                <div class="p-field" v-if="showUserAttr" >
                                    <div class="p-mb-2" style="font-size: 16px;">
                                        {{$t('inputForm.select')}}
                                    </div>
                                    <div class="p-d-flex p-flex-column">
                                        <template v-for="(userAttr, attrIndex) in userAttrList" :key="attrIndex">
                                            <div class="p-d-flex p-jc-start p-field-radiobutton">
                                                <RadioButton :id="attrIndex" :value="userAttr.value" v-model="userAttrVal" @change="onUserAttrChange"  :disabled="!edit"/>
                                                <label :for="attrIndex" style="font-size: 16px;"> {{userAttr.label}}</label>
                                            </div>
                                        </template>
                                    </div>
                                </div>

                                <div class="p-field p-grid" v-if="appTypeList.length">
                                    <div class="p-formgroup-inline">
                                        <div v-for="type in appTypeList" class="p-field-checkbox" :key="type._id">
                                            <Checkbox :id="type.value" name="type2" :value="type.value" v-model="appType"  :disabled="!edit"/>
                                            <label :for="type.value" style="font-size: 16px;" > {{ type.label }}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <transition name="in-out-translate-fade" mode="out-in"  key="transition1" appear>
                                <div v-if="form" class="p-d-flex p-flex-column" >
                                    <template  v-for="(parts, index) in form"  :key="parts._id">
                                        <formPanel :item="parts" :edit="edit" :required="requiredComponent(parts.Required, index)" >
                                        <div class="p-col-12 align-item-center p-grid no_margin " :key="parts.Id" :class="{no_padding: parts.ComponentType=='Editor'}">
                                                <template v-for="(item, index_parts) in parts.items" :key="item.Id" >
                                                        <component
                                                            @setDataInItem="setDataToStore" 
                                                            v-bind:is="item.Xtype" 
                                                            :item="item" 
                                                            :Id="parts.Id"
                                                            :edit="edit"
                                                            :required="requiredComponent(parts.Required, index)"
                                                            @clearRequireField="changeActiveRequiredField($event, index)"
                                                        /> 
                                                        <template v-if="parts.ComponentType != 'Editor' && item.Xtype != 'spaceField' && item.Xtype != 'simpleText'">
                                                                <Button class="p-button-outlined p-button-info"  v-if="!edit && !parts.fix" @click="editFieldButtonClick($event, index)">
                                                                    {{$t('inputForm.amend')}}
                                                                </Button>
                                                                <Button class="p-button-outlined p-button-danger"  v-if="!edit && parts.fix" @click="editFieldButtonClick($event, index)">
                                                                    {{$t('inputForm.finish')}}
                                                                </Button>
                                                        </template>
                                                </template>
                                            </div>
                                        </formPanel>
                                    </template>
                                </div>
                            </transition>
                            <div class="p-p-4">
                                <Button class="p-d-block p-mx-auto p-button-outlined p-button-info" type="submit" > {{$t('inputForm.toConfirmPanel')}} </Button>
                            </div>
                        </div>
                    </transition-group>
                    <InputViewFooter />
                </div>
            </div>
        </Panel>
    </template>
</template>

<script>
// @ is an alias to /src
import http from '../mixins/api'
import RadioButton from 'primevue/radiobutton';
import Checkbox from 'primevue/checkbox';
import Calendar from 'primevue/calendar';
import formPanel from '@/components/inputFormParts/formPanel'
import textField from '@/components/inputFormParts/textField'
import spaceField from '@/components/templateFormParts/spaceField'
import textAreaField from '@/components/inputFormParts/textAreaField'
import dropDownField from '@/components/inputFormParts/dropDownField'
import multiSelectField from '@/components/inputFormParts/multiSelectField'
import simpleText from '@/components/inputFormParts/simpleText';
import dateField from '@/components/inputFormParts/dateField'
import timeField from '@/components/inputFormParts/timeField';
import fileField from '@/components/inputFormParts/fileField'
import radioBoxGroupField from '@/components/inputFormParts/radioBoxGroupField'
import checkBoxGroupField from '@/components/inputFormParts/checkBoxGroupField'
import fullEditor from '@/components/inputFormParts/fullEditor'
import fullEditor4Applicant from '@/components/inputFormParts/fullEditor4Applicant';
import ProgressSpinner from 'primevue/progressspinner';

import Panel from 'primevue/panel';
import Button from 'primevue/button';
import BankInvoicePaperFooter from '@/components/BankInvoicePaperFooter';
import InputViewFooter from '@/components/InputViewFooter';
import isMobile from '@/mixins/device'
import store from '@/store'
import OverlayPanel from 'primevue/overlaypanel';
import VueQrcode from "@chenfengyuan/vue-qrcode";
import helper from '@/mixins/helper';
import Tooltip from 'primevue/tooltip'
import Dropdown from 'primevue/dropdown';
import InlineMessage from 'primevue/inlinemessage';

import socketHelper from "@/mixins/socketHelper"
import { uuid } from "vue-uuid";


export default {
    name: 'PreviewWindow',
    components: {
        formPanel,
        dropDownField,
        multiSelectField,
        RadioButton,
        Checkbox,
        Calendar,
        Panel,
        textField,
        spaceField,
        textAreaField,
        simpleText,
        dateField,
        timeField,
        fileField,
        radioBoxGroupField,
        checkBoxGroupField,
        fullEditor,
        fullEditor4Applicant,
        Button,
        ProgressSpinner,
        BankInvoicePaperFooter,
        InputViewFooter,
        OverlayPanel,
        VueQrcode,
        Dropdown,
        InlineMessage,
        // PanelComponent,
        // FormComponent,
    },
    data: function(){
        return{
            data:{
                form: {},
                formList: [],
                userAttrList: [],
                appTypeList: [],
            },
            loading: true,
            // appType: [],
            showUserAttr: true,
            activeUserAttr: null,
            edit: true,
            displaySendFormStatus: 0,
            displayProgressSpinner: true,
            activeRequiredFieldIndexList: {},
            hasRedirectFlag: false,
            Finished: false,
            EndDocumentName: "",
            qrOption: {
                width: 140,
            },
            isMobile,
        }
    },
    watch: {
        edit: function() {
            window.scrollTo(0, 0)
        },
    },
    computed: {
        DocumentName: {
            get: function() {
                return this.$store.getters["inputForm/getDocumentName"]
            }
        },
        form: {
            get: function() {
                return this.$store.getters["inputForm/getActiveForm"]
            }
        },
        userAttrList: {
            get: function() {
                return this.$store.getters["inputForm/getUserAttrList"]
            }
        },
        appTypeList: {
            get: function() {
                return this.$store.getters["inputForm/getActiveAppTypeList"]
            },
        },
        appType: {
            get: function() {
                return this.$store.getters["inputForm/getAppType"] || []
            },
            set: function(value) {
                const list = []
                Object.keys(value).forEach(function (key) {
                    list.push(value[key])
                });
                this.$store.commit('inputForm/setAppType', value)
            }
        },
        userAttrVal: {
            get: function() {
                const activeUserAttr = this.$store.getters["inputForm/getActiveUserAttr"]
                return activeUserAttr
            },
            set: function(value) {
                this.$store.commit('inputForm/setActiveUserAttr', value)
            }
        },
        isDisabledSend: {
            get: function() {
                const index = this.$store.getters["inputForm/getActiveForm"].findIndex(x => x.fix)
                return index != -1
            }
        },
        selectedLanguage: {
            get: function() {
                return this.$store.getters["applicantStore/getUserLanguage"];
            },
            set: function(value) {
                this.$store.commit("applicantStore/setUserLanguage", value)
                this.$store.commit('inputForm/setUserAttrData', value)
            },
        },
        languages: {
            get: function() {
                return this.$store.getters["applicantStore/getUserLanguageList"]
            },
        },
        requiredComponent: {
            get: function() {
                const self = this;
                return function(required, index) {
                    if(required == "true") {
                        if(self.activeRequiredFieldIndexList[index]) {
                            return "false"
                        }
                        return "true"
                    }
                    return "false"
                }
            },
        },
        editingForm: {
            get: function() {
                const editing = this.$store.getters['previewFormStore/getEditingConfirmAlert']
                if (editing) {
                    this.confirmPosition()
                    this.editingForm = false
                }
                return editing
            },
            set: function(value) {
                this.$store.commit('previewFormStore/setEditingConfirmAlert', value)
            }
        },
    },
    async beforeRouteEnter(route, redirect, next) {
        const query = route.query
        const userId = query.userId
        const id = query.id
        next(vm => vm.getData({id: id}))

    },
    methods: {
        getData: async function(obj) {
            const id = obj.id
            
            const previewSetOnUUID = uuid.v4()
            socketHelper.joinPreviewTemplate(previewSetOnUUID, function() {
                socketHelper.previewSetOn(previewSetOnUUID)
            })
            
            // socketHelper.joinTemplate(id)
            const res = await http.post("/form/GetInputFormInPreview", {id: id, previewSetOnUUID: previewSetOnUUID}, null, false, false)
            if(res.Success) {
                store.commit('inputForm/setFormTemplateId', id)
                this.setData({...res})
            } else {
                this.wrongPage(res.DocumentName)
            }
            this.loading = false
        },


        wrongPage: function(DocumentName) {
            this.EndDocumentName = DocumentName
            this.Finished = true
            this.$router.push({ path: '/admin/draft' });
        },

        setData (data) {
            this.$store.commit("applicantStore/setInitUserLanguage")
            this.$store.commit('inputForm/setAllUserAttrData', data.allUserAttrList)
            this.$store.commit('inputForm/setUserAttrData', this.selectedLanguage)
            this.$store.commit('inputForm/setAppTypeData', data.appTypeList)
            this.$store.commit('inputForm/setFormListData', data.formList)
            this.$store.commit('inputForm/setTemplateInfo', data.templateInfo)
            if(data.templateInfo.ScopeOfPublication != "kojin_houjin") {
                this.userAttrVal = data.templateInfo.ScopeOfPublication
                this.$store.commit('inputForm/setActiveUserAttr', this.userAttrVal)
                this.showUserAttr = false
            }

            window.scrollTo(0, 0)

        },

        onUserAttrChange(e) {
            if(this.userAttrVal) {
                this.$store.commit('inputForm/setActiveUserAttr', this.userAttrVal)
            }
        },
        setDataToStore: function(data) {
            this.$store.commit('inputForm/formUpdate', [data])
        },
        editFieldButtonClick: function(e, index) {
            this.$store.commit('inputForm/setFixField', {index: index})
        },
        changeActiveRequiredField: function(e, index) {
            this.activeRequiredFieldIndexList[index] = e
        },
        onShowQRcodeClick: function(e) {
            this.$refs.op.toggle(e)
        },
        onQrcodeClick: function() {
            const src = this.$refs.qrcode.$el.src
            helper.copyImage64ToClipboard(src)
            this.$toast.add({severity:'success', summary: this.$i18n.tc("operationButtonBar.copiedQrcode"), life: this.$constants.successHelperLife, group:  this.$constants.defaultToastGroup});
        },
        confirmPosition(position="top") {
            this.$confirm.require({
                group: 'positionDialog',
                message: this.$i18n.tc('previewWindow.saveConfirm'),
                // header: 'Delete Confirmation',
                icon: 'pi pi-info-circle',
                position: position,
                isShowRejectButton: false,
                accept: () => {
                },
            });
        },
        onRefreshIconClick() {
            location.reload()
        },

    },
    directives: {
        'tooltip': Tooltip
    },
}
</script>

<style scoped>
</style>
