import constants from '@/mixins/constants';

export default {
    changeEmail: {
        title: "メールアドレス変更",
        Email: "変更前のメールアドレス",
        NextEmail: "変更後のメールアドレス",
        change: "変更",
    },

    changePassword: {
        title: "パスワード登録・変更",
        Email: "メールアドレス",
        Password: "新パスワード",
        nextPassword: "新パスワード(確認)",
        dontUse: "他のサービスと同じパスワードを使わないでください",
        update: "パスワード更新",
    },
    adminLogin: {
        changeEmailAddress: "メールアドレス変更",
        changeEmailAddressPopup: "送信した後、認証メールが送信されます",
        changeEmailAddressPopupHeader: "メールアドレス変更",
        changePassword: "パスワード変更",
        changePasswordPopup: "送信した後、認証メールが送信されます",
        changePasswordPopupHeader: "パスワードの登録・変更",
        invalidLogin: "メールアドレスまたはパスワードが違います",
        privacy: "プライバシー",
        article: "条項",
    },

    admin: {
        willEndFormTemplate: "もうすぐ公開が終了するフォームがあります。「作成済」にて終了日を変更できます。"
    },

    invited: {
        registedSuccess: "あなたの部門に新しいユーザーを追加しました(「一覧」を参照)",
        registedFailed: "ユーザ登録に失敗しました",
        registedDepartment: "あなたの組織に新しい部門を追加しました(「一覧」を参照)\n次はこの部門に新しいユーザーを追加してください",
        registedCompany: "新しい組織を作成しました(一覧へ)\n次はこの組織に新しい部門を追加してください",
    },

    templateFormView: {

    },

    draft: {
        saved: "一時保存しました",
        confirmed: "確定しました(作成済を参照)",
        saved_detail: "確定する場合は「確定(作成済へ)」ボタンをクリックしてください",
        confirmed_detail: "作成済ステータスにてURLを取得してください",
        garbage: "ゴミ箱へ移しました(「ゴミ箱」を参照)",
        copied: "コピーしました(「下書き」を参照)",
        requiredFieldError: "「To部門」は必須です",
        header: {
            createdUserEmail: "作成者",
            createdUserEmailTooltip: "「新規作成」をクリックしたユーザー \n※一覧(変更) > 「組織」 > 「部門」 > ユーザー情報変更（アイコン）を参照",
            createdAndUpdatedDate: "作成・保存日時:",
            templateId: "書類ID",
            formId: "書類毎ID: 受信時に発行",
            confirmedDate: "確定日時:",
            garbageDate: "ゴミ箱日時:",
            templateIdInfo: "書類が作成されたときに発行される、固有ID (1書類につき1ID) ",
            formIdInfo: "書類が送信・受信されたときに発行される、固有ID（1送信・受信につき1ID）"
        },
        overWriteConfirm: "他のユーザーが他の画面にてこのフォームを編集中です。それを上書きしますか？",
        documentName: "書類名",
        toDepartment: "To部門",
        publicPeriod: "公開期間", //"Public Period",
        publicRangeLabel: "公開範囲",
        publicRangeLabelInfo: "公開後に変更できます。\n（１）「個人用・法人用の入力フォームを表示する」を選択：\n入力フォームが表示される前に「個人」か「法人」かを選択する画面が表示されます。「個人」が選択されると個人用のフォーム（以下の「申込種別の選択」にて「個人」を選択して作成されたフォーム）が表示され、「法人」が選択されると法人用のフォーム（以下の「申込種別の選択」にて「法人」を選択して作成されたフォーム）が表示されます。\n（２）「個人用の入力フォームを表示する」を選択：\n入力フォームが表示される前に「個人」か「法人」かを選択する画面は表示されず、個人用のフォーム（以下の「申込種別の選択」にて「個人」を選択して作成されたフォーム）が表示されます。\n（３）「法人用の入力フォームを表示する」を選択：\n入力フォームが表示される前に「個人」か「法人」かを選択する画面は表示されず、法人用のフォーム（以下の「申込種別の選択」にて「法人」を選択して作成されたフォーム）が表示されます。\n※「URL取得」> そのURLをブラウザ上部に貼り付け >  （１）（２）（３）を切り替え > ブラウザを更新 > （１）（２）（３）の見え方の違いを確認できます。",
        canBeChangeAfterPublication: "公開後に変更できます。\n「公開期間」を指定できます。\n※「URL取得」をクリック > そのURLをブラウザ上部に貼り付け > 公開期間を変更（終了日前と終了日後など） > ブラウザを更新 > 終了日前と終了日後などの見え方の違いを確認できます。",
        type: "種別の表示(省略可): ",
        typeNotion: "※選択された項目が入力画面にて表示されます",
        selectAppType: "申込種別の選択: ",
        kojin: "個人",
        houjin: "法人",
        cantCopyInPreview: "※コピーできません（プレビュー中のため）",
        cantSaveInPreview: "※保存できません（プレビュー中のため）",
        previewing: "プレビュー中",
        example: "例",
        add: "追加",
        preview: "プレビュー",
        temporarySaving: "一時保存",
        minConponentText: "最低1つ必要です",
        copyHoujinTemplate: "「法人」用をコピー",
        copyKojinTemplate: "「個人」用をコピー",
        copyAndOverWrite: "コピーして上書きします",
        deleteComponentDialogMessage: "削除しますか？",
        copyComponentDialogMessage: "コピーしますか？",
        toDepartmentInfoTooltip: "「To部門」の名称変更は「一覧」> ペンアイコンにて行ってください。\n「To部門」を選択して「一時保存」すると、このフォームをその部門のユーザーで共有できます。\n「To部門」を削除して空白で「一時保存」すると、あなた（作成者）だけがこのフォームを見ることができます。\n選択・保存は作成者だけがおこなえます。",
        sortNumberLabel: "ソートキー (部門毎)",
        sortNumberInfoTooltip: "下書きでの書類の確定の際に、部門毎に連番が発番されます。上書きすることで、部門ごとに書類の並び順をカスタマイズすることができます。",
        controlTextLabel: "管理番号",
        failChangeToDepartment: "作成者（「新規作成」をクリックしたユーザー）だけが「To部門」を変更できます",
        draftPublicPeriodTooltip: "「確定」をクリックしたあと「作成済」にて設定・変更してください",
        isVerification: "認証",
        publicFormat: "公開形式",
        isVerificationTooltip: "※フォーム送信者にメールアドレスを認証してもらうことで、あなたはフォーム受信後にフォーム送信者へメールを送信できます。\nフォームの送信者は、メールアドレス認証を行うことで、送信したフォームとステータス「送信済 (未読)」「送信済 (既読)」「受取済 (既読)」を確認することができます",
        publicFormatTooltip: "フォームを公開する際、フォーム形式またはWebページ形式のどちらかで公開できます（公開後に変更可能）。\n※「URL取得」> そのURLをブラウザ上部に貼り付け > 「フォーム形式で公開」と「Webページ形式で公開」を切り替え > ブラウザを更新して見え方の違いを確認できます。",
        publicFormatList: [
            {
                label: "フォーム形式で公開（「送信」ボタンを表示）",
                value: "form"
            },
            {
                label: "Webページ形式で公開（「送信」ボタンを非表示）",
                value: "webpage"
            }
        ],
        verificationList: [
            {
                label: "フォーム入力画面の前にメールアドレス入力画面を表示する",
                value: true
            },
            {
                label: "フォーム入力画面の前にメールアドレス入力画面を表示しない",
                value: false
            }
        ],
        toChangeRegistrationInfoLinkText: "名称変更",
        defaultInbox: "自動振り分け",
        defaultInboxTooltip: "受信したフォームはここで選択されたステータス/サブフォルダへ自動的に振り分けされます。\n\n この機能を使うには、以下の（１）と（２）を完了後に、このプルダウンにて新たに作成したステータス/サブフォルダを選択してください。\n （１）画面左上のプルダウンにて「すべての部門」ではなくある１つの部門を選択\n （２）「新規作成」ボタンの右にあるアイコンをクリックして新しいステータス/サブフォルダを作成",
        defaultInboxListBox: "指定なし (受信トレイ)",
        toChangeRegistrationInfoLinkTextClick: "ペンアイコンをクリックして名称を変更できます",
    },
    postedFormScreen: {
        updateStatus: "取下げ完了しました", 
        failedUpdateStatus: "既にステータスが変更されています",
        failedMoveStatus: "ステータスの変更に失敗しました",
        AcceptForm: "受理しました(「受理」を参照)",
        RejectForm: "不受理としました(「不受理」を参照)",
        DoneForm: "保管箱へ移しました(「保管箱」を参照)",
        DeleteForm: "削除しました(「削除済」を参照)",
        ButtonStatusText: {
            "Sent": "送信済",
            "Withdrawal": "取下げ",
            // "Accepted": "受理済",
            // "Rejected": "不受理",
            "Accepted": "受取済",
            "Rejected": "受取済",
            "AlreadyWithdrawal": "取下げ済",
        },
        Withdrawal: "取下げ(送信取消)",
        confirmed: "確認しました(追加されたアイコンを参照)",
        cancellation: "解除しました(アイコンが削除されました)",
        alreadyConfirmed: "すでに確認済です",
        alreadyCancellation: "すでに解除済です",
        notSharedAndCantConfirm: "共有(招待)されていません。確認できません",
        header: {
            sendDate: "送受信日時",
            templateId: "書類ID",
            formId: "書類毎ID ",
            withdrawal: "取下げ(送信取消): ",
        },
        documentName: "書類名",
        toDepartment: "To部門",
        publicPeriod: "公開期間",
        type: "種別: ",
        selectAppType: "申込種別の選択: ",
        kojin: "個人",
        houjin: "法人",
        example: "例",
        noEmailAddress: "From: なし(原因: メールアドレス入力画面を表示していない。作成済 > 認証を参照)",
        return2SentBox: "< 送信済一覧",
        isWithdrawal: "取下げしますか?",
        isReadForm: "既読",
        notIsReadForm: "未読",
        BeforeDoneForm: "\"保管箱\"へ移しますか？\nすべての操作ができなくなります。",
        BeforeDeleteForm: "\"削除済\"へ移しますか？\nすべての操作ができなくなります。",
    },
    door: {
        Send: "送信",
        Authentication: "認証",
        AuthenticationNumber: "認証番号",
        inputAuthNumber: "メールに記載の「認証番号」を入力してください",
        inputInfo: "連絡のつくメールアドレスを入力してください",
        ToInputPanel: "メールを認証して入力画面へ",
        failedCertificationNumber: "入力が誤っているか有効期限が過ぎています\n再度やり直してください",

    },
    formValidationDoors: {
        inputInfo:  "パスワードで保護されています。\nパスワードを入力してください。",
    },
    inputViewFooter: {
        text1: "このコンテンツの作成者ではありません",
        author: "このコンテンツの作成者は {Author} です",
        cantSendAmend: "パスワードを送信しないでください",
    },
    inputForm: {
        topText: "入力はこの1ページだけです",
        plsConfirm: "確認画面 (送信されていません)",
        notion4Phone: "メモリ容量が少ないと入力中にデータが自動消去される可能性があります",
        copy: "コピー",
        copyToBottom: "一番下にコピー",
        select: "選択してください",
        type: "種別: ",
        amend: "修正",
        finish: "完了",
        toConfirmPanel: "確認画面へ",
        back: "戻る",
        Send: "送信",
        SendComplate: "送信完了！",
        cantSendAmend: "修正中のため送信できません",
        formStatus_Sending: "送信中",
        formStatus_End: "送信しました",
        formStatus_Failed: "送信に失敗しました",
        isForward: "必須項目の入力が漏れています\n先に進みますか?",
        required: "必須",
        optional: "任意",
        blank: "空白",
        kojin: "個人",
        houjin: "法人",
        backToDoor: "認証画面からやり直してください",
        exceededMaxFileStorage: "最大{MB}MB/ファイル",
        inputDescriptionCopyEmailAgain: "再度送信内容の控えを送信する(複数回可)",
        inputDescriptionCopyEmail: "送信内容の控えを送信する(複数回可)",
        descriptionCopyEmailPlaceholder: "メールアドレス", 
        jump2Homepage: "リンク先へ移動しますか？",
        dataSaving: "保存中...",
        dataSaved: "保存完了(ファイル以外)",
        sendEmail2ExistUser: "(自動返信メールが送られました)",
        showQRcode: "QRコードを表示",
        importFormButton: "コピー",
        importFormButtonTooltip: "クリックしてサインインするとこのフォームがあなたの「下書き」にコピーされます。アカウントがない場合、アカウントを作成すると同時にあなたの「下書き」にコピーされます。",
        notSaved: "戻れません。「キャンセル」をクリックしてください。",
        selectUserAttrMessage: "「個人」または「法人」を選択して先に進んでください。",
    },

    postedForm: {
        inputEmail: "送信時に認証したメールアドレスを入力してください",
        sendMailFailed: "送信時に認証したメールアドレスを入力ください"
    },

    top: {
        createNewCard: "新しいカードを作る",
        deleteCard: "カードを削除します",
        deleteCardForever: "削除しますか？",
        deleteCardForeverInfo: "カードを再度開くことはできなくなります。\n元に戻すことはできません",
        Company: "組織",
        Department: "部門",
        Personal: "マイノート",
        createNewBoardList: "新しいリストを作る",
        deleteBoardList: "リストを削除する",
        deleteBoardListConfirm: "このリストを削除しますか?",
        createNewBoardListInfo: "リストを作成します。リストは「組織/ 部門」等のメンバーで共有されます。「個人」のリストはあなたのみが見ることができます。",
        BoardTypeTextToolTip: {
            Company: "以下のカードは、この「組織」のすべての「部門」に属するユーザーに共有されます。\n※「組織」の名称変更は、左下の「一覧（変更）」で行えます。",
            Department: "以下のカードは、この「部門」に属するすべてのユーザーに共有されます。\n※「部門」の名称変更は、左下の「一覧（変更）」で行えます。",
            Personal: "以下のカードは、あなただけがみえます。"
        },
        card: {
            Attachment: "添付ファイル",
            AddAttachment: "ファイル添付",
            Description: "詳細",
            isNotSaved: "まだ保存されていません\n保存ボタン/キャンセル",
            addAttachment: "添付",
            deleteAttachment: "削除",
            deleteAttachmentText: "削除しますか？\n添付ファイルを削除すると元に戻せません。",
            commentAttachment: "コメント",
            changeFileNameAttachment: "ファイル名変更",
            setHeader: "上に表示",
            removeHeader: "上に表示しない",
        },
        quickCard: {
            "pinButton": "ピン留め",
            "unPinButton": "ピン解除",
            "labelButton": "ラベル",
            "moveButton": "移動",
            "deleteButton": "削除",
            "open": "開く",
        },
        anotherUserModified: "他のユーザーが他の画面にて編集中です。それを上書きしますか？\n(最近編集したユーザ{USEREMAIL})"
    },

    EditCardDialog: {
        header: "カード",
        notion: "「組織」を選択すると組織のユーザー全員、「部門」を選択するとその部門のユーザーのみ、「個人」を選択すると自分自身のみが閲覧できます",
        reload: "更新",
        pinnedCard: "固定表示 ON",
        clearPinnedCard: "固定表示 OFF",
        tagPopPanel: {
            title: "ラベル(分類)",
            info: "クリックして貼り、再度クリックではがせます。"
        },
        cardMovePopPanel: {
            title: "移動",
            info: "移動先を指定してください",
            updateButton: "移動",
            list: "リスト",
            position: "位置",
            moved: "移動しました",
            isPined: " (ピン留め中)",
            isCurrentCardNo: " (現在)",
        },
        invalidFileNameLetter: "ファイル名に次の文字は使えません\n{INVALID}",
        fileLength: "最大255文字",
        invalidTitleLength: "最大" + constants.maxCardTitleLength.toLocaleString() + "文字まで",
        invalidContentLength: "最大" + constants.maxCardContentLength.toLocaleString() + "文字まで",
    },

    downloadCsvDialog: {
        header: "ダウンロード",
        infoText: "選択した書類のCSVファイルと添付ファイルをZIPファイルでダウンロードします<br>CSVファイル：「書類ID」と「法人」「個人」ごとにダウンロードされます<br>添付ファイル：「書類毎ID」ごとにダウンロードされます",
        selectCharacterCode: "文字コードを選択ください",
        download: "ダウンロード",
    },

    stampedUserListOverPanel: {
        confirmedUser: "チェック済のユーザー",
        notConfirmedUser: "チェックがまだのユーザー",
        toShareView: "この書類の共有部門、共有者はこちら>>",
    },

    formDataTable: {
        SortNumberByTemplate: "ソート番号",
        Filter: "フィルター",
        ForgetFilter: "見あたりません。データがないか、フィルタ解除忘れです。",
        Cancel: "解除",
        Clear: "クリア",
        DocumentName: "書類名",
        ControlText: "管理番号",
        SentDocumentName: "書類名",
        ToDepartment: "To部門",
        ToCompanyName: "To組織名",
        OpenBegin: "公開開始日",
        OpenEnd: "公開終了日",
        UpdatedDate: "作成・保存日時",
        TemplateNote: "メモ(1)",
        ConfirmedUserDisplayInCreated: "チェック済",
        FromEmail: "From Email(認証済)",
        CreatorName: "作成者",
        ConfirmedUserDisplayInReceived: "チェック済",
        SendDate: "送受信時間",
        FormNote: "メモ(2)",
        isNotSave: "保存されていません（「キャンセル」で戻って保存する、「OK」で保存せず先へ進む）",
        isNotSaveClickRow: "保存されていません",
        isNotSaveAccept: "保存せず先に進む",
        isNotSaveBack: "戻って保存する",
        SenderFormNote: "送信者メモ",
        FormStatusInSender: "ステータス",
        NotShowFromEmail: "なし(メール認証なし)",
        SenderScreen: "送信済",
        IsShare: "To部門のユーザー以外のユーザーと共有中です（招待された部門や個人は、「一覧」 >「書類別ユーザー一覧」タブを参照）",
        blankCreatorName: "(氏名が登録されていません)",
        blankDepartmentName: "(部門名が登録されていません)",
        blankCompanyName: "(組織名が登録されていません)",
        isCreatorName: "「新規作成」をクリックしたユーザー \n※「To部門」を選択して一時保存後に表示\n※一覧(変更) > 「組織」 > 「部門」 > ユーザー情報変更（アイコン）を参照",
        selectAll: "全て選択",
        selectAllInfo: "（１）チェックを1つでもはずしたらページの全件選択へ変更\n（２）ページ移動したら全件選択解除",
        unSelectAll: "選択を解除",
        FromEmailInfo: "（１）「From Email(認証済)」宛にメールを送信でき、送信されたメールは「送信済メール一覧」で確認できます。（２）「From Email(認証済)」は「フォーム入力画面の前にメールアドレス入力画面を表示する」が選択された状態で公開されたフォームにだけ表示されます（\"作成済, URL\" > 各フォームを選択 > \"認証\"）。",
        SentEmailTooltipText: "メール送信済（「送信済メール一覧」参照）",
        IsBell: "このフォームの受信時、取下げ時、公開期間終了時にメールを受信する設定がされています",
        globalSearch: "選択中のステータスにあるフォームの書類名、管理番号、To部門、To組織名、メモ、From Email、書類ID、書類毎IDを検索（フィルタ）します",
        globalSearchInputTextTooltip: "Enterキーを押して検索",
        rowEnd: "**ここまで**",
        IsPasswordLockedTooltip: "閲覧者・フォーム入力者に対して、パスワード入力画面を表示する設定が「ON」になっています。",
        IsBeforeShowMessageTooltip: "閲覧者・フォーム入力者に対して、閲覧・入力前にメッセージ/CAPTCHAなどを表示する設定が「ON」になっています。",
        IsAfterShowMessageTooltip: "閲覧者・フォーム入力者に対して、送信後に、メッセージを表示する設定が「ON」になっています。",
    },

    operationButtonBar: {
        editURLButtonText: "公開用のURLを指定する",
        passURLButtonText: "フォームを切り替える",
        garbageBox: "ゴミ箱",
        copy: "コピー",
        confirm: "確定(作成済へ)",
        invite: "招待",
        notifications: "通知設定",
        messageAfterFormSubmission: "送信後のメッセージ",
        shortUrl: "URLを短縮する",
        confirmedUserDisplayInCreated: "チェック済",
        confirmedUserDisplayInReceived: "チェック済",
        cancelConfirmed: "チェックの取消",
        cancelConfirmedInReceived: "チェックの取消",
        getURL: "URL取得",
        replaceURL: "差しかえ",
        toTemplateListText: "このフォーム/ Webページへのリンクは以下です。このリンクをコピーしてWebサイトやメール等で共有してください。\n※パスワード、利用規約、CAPTCHA、メッセージ、通知などは、リンクを共有した後でも追加・変更することができます。",
        toTemplateListText2: "申請者はこのリンクをクリックした後、メール認証、入力、送信をおこないます",
        garbageTemplateLinkText: "このフォーム/ Webページへのリンクは以下です。このリンクをコピーしてWebサイトやメール等で共有してください。\n※パスワード、利用規約、CAPTCHA、メッセージ、通知などは、リンクを共有した後でも追加・変更することができます。",
        garbagedFormURLTextInfo: "以下のURLは公開が停止フォームまたは切り替え後のフォームにリンクします",
        toPreviewWindowLinkText: "このドキュメントをプレビューするためのリンクです。このリンクをコピーして、Webやスマートフォンでアクセスしてください。",
        toEmailURL: "フォーム送信者のメールアドレスを認証する",
        reject: "不受理",
        accept: "受理",
        deleted: "削除済",
        done: "対応完了(保管箱)",
        toReject: "\"不受理\"へ",
        toAccept: "\"受理\"へ",
        toDeleted: "削除",
        toDone: "\"保管箱\"へ",
        sendAcceptedEmail: "受理メール",
        sendRejectedEmail: "不受理メール",
        sendDonedEmail: "対応完了メール",
        moveToGarbege: "ゴミ箱へ移しますか?\n公開が停止され、「公開期間」などのすべての項目が変更できなくなります",
        alreadySent_once: "既に送信しています。再度送信しますか?",
        cautionConfirmTemplateForm: "「個人」または「法人」のどちらかまたは両方が作成されていない可能性があります\n確定しますか？",
        cautionConfirmTemplateForm2: "公開開始年月日: 現在以降の年月日を指定できます\n公開終了年月日: 料金プランにより指定できる年月日が異なります",
        cautionConfirmTemplateForm2: "「作成済」を参照し以下をおこなえます。\n\n（１）通知の設定\n（２）公開・共有リンクの取得\n（３）公開年月日の指定\n      *公開開始年月日: 現在以降の年月日を指定できます\n      *公開終了年月日: 料金プランにより指定できる年月日が異なります",
        previewURL: "プレビューURL",
        overwriteURL: "URLを変更・上書きする",
        ope: "操作",
        toEnableSkipEmailAuth: "※フォーム送信者にメール認証を求めることで、フォーム送信者は送信後に送信完了メールを受け取り、そのメール上のリンクから送信したフォームを見ることができます。",
        toEnableSkipEmailAuthTooltip: "※フォーム送信者がメール認証をしない場合、フォーム送信者は送信後に送信されたフォームを見ることはできません",
        previewURLInfo: "プレビュー画面を見た後、修正する必要がある場合はこの画面に戻って修正してください。修正後、「一時保存」クリック、再度プレビュー画面をみて、ブラウザの更新ボタンをクリックすれば修正箇所が反映されます。修正が完了したら「確定（作成済へ）」ボタンをクリックして「作成済」へ移動させてください（上書きできなくできます）。",
        overwriteURL: "URLを変更・上書きする",
        sentNormalBulk: "メール一斉送信",
        moveDetailStatusForm: "移動",
        cantMoveDetailStatus: "移動先のステータス/サブフォルダがありません（設定は「新規作成」ボタンの横のアイコンをクリックしておこなえます）",
        copiedQrcode: "QRコードをコピーしました",
        qrcodeTooltip: "クリックしてQRコードをコピー（png）",
        addPasswordTooltip: "パスワードを追加",
        messageBeforeShowFormDialog: "閲覧者・フォーム入力者へのメッセージ（利用規約、CAPTCHAなど）"
    },

    previewWindow: {
        saveConfirm: "変更が反映されていません。「一時保存」してから再度クリックしてください。"
    },

    EditURLDialog: {
        header: "URLの変更・上書き",
        topText: "公開に利用するURLを指定できます。すでに利用中のURLで指定する場合（URLの引継ぎ）、そのURLで公開されていたフォームは公開停止され、「ゴミ箱」へ移動されます。\n URLの引継ぎは、「To部門」が同じフォームでのみ行えます。引継ぎの際、公開が停止されるフォームが表示された、確認のためのポップアップが表示されます。",
        bottomText: "このURLでフォームを公開します。すでにこのURLが他のフォームの公開に利用されている場合、そのフォームは非表示になり、新フォームが表示されるようになります（旧フォームは公開停止＆「ゴミ箱」へ移動）",
        Publish: "このURLで公開する",
        confirmPopupText: "現在、このURLで「{DOCUMENTNAME}」を公開中です。上書きしますか？ 上書きすると、このフォームは公開停止され「ゴミ箱」へ移動します。",
        overwrite: "上書きする",
        urlEditInfo: "※半角英数、日本語等を利用できます(最低1桁以上)",
    },

    PassURLDialog: {
        header: "このフォームの差しかえ",
        PublishButtonText: "差しかえる",
        info: "このURLで公開する差しかえ後のフォームを以下から選択してください。「To部門」が同じで「作成済」ステータスにあるフォームへ差しかえできます。フォームの差しかえ後、差しかえ前のフォームは公開が停止され「ゴミ箱」へ移動されます。",
        passedTemplate: "差しかえ後のフォーム",
        passingTemplate: "差しかえ前のフォーム",
        SuccessPublish: "このフォームの差しかえを完了しました\n差しかえ前のフォームをゴミ箱へ移動しました",
        beforePublishingConfirm: "差しかえにより、差しかえ前の設定（招待、パスワード、メッセージ、通知）はリセットされます。必要ならメモしてください。差しかえ後に再度設定し直してください。",
    },

    mailDialog: {
        header: {
            sentEmail: "送信済メール",
            receivedEmail: "メール送信",
            notReceivedEmail: "メール送信",
            doneEmail: "メール送信"
        },
        WriterBrowserLanguage: "受信者の言語は{WriterBrowserLanguage}です",
        sendDate: "送受信日時: ",
        subject: "件名: ",
        sendEmailDialodText1: "以下のメールを送信します",
        sendEmailDialodText2: "理由等を追加して送信できます",
        forceClose: "閉じる(保存されません)",
        FromNameInfo: "メール受信者に表示される「差出人」を上書きできます。",
        ReplyToAddressInfo: "メール受信者から返信を受けない場合、\"no-reply{'@'}bankinvoice.com\"にしてください。 メール受信者から返信を受ける場合、返信先のメールアドレスをあなたのメールアドレスにしてください。",
        ToBulkMailText: "（各メールの宛先は各フォームの「From Email(認証済)」です）",
        BCCEmailsPlaceholder: "メールアドレス（複数ある場合、「,」で区切ってください）",
        blankBulkCompanyName: "（各フォームの「To組織名」が表示されます）",
        bulkMailInfo: "選択されたフォームの「From Email(認証済)」に表示されているメールアドレス宛にメールが一斉送信されます。\n「From Email(認証済)」にて「なし(メール認証なし)」と表示されているフォームについてはメールが送信されません。",
        from: "差出人:",
        replayTo: "From,Reply To:",
        bulkFrom: "差出人:",
        bulkReplayTo: "From,Reply To:",
    },

    templateNote: {
        placeHolder: "メモ欄(公開されません)",
        anotherUserEditted: "他のユーザーが他の画面にて編集中です。それを上書きしますか？\n(最近編集したユーザ{USEREMAIL})",
    },
    allDepartmentUser: {
        departmentHeader: "組織別部門別ユーザー一覧 ({count}部門)",
        documentHeader:"フォーム別共有ユーザー一覧 ({length}書類)",
        dontShowDocument: "書類が表示されていません\n共有していない書類は表示されません",
        focusedDocument: "書類名が黄色にフォーカスされました。みつからないときは下へスクロールしてください。共有者については上の説明を参照してください。",
    },

    allDepartmentUserDataTable: {
        what: "「組織」、その組織のすべての「部門」、各部門に属する「ユーザー」の一覧です。ここで以下をおこなえます。<br> （１）「部門」に「ユーザー」を追加（ユーザ -- プラス アイコン）<br> （２）新しい「部門」を登録（＋ アイコン）<br> （３）部門名や組織名の変更（ペン アイコン）など",
        onlyOne: "現在あなたはどの組織・部門にも属していません。",
        // empty: "現在あなたはどの組織・部門にも属していません。他のユーザーから部門への招待をうけたり組織をOFFからONへ変更ください（右上のユーザーアイコン > ワークスペース）",
        empty: "現在あなたはどの組織・部門にも属していません。他のユーザから部門への招待を受けるまではフォームの作成や閲覧ができません。",
        header: {
            name: "組織・部門・ユーザー名",
            userDepartment: "ユーザーの所属部門",
            userEmail: "ユーザーのEmail",
            changeCompanyNameTooltip: "組織名称変更(by この組織内のユーザー)",
            changeDepartmentNameTooltip: "部門名称変更(by  この部門内のユーザーと運用部門のユーザー)",
            changeUserInfoTooltip: "ユーザー情報変更(by この部門のユーザーと運用部門のユーザー)",
            deleteUserInfoTooltip: "退会(by この部門のユーザーと運用部門のユーザー)",
            AddNewDepartmentTooltip: "新しい部門を追加(by 運用部門のユーザー)",
            AddNewUserTooltip: "新しいユーザーを追加(by この部門のユーザーと運用部門のユーザー)",
            changePricePlan: "料金プランを変更(by この部門内のユーザーと運用部門のユーザー)",
            IsCompany: "組織",
            IsOPEDepartment: "運用部門",
            IsDepartment: "部門",
            blankCreatorName: "(氏名が登録されていません)",
            blankDepartmentName: "(部門名が登録されていません)",
            blankCompanyName: "(組織名が登録されていません)",
            InfoDepartment_OPE: "「運用部門」に属するユーザーは、以下を行えます。\n（１）他の部門の追加\n（２）他の部門に新しいユーザーを追加\n（３）他の部門のユーザーの退会\n（４）組織名称変更\n（５）この部門の名称変更\n（６）この部門に新しいユーザを追加\n（７）この部門の料金プランの変更\n（８）この部門内の他のユーザの氏名、所属部門、Emailの変更\n（９）この部門内の他のユーザの退会",
            InfoDepartment: "「部門」に属するユーザは、以下を行えます。\n（１）組織名称変更\n（２）この部門の名称変更\n（３）この部門に新しいユーザを追加\n（４）この部門の料金プランの変更\n（５）この部門内の他のユーザの氏名、所属部門、Emailの変更\n（６）この部門内の他のユーザの退会",
        },
        companyNameInfoDialog: {
            header: "この組織に登録されているユーザーは組織名を変更できます",
            companyName: "組織名",
        },
        departmentNameInfoDialog: {
            header: "この部門に登録されているユーザーは部門名を変更できます",
            departmentName: "部門名",
            departmentCode: "部門コード"
        },
        userInfoDialog: {
            header: "ユーザー情報",
            name: "氏名:",
            department: "所属部門:",
            useCaseName: "※「下書き」にて「作成者」として表示されます",
        },
        editInfoDialog: {
            header: "組織・部門名登録",
            companyName: "組織名",
            departmentName: "部門名",
            departmentCode: "部門コード",
            changeableAtAnyTime: "登録後に変更できます",
        },
        deleteUser: "削除しますか？",
        cantChangeDepartmentName: "所属していない部門の名称は変更できません",
        cantChangeCompanyName: "所属していない組織の名称は変更できません",
        pleaseUpdateInit: "所属する組織名と部門名を登録してください"
    },

    FormDataTable: {
        dontExistCompany: "既に存在しない組織か、組織が異なります",
        dontExistCompanyName: "組織名が変更されています",
        dontExistDepartment: "既に存在しない部門か、組織が異なります",
        dontExistDepartmentName: "部門名が変更されています",
        LinkDepartmentToAllInfo: "一覧へリンクしますか？\n以下をおこなえます。\n- 部門に所属しているユーザの確認\n- 部門名の変更\n- ユーザの情報変更、ユーザの追加・削除\n- 料金プランの変更など\n※部門名が変更されている場合、変更後の部門へリンクします。",
        LinkCompanyToAllInfo: "一覧へリンクしますか？\n以下をおこなえます。\n- 組織に属している部門の確認\n- 組織名の変更\n- 部門の情報変更、部門の追加・削除\n - 料金プランの変更など\n※組織名が変更されている場合、変更後の部門へリンクします。",
        LinkToAllInfo: "一覧へリンクしますか？"
    },

    invitedFormDataTable: {
        what: "「作成済」に表示されてあなたが共有できるフォームの一覧（組織内のすべてのフォームではなく一部のフォーム）と、それらのフォームを共有中のユーザー一覧です。<br> 各フォームを共有するユーザーは、（１）「To部門」のユーザー、（２）フォームに招待された部門のユーザー、（３）フォームに招待されたユーザーのいずれかのユーザーです。<br> （１）のユーザーは、「組織別部門別ユーザー一覧」を参照してください。（２）と（３）のユーザーは、以下のフォームごとの内訳を参照してください。",
        documentName: "書類名",
        departmentName: "To部門名（宛先部門名）",
        companyName: "To組織名",
        cancelShare: "この書類の共有を解除します",
        successRemoveInvitedUser: "この書類の共有を解除しました",
        isInvitedDepartment: "招待された部門",
        isInvitedUser: "招待されたユーザ",
        isInvitedUserName: "(このフォームを個人で共有)",
        showRemoveUserInTemplate: "共有解除\n ※共有（招待）するには、「作成済」 > このフォームを選択 > 「招待」をクリック"
    },

    sentEmailView: {
        title: "送信済メール一覧(45日間)",
        info: "クリックして詳細をみることができます",
        copyTable: "このページをコピー",
        copedTable: "コピーしました!",
        toEmail: "宛先",
        subject: "件名",
        sendUser: "送信者",
        sendDate: "送受信日時",
        content: "内容",
    },

    sentBox: {
        plzPC: "スマートフォンでは送信した書類しか見ることができません。PCでサインインすれば、作成したり受信した書類を見ることができます。"
    },

    inviteUserView: {
        title: "ユーザ登録",
        info: "ユーザーを登録する「組織名」と「部門名」を選択し、ユーザーのメールアドレスと氏名を入力してください<br>登録されたユーザーはメニュー ＞ 一覧(変更)にて確認できます（登録の解除等の変更もできます）<br>*「部門名」に登録先の部門がない場合、部門の新規作成(追加)はメニュー ＞ 部門登録にておこなえます<br>*部門名の変更はメニュー ＞ 一覧(変更)にておこなえます",
        organizationName: "組織名",
        departmentName: "部門名",
        email: "ユーザのEmail",
        name: "ユーザの氏名",
        inviteEmailText: "※ユーザーにパスワードのお知らせメールが送信されます",
        register: "登録",
        departmentEmptyMessage: "まず組織を選択ください",
        companyEmptyMessage: "現在あなたはどの組織・部門にも属していません。他のユーザから部門への招待を受けるまではフォームの作成や閲覧ができません。",
    },

    createDepartmentView: {
        title: "部門登録",
        note: "以下の組織にて、新しい部門を作成します(組織名は「一覧(変更)にて変更できます)",
        note2: "各組織の運用部門ユーザーのみがおこなえます",
        organizationName: "組織名",
        departmentName: "部門名",
        departmentCode: "部門コード",
        optional: "任意",
        newDepartmentNameInputPlaceholder: "新規作成する部門の名称",
        newDepartmentCodeInputPlaceholder: "新規作成する部門のコード",
        register: "登録",
        doneCreatingDepartmentInfo: "「部門登録」した後は、一覧 > 組織をクリックして、部門が作成されたことを確認し、ユーザーを追加してください（ユーザ -- プラス アイコン）。"
    },

    createOrganizationView: {
        title: "組織",
        organizationName: "組織名",
        departmentName: "部門名",
        register: "登録",
        OrganizationNamePlaceholder: "",
        DepartmentNamePlaceholder: "運用部門名を入力",
    },

    inviteUserDialog: {
        header: " 選択している書類を共有する部門・個人を招待します \n 以下のプルダウンにて部門を選択または個人のメールアドレスを入力してください\n *招待(共有)された部門のユーザー、個人は、作成済書類の「確認」、受信した書類の「確認」、「招待」をおこなえます",
        invite: "招待",
        shareMemberText: "現在この書類を共有している部門と共有者",
        department: "(To部門)",
        creator: "(作成者)",
        sharedDepartment: "(部門)",
        sharedUser: "(人)",
        toShareView: "この書類の共有部門、共有者はこちら>>",
        inviteByEmail: "Emailで招待する ",
        isDeleteShareMember: "この書類の共有を解除します",
        unShareButtonTooltip: "共有解除",
        inviteFieldPlaceholder: "招待する部門名またはメールアドレスを入力",
    },

    formNotificationDialog: {
        title: "通知",
        emailFormText: "このフォームが受信された時、取下げられた時、公開期間終了が近づいた時にメールで通知を受信できます。チェックされているユーザーは通知を受信でき、チェックされていないユーザーは受信できません。",
        addMember: "通知を受信するユーザーを追加 >",
        createWebhook: "Webhookを作成",
        cancel: "保存せずに閉じる",
        blankCreatorName: "(氏名が登録されていません)",
        emailFormTextInfo: "このフォームの通知を受信するには、以下の方法があります。\n（１）「一覧」をクリックして、あなたがこのフォームの「To部門」に招待をうける。\n（２）このフォームの「招待」ボタンをクリックして、あなたが属する部門が招待をうける、またはあなた個人が招待をうける。",
        notworkingByPublic: "現在「Webページ形式で公開」が選択されているため、このフォームは送信されません。",
    },

    setting: {
        title: "設定",
        lang: {
            label: "言語"
        },
        update: "更新",
        otherSetting: "その他設定",
    },

    accountSetting: {
        setting: "設定",
        workspace: "ワークスペース",
        upgrade: "アップグレード",
        signout: "サインアウト",
        allDepartment: "すべての部門",
    },

    workspace: {
        select: "選択",
        useSelectedWorkspace: "選択した組織を表示する"
    },

    policy: {
        title: "利用規約：BankInvoice Paper ＊＊＊＊＊市区町村",
        subTitle: "＊＊＊＊＊市区町村（以下「当市区町村」といいます。）が提供する公式サービス「BankInvoice Paper ＊＊＊＊＊市区町村」は、＊＊＊＊＊市区町村が＊＊＊＊＊＊＊＊＊＊の一環として市民の皆様に提供する、市民の皆様が当市区町村に対して電子情報処理組織を用いた申請（以下「電子申請」といいます。）を行うサービス（以下「当サービス」といいます。）です。なお、Bank Invoice株式会社は当サービスに必要なシステムを開発・提供しております。",
        subTitle2: "当サービスをご利用いただくためには、以下のご利用規約（以下「本規約」といいます。) の条項のすべてにご同意いただく必要があります。必ず事前に本規約をご確認及びご理解いただいたうえで、当サービスをご利用いただきますようお願いします。",
        article1: "第1条（目的）",
        article1_li: "本規約は、当市区町村と当サービスを利用する利用者との間の当サービスに関する提供条件及び権利義務関係について必要な事項を定めることを目的とし、利用者と当市区町村との間の当サービスの利用に関わる一切の関係に適用されます。",
        article2: "第2条（本規約への同意）",
        article2_li: "利用者は、本規約の定めに従って当サービスを利用しなければなりません。当サービスの利用者は、当サービスの利用を開始した時点において、本規約の各条項のすべてに同意したものとみなされます。",
        article2_li_liList: [
            "当社の取扱商品・サービスのご提供および開発のため",
            "当社の取扱商品・サービス、各種キャンペーンのご案内のため",
            "当社の取扱商品・サービスの品質向上・充実を目的としたマーケティング・調査・分析のため",
            "当社の保守・サポートのご提供のため",
            "当社のサービスに関する規約等の変更などを通知するため",
            "当社のサービスに関する当社の規約、ポリシー等（以下「規約等」といいます。）に違反する行為に対する対応のため",
            "金融機関の本人口座確認のため",
            "金融機関への振込確認のため",
            "緊急時のご連絡のため",
            "その他、上記利用目的に付随する目的のため",
        ],
        article2_li_liList_length: "10",
        article3: "第3条（個人情報利用目的の変更）",
        article3_li: "当社は、個人情報の利用目的を相当の関連性を有すると合理的に認められる範囲内において変更することがあり、変更した場合、その変更について当社が重要性が高いと判断した場合にはお客様に通知又は公表します。",
        article4: "第4条（BankInvoice Paperアカウントの登録等）",
        article4_li: " 利用者が当サービスで申請を行うためには、Bank Invoice株式会社が提供する共有アカウント(以下「BankInvoice Paperアカウント」といいます。) へのご登録、または利用者が管理する受信可能なメールアドレスのご登録が必要になります。 前項に規定するBankInvoice Paperアカウントへの登録を行う場合には、利用者は事前にBankInvoice Paperアカウント規約（https://＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊＊）に同意する必要があります。 ",
        article5: "第5条（適用範囲）",
        article5_li: "本規約のほか、当サービス内に掲載するサービス利用に関するルールや各種規定(ヘルプやFAQなども含みます。以下「個別規定」といいます。) は、本規約の一部を構成するものとします。本規約の内容と個別規定の内容とが異なる場合は、個別規定の規定が優先して適用されるものとします。",
        article6: "第6条（個人情報の取り扱い）",
        article6_li: " 当市区町村による利用者の個人情報の取扱いは、「＊＊＊＊＊市区町村個人情報保護条例（平成16年条例第11号）」、及び当サービスに必要なシステムを提供しているBank Invoice株式会社のその個人情報の取扱いは、Bank Invoice株式会社がホームページ上で公表している「プライバシーポリシー及び個人情報の取り扱いについて」（https://＊＊＊＊＊＊＊＊＊＊＊＊＊＊）によります。 利用者は、当市区町村及びBank Invoice株式会社が各規定に従い利用者の個人情報を取り扱うことについて同意するものとします。なお、当サービスにおいて取得した利用者の個人情報は、当サービスを利用した申請の事務処理の目的で利用します。",
        article7: "第7条（申請の受領）",
        article7_li: "利用者が、当サービスを利用して、当市区町村に対して電子申請を行った場合には、かかる電子申請は、執務時間内には当市区町村の使用に係る電子計算機に備えられたファイルへの記録がされた時に、執務時間外には翌開庁日に当市区町村に到達したものとみなされます。",
        article8: "第8条（サービスの停止、休止等）",
        article8_li: "当市区町村は、以下のいずれかに該当する場合には、利用者に事前の通知を行うことなく、当サービスの全部または一部の提供を停止、休止または中断等を行うことができるものとします。 当サービスの維持もしくは補修の必要がある場合。 当サービスに係るコンピューター・システムの点検または保守作業を緊急に行う場合。 コンピューター、通信回線等が事故により停止した場合。 地震・落雷・火災・風水害・停電・天災地変・テロなどの不可抗力により当サービスの運営・提供ができなくなった場合。 その他 当市区町村が停止、休止または中断等を必要と合理的に判断した場合。 当市区町村は、前項の規定によって当サービスの停止、休止または中断等を行ったことによって、利用者に生じたいかなる損害に対しても、 当市区町村の責に帰すべき事由がある場合を除き、一切の責任を負わないものとします。",
        article9: "第9条（サービス内容の変更及び提供終了）",
        article9_li: "当市区町村は、その判断により、利用者に事前の通知を行うことなく、当サービスの内容を変更し、または当サービスの提供を終了することができるものとします。 当市区町村は、前項の規定によって当市区町村が行った行為により、利用者または他の第三者に生じたいかなる損害に対しても、当市区町村の責に帰すべき事由がある場合を除き、一切の責任を負わないものとします。",
        article10: "第10条（免責）",
        article10_li: "当市区町村は、当サービスの内容については、厳重に管理し、万全を期しますが、プログラミング上の誤りその他の瑕疵がないこと、利用者の期待する機能・正確性・有用性を有すること及び不具合が生じないことその他内容の正確性及び安全性について、完全に保証するものではなく、何ら責任を負わないものとします。当サービスの利用については、利用者の責任のもとに行うものとし、利用者が当サービスを利用したことにより被ったとされるいかなる損害についても、当市区町村の責に帰すべき事由がある場合を除き、当市区町村は責任を負わないものとします。 利用者が当サービスの利用をする判断については、利用者の責任の下に行うこととし、当市区町村は、当サービスを利用することにより被ったとされるいかなる損害についても、当市区町村の責に帰すべき事由がある場合を除き、一切の責任を負わないものとします。 お使いの端末やブラウザーのバージョンによっては、当サービスの全部もしくは一部を正常にご利用いただけない場合があります。また、当市区町村は、補修、保守またはアップデートを行う義務を負うものではありません。 当市区町村は、当サービスのバク、機能の不備、エラー、障害、通信状況、端末のOSのバージョンアップ等によって、利用者が当サービスを利用することができないために、利用者に生じた損害について、当市区町村の責に帰すべき事由がある場合を除き、一切の責任を負わないものとします。",
        article11: "第11条（本規約の変更等）",
        article11_li: "当市区町村は、必要があると認めるときは、法令の定めに従い、本規約を変更し、または新たな条項を追加することができるものとします。 前項に基づく変更後の本規約は、当市区町村が当該変更後の本規約を適切な方法により周知した日又は当該周知の際に定めた適用開始日から適用されることとし、利用者はこれに同意するものとします。",
        article12: "第12条（準拠法及び管轄）",
        article12_li: "本規約の有効性、解釈及び履行については、日本法に準拠し、日本法に従って解釈されるものとします。 当サービスまたは本規約に起因しまたは関連する一切の紛争については、東京地方裁判所を第一審の専属的合意管轄裁判所とするものとします。",
                            
    },

    xtype: {
        textField: {
            title: "1項目1入力",
            unit: "単位",
        },
        textField2: {
            title: "1項目2入力",
        },
        textField3: {
            title: "1項目3入力",
        },
        textAreaField: {
            title: "1項目1入力(長文・改行対応)"
        },

        multiSelectField: {
            title: "プルダウン(複数選択)",
            placeHolder: "選択してください(複数選択)",
            // optionLabel: "選択肢{n}(選択肢を1行に1つずつ入力、1つ選択)",
            optionLabel: "選択肢は改行によりつくられます",
            otherLabel: "その他",
            otherInputPlaceholder: "「その他」を選ばれた場合は、この空欄に具体的内容をご記入ください。",
        },
        dropDownField: {
            title: "プルダウン(1つ選択)",
            // optionLabel: "選択肢{n}(選択肢を1行に1つずつ入力、1つ選択)",
            placeHolder: "選択してください(1つ選択)",
            optionLabel: "選択肢は改行によりつくられます",
            otherLabel: "その他",
            otherInputPlaceholder: "「その他」を選ばれた場合は、この空欄に具体的内容をご記入ください。",
        },
        checkBoxGroupField: {
            title: "チェックボックス (複数選択)",
            otherLabel: "その他",
            otherInputPlaceholder: "「その他」を選ばれた場合は、この空欄に具体的内容をご記入ください。",
        },
        radioBoxGroupField: {
            title: "ラジオボタン (一つ選択)",
            otherLabel: "その他",
            otherInputPlaceholder: "「その他」を選ばれた場合は、この空欄に具体的内容をご記入ください。",
        },
        yearDateField: {
            title: "年月日",
        },
        timeField: {
            title: "時間",
        },
        timeRangeField: {
            title: "時間 (範囲)",
        },
        periodDateField: {
            title: "年月日(期間)",
        },
        dateField: {
            yearTitle: "年月日",
            periodTitle: "年月日(期間)",
        },
        fileField: {
            title: "ファイル添付",
            enable: "以下のファイルを添付できます\n-\nPDF、OpenDocument Format (odt、ods、odp)\nPNG、GIF、JPEG、BMP、TIFF\ntxt、CSV\nWord 2003(doc)/2010以降(docx,docm)、Excel 2003(xls)/2010以降(xlsx,xlsm)、Powerpoint 2003(ppt)/2010以降(pptx,pptm)、Rich Text Format(rtf)\n 一太郎 (jtd)、花子 (jhd)、三四郎 (jsd)\nMP3、MP4、Windows Media (wma)、QuickTime (mov)\nJW_CAD (jww)、DXF、AutoCAD (dwg)、SFC、P21",
            label: "ファイルを選択",
            invalidFile: "不正なファイル",
            tooLongFile: "ファイル名は最大{LEN}文字まで",
            download: "ファイルをダウンロード",
            clear: "取消"
        },
        editor: {
            title: "注記、案内、条文等の表示\n＊フォーム作成者向け。公開後、入力者が入力・上書きできません（CSVダウンロード不可）。",
            iconText: "閲覧者・フォーム入力者は編集できません"
        },
        editor4Applicant: {
            title: "自由記入欄 \n＊フォーム入力者向け。公開後、入力者が入力・上書きできます（CSVダウンロード不可）。",
            iconText: "フォーム入力者が編集できます"
        },
        blankText: "この欄は空白のままだと表示されません",
        item1Text: "質問・依頼（この欄は空白のままだと表示されません）",
        sortIconTooltip: "上下に移動",
        removeComponentButtonTooltip: "削除",
        copyButtonTooltip: "コピー",
        addButtonTooltip: "入力項目を追加",
        upComponentButtonTooltip: "一つ上に移動",
        downComponentButtonTooltip: "一つ下に移動"
    },

    messageAfterSendingFormDialog: {
        title: "送信後のメッセージ",
        info: "「フォーム形式で公開」されたフォームが送信された後に表示される画面にて以下のメッセージを表示します。メッセージを入力してください。",
        placeholder: "送信いただきありがとうございました。 次は以下のフォームを入力して送信してください。\nhttps://paper.bankinvoice.com/*******",
        notworkingByPublic: "現在「Webページ形式で公開」が選択されているため、このフォームは送信されません。",
    },

    EditFormPasswordDialog: {
        header: "パスワードで保護（公開範囲制限）",
        info: "公開するユーザを制限するために、最初にパスワード入力画面を表示します（上書き可能）。「ON」にするとリンクをクリックした後にパスワードを知っている人だけがリンク先を見ることができます。「OFF」にするとリンクをクリックした人はだれでもリンク先を見ることができます。",
        copyButton: "コピー",
    },

    utils: {
        success: "成功",
        failed: "失敗",
        error: "エラー",
        update: "変更しました",
        registerAndUpdate: "登録/変更しました",
        isUpgrade: "アップグレードしますか？",
        register: "登録",
        details: "詳細",
        send: "送信",
        save: "保存",
        delete: "削除",
        close: "閉じる",
        change: "変更",
        submit: "送信",
        cancel: "キャンセル",
        ok: "OK",
        later: "あとで",
        retry: "やりなおす",
        sendMailSuccess: "メールを送信しました",
        sendMailSuccessPostStore: "メールを送信しました(送信済メール一覧へ)",
        sendMailFailed: "メールの送信に失敗しました",
        sendMailFailedByForm: "「From email」がない等が原因です",
        sendError: "送信エラー",
        registed: "登録しました(一覧へ)",
        copied: "コピーしました",
        sendMailForUserVerify: "本人確認のためメールを送信しました",
        deleteSuccess: "削除しました",
        invalidEmailAddress: "メールアドレスが適切ではありません",
        invalidMaxTextLength: "最大" + constants.maxTextLength + "文字までです",
        anyBlank: "入力してください",
        finishedFormText: "公開は停止または終了しました",
        beforePublicFormText: "公開開始前です",
        notSaved: "保存されません",
        createNewTemplate: "新規作成",
        department: "部門",
        document: "書類",
        appType: {
            new: "新規",
            update: "更新",
            change: "変更",
            abolished: "廃止",
            other: "その他",
        },
        signout: "サインアウト",
        next: "次へ",
        Yes: "Yes",
        No: "No",
        refresh: "リフレッシュ",
        notFoundFormText: "リンク先のPaperer（フォーム）がみあたりません",
        notFoundFormText2: "(リンク先: {LINK})",
        clear: "クリア",
        notFound: "見つかりません",
        selectAForm: "上のフォームをクリックしてください",
        back: "戻る",
        save_ctrl: "Ctrl+Enterで保存",
        backInInputForm: "戻りますか？",
        reconnecting: "再接続しています",
        disconnected: "インターネットに接続されていません",
        moveThisPage: "このページから移動しますか？",
        password: "パスワード",
        blank: "空白",
    },

    logout: {
        logoutConfirm: "ログアウトします"
    },

    upgradePopup: {
        upgradeButton: "アップグレード >"
    },

    selectMovedDetailFormStatusDialog: {
        title: "移動先"
    },

    menuPanelConfigPanel: {
        info: "（１）各ステータスごとにステータス/サブフォルダを作成できます\n（２）「受信トレイ」に作成されたステータス/サブフォルダにてフォームを受信できます（「作成済, URL」> 各フォームを選択> 「自動振り分け」）",
        subfolderInfo: "(1)例えば、「受信トレイ」にて「確認中」「Aさん承認済」「Bさん承認済」などのステータスを追加したり「担当_Aさん」「担当_Bさん」などのサブフォルダを追加できます。\n (2)受信したフォームが「受信トレイ」ではなく、ここで作成されたステータス/サブフォルダへ自動的に振り分けされるように設定できます（「作成済」 > 各フォームの「自動振り分け」 > 振り分け先を選択）。",
        whatDiv: "部門ごとにステータス/サブフォルダ設定（このアイコンは画面左上のプルダウンにて「すべての部門」ではなくある１つの部門を選択するとクリックできるようになります）」",
        ReceivedBoxInfo: "フォームを受信するステータス/サブフォルダを以下で選択してください。\n「作成済, URL」> 各フォームを選択> 「自動振り分け」",
        addedBelow: "下に追加しました",
        plusIconInfo: "ステータス/サブフォルダを作成",
        moveIconInfo: "上下に移動",
        deleteIconInfo: "このステータス/サブフォルダを削除",
        showIconInfo: "このステータス/サブフォルダを表示/非表示",
        deleteDetailStatus: "削除します\nこのステータス/サブフォルダ内のフォームは、「{STATUS}」へ移動されます。",
        whatActiveDepartment: "あなたが招待された部門が表示されています。特定の（１つの）部門を選択すると、\n（１）部門ごとのステータス/サブフォルダを追加できます（「新規作成」ボタン横のアイコンを参照）。\n（２）「受信トレイ」に追加されたステータス/サブフォルダで、フォームを自動振り分けして受信できます（「作成済」の「自動振り分け」欄を参照）。",
        userInfoTooltip: "メールアドレス変更\nパスワード変更\n言語\nタイムゾーン\n年月日表示\nサインアウト\n\nアプリ切替え\nアップグレード",
    },

    socketAuto: {
        autoChangedStatus: "このフォームのステータスは他のユーザーにより他の画面にて変更されました。",
        autoChangedStatus2Confirm: "このフォームは他のユーザーにより他の画面にて確定されました。変更できません。(「作成済」を参照)"
    },

    MessageBeforeShowFormDialog: {
        title: "閲覧者・フォーム入力者へのメッセージ（利用規約、CAPTCHAなど）",
        messageInfo: "閲覧者・フォーム入力者へメッセージ、利用規約、利用規約への同意依頼、CAPTCHAなどを表示できます。",
        message1Placeholder: "メッセージ 1/2（ON：このメッセージを表示、OFF：このメッセージを非表示）\n例）閲覧・フォーム入力前に以下の利用規約をご確認ください。\n･利用規約に同意される方は、以下のチェックボックスにチェックをいれてください。\n･以下のすべての項目にチェックがある場合のみ、次の画面（フォーム入力画面）へ進むことができます。",
        message2Placeholder: "メッセージ 2/2（ON：このメッセージを表示、OFF：このメッセージを非表示）\n** ここに利用規約などを記載 **",
        checkboxInfo: "閲覧者・フォーム入力者が同意すべきことを以下に列挙してください。\n ※すべてのBOXにチェックが入れられた時だけ「次へ >」ボタンで先に進めます。",
        checkbox1Placeholder: "利用規約、および「個人情報の取り扱いについて」を承認のうえ、申請します。",
        checkbox2Placeholder: "送信後に変更が生じた場合には、速やかに再度作成して送信しなおします。",
        checkbox3Placeholder: "(空白のままにすると、この行全体が非表示になります)",
        authenticationInfo:  "認証",
        whatAuthenticationInfo:  "この認証はCAPTCHAの代替手段です。「ON」にすると閲覧者・フォーム入力者による不正使用を防止できます。\n※閲覧者・フォーム入力者が上のメッセージを見たときに認証が行われ、以下のように表示されます。",
    },

    preFormMessage: {
        conditionActiveNextButton: "※すべてのボックスにチェックを入れてください"
    },

    plans: {
        upgradeButtonText: "今すぐアップグレード",
        changePlanButtonText: "プラン変更",
        currentPlanButtonText: "現在利用中のプラン",
        inquiryText: "営業に連絡して見積もりを依頼する",
        inquiryButtonText: "営業へ連絡 >",
        inquiryButtonURL: "https://app.paper.bankinvoice.com/inputforms/adCOqxg8vC/fJ1Uh5rSkg",
        paidButtonText: "開始する",
        basePriceUnit: "USD/月",
        priceUnit: "部門/月",
        currency: "USD",
        people: "人",
        basePriceYearUnit: "USD/年",
        priceYearUnit: "USD/年",
        mainFunc: "主な機能",
        freeConfirm1: "ダウングレード後以下の条件に変更されます",
        freeConfirmList1: "フォームの公開期間は最大1ヶ月",
        freeConfirmList2: "フォームのメールアドレス認証機能なし",
        freeConfirmList3: "部門メンバー数は最大10名",
        freeConfirmAs1: "※公開期間はダウングレードした日から（本日）最長1ヶ月となります",
        freeConfirmAs2: "※フォーム（公開中のフォーム含む）のメールアドレス認証がスキップされます",
        freeConfirmAs3: "※部門メンバー数が10名を超えている場合、メンバー数をそれ以上増やすことはできなくなります",
        free: {
            title: "Free",
            forUser: "個人またはチーム向け",
            fee: {
                year: "0 USD/月",
            },
            members: "最大10名/部門",
            receiveDataAmount: "無制限",
            receiveDataStatus: true,
            publicFormAmount: "300",
            publicFormRange: "最長1ヶ月",
            csv: false,
            funcInfo: "Freeプランには以下が含まれています",
            funcList: [
                "部門ごとのメンバー数 最大10人",
                "無制限のストレージ 最大2MB/ファイル",
                "フォームごとの公開期間 最大30日",
                "CSV出力 最新100件/部門",
                "閲覧可能フォーム数 最新100件/部門",
                "公開可能なフォーム数 無制限",
                "公開用URLの指定・上書き",
                "フォーム受信数　無制限",
                "フォームのステータス管理",
                "フォームのメモ・検索",
                "フォーム受信時・取下げ時にメール通知",
                "パスワードによる保護",
                "利用規約・CAPTCHA等の表示",
                "フォーム送信後のメッセージ表示",
                "フォームの切り替え（個人用/法人用）",
                "公開中フォームの差替え",
                "フォーム作成時のプレビュー",
            ]
        },
        business: {
            title: "Business",
            forUser: "部門あたり月額、\n1部門に1人でも50人でも同じ月額",
            fee: {
                year: "130 USD/年",
                month: "13 USD/月",
            },
            members: "最大50名/部門",
            receiveDataAmount: "無制限",
            receiveDataStatus: true,
            publicFormAmount: "無制限",
            publicFormRange: "無制限",
            csv: false,
            funcInfo: "Free の内容すべてに加えて：",
            funcList: [
                "部門ごとのメンバー数 最大50人",
                "無制限のストレージ 最大20MB/ファイル",
                "フォームごとの公開期間 無制限",
                "CSV出力 最新1,000件/部門",
                "閲覧可能フォーム数 最新1,000件/部門",
                "フォーム送信者メールアドレスの認証",
                "フォーム送信者への送信済フォームの表示",
                "フォーム送信者へのステータス表示",
                "フォーム送信者による取下げ（送信取消）",
                "フォーム送信者によるフォームへのメモ",
                "フォーム送信者宛にメール送信",
                "フォームの下に作成者（組織名）の表示",
                "優先サポート（48時間以内に回答）",
            ]
        },
        business_plus: {
            title: "Business Plus",
            forUser: "部門あたり月額、\n1部門に1人でも100人でも同じ月額",
            fee: {
                year: "390 USD/年",
                month: "39 USD/月",
            },
            members: "最大100名/部門",
            receiveDataAmount: "無制限",
            receiveDataStatus: true,
            publicFormAmount: "無制限",
            publicFormRange: "無制限",
            csv: false,
            funcInfo: "Business の内容すべてに加えて：",
            funcList: [
                "Business の内容すべてに加えて：",
                "部門ごとのメンバー数 最大100人",
                "無制限のストレージ 最大50MB/ファイル",
                "CSV出力 最新10,000件/部門",
                "閲覧可能フォーム数 最新10,000件/部門",
                "送信時にエラーチェック（項目ごと）",
                "優先サポート（24時間以内に回答）",
            ]
        },
        enterprise: {
            title: "Enterprise",
            forUser: "カスタマイズと既存システムへのデータ連携",
            fee: {
                year: "10 USD/月",
                month: "12 USD/月",
            },
            members: "無制限",
            receiveDataAmount: "無制限",
            receiveDataStatus: true,
            publicFormAmount: "無制限",
            publicFormRange: "無制限",
            csv: true,
            funcInfo: "Business の内容すべてに加えて：",
            funcList: [
                "部門ごとのメンバー数 無制限",
                "無制限のストレージ", 
                "CSV出力 無制限",
                "閲覧可能フォーム数 無制限",
                "既存システムへのAPI連携",
                "高度なセキュリティ",
                "アクセス制御",
                "サイバー驚異の防止",
                "優先サポート（10時間以内に回答）",
            ],
        },
    },

    locale: {
        today: "今日",
        save: "保存",
        now: "今",
        clear: "クリア",
    },

    inputFormsRedirect: {
        confirmText: "クリックされたリンクは、見た目のリンク先と実際のリンク先が異なるようです。\nこれはリンクがクリックされたかどうかを追跡するために使用されることがありますが、詐欺の可能性もあるので注意が必要です。\n\n見た目のリンク:\n{TEXTURL}\n実際のリンク:\n{URL}",
        openRedirectUrl: "{URL}を開く",
        openRedirectUrlText: "{URL}を開く"
    },
}
