<template>
    <div v-if="editor" class="width-100 fullEditorsEditorContainer" :style="{'min-height': disabled ? '20px' : '185px'}" >
        <bubble-menu
                class="bubble-menu"
                :tippy-options="{ duration: 100, placement: 'top-start' }"
                :editor="editor"
                style="flex-direction: column"
                v-if="!disabled"
                :should-show="shouldShow"
                >
                <div style="flex: 1; display: flex;">
                    <Dropdown style="" class="dropdown-120 labelEditorFontFamilySelect" v-model="selectedTextFontFamily" :options="fontFamilyList" optionLabel="label" optionValue="value" @change="changeFontFamily" > </Dropdown>
                                <span class="material-symbols-outlined cursor_pointer" style="position: relative;" @click="onFontSizeIconClick" > format_size
                        <Dropdown ref="labelEditorFontSizeSelect" class="labelEditorFontSizeSelect" v-model="selectedTextFontSize" :options="fontSizeList" optionLabel="label" optionValue="value" @change="changeFontSize" > </Dropdown>
                    </span>
                    <span class="material-symbols-outlined cursor_pointer" @click="onFontSizeDecIconClick" > text_decrease </span>
                    <span class="material-symbols-outlined cursor_pointer" @click="onFontSizeIncIconClick" > text_increase </span>

                    <span class="material-symbols-outlined cursor_pointer" @click="editor.chain().focus().toggleBulletList().run()" :class="{ 'is-active': editor.isActive('bulletList') }"> format_list_bulleted </span>
                    <span class="material-symbols-outlined cursor_pointer" @click="setLink" :class="{ 'is-active': editor.isActive('link') }"> link </span>
                    <span class="material-symbols-outlined cursor_pointer" @click="editor.chain().focus().unsetLink().run()" :disabled="!editor.isActive('link')"> link_off </span>
                </div>
                <div style="flex: 1;">
                    <span class="material-symbols-outlined cursor_pointer" @click="onCopyTextIconClick" :class="{ 'is-active': editor.isActive('library_books') }"> library_books </span>
                    <span class="material-symbols-outlined cursor_pointer" @click="editor.chain().focus().toggleBold().run()" :class="{ 'is-active': editor.isActive('bold') }"> format_bold </span>
                    <span class="material-symbols-outlined cursor_pointer" @click="toggleUnderlineStrike" :class="{ 'is-active': editor.isActive('strike') }"> strikethrough_s </span>
                    <span class="material-symbols-outlined cursor_pointer" @click="toggleUnderline" :class="{ 'is-active': editor.isActive('underline') }" > format_underlined </span>
                    <span class="material-symbols-outlined cursor_pointer" @click="toggleUnderlineSquiggle" :class="{ 'is-active': editor.isActive('underlineSquiggle') }" > format_underlined_squiggle </span>
                    <span class="material-symbols-outlined cursor_pointer" style="position: relative;text-shadow: black 0px 0px;" v-bind:style="{ 'color': selectedHighlight }" @click="onHighlightIconClick" @mouseleave="onHighlightIconLeave" :disabled="!editor.isActive('backgroundColor')"> format_ink_highlighter
                        <Dropdown ref="hightLightColorDropdown" class="labelEditorHighlightSelect" :options="colorList" optionValue="labelValue" @change="changeHighlight"  >
                        <template #value="slotProps">
                            <div class="" v-if="selectedHighlight" style="height: 100%; width: 100%; position: absolute; top: 0px; left: 0px;" v-bind:style="{ 'background-color': selectedHighlight }">
                            </div>
                        </template>
                        <template #option="slotProps">
                            <div class="" style=" height: 100%; width: 100%; position: absolute; top: 0px; left: 0px;" v-bind:style="{ 'background-color': slotProps.option.labelValue }">
                            </div>
                        </template>
                        </Dropdown>
                    </span>
                    <span class="material-symbols-outlined cursor_pointer" style="position: relative;text-shadow: black 0px 0px;" v-bind:style="{ 'color': selectedTextFontColor }" @click="onFontColorIconClick" :disabled="!editor.isActive('color')"> format_color_text 
                        <Dropdown ref="fontColorDropdown" class="labelEditorFontColorSelect" :options="fontColorList" optionValue="value" @change="changeFontColor"  >
                        <template #value="slotProps">
                            <div class="" v-if="selectedTextFontColor" style="height: 100%; width: 100%; position: absolute; top: 0px; left: 0px;" v-bind:style="{ 'background-color': selectedTextFontColor }">
                            </div>
                        </template>
                        <template #option="slotProps">
                            <div class="" style=" height: 100%; width: 100%; position: absolute; top: 0px; left: 0px;" v-bind:style="{ 'background-color': slotProps.option.value }">
                            </div>
                        </template>
                        </Dropdown>
                    </span>
                    <span class="material-symbols-outlined cursor_pointer" @click="onFormatAlignClick('left')" > format_align_left </span>
                    <span class="material-symbols-outlined cursor_pointer" @click="onFormatAlignClick('center')" > format_align_center </span>
                    <span class="material-symbols-outlined cursor_pointer" @click="onFormatAlignClick('right')" > format_align_right </span>
                    <input type="file" style="display: none;" ref="uploadButton" @change="changeUploadFile" />
                </div>
        </bubble-menu>
        <floating-menu :editor="editor" :tippy-options="{ duration: 100 }">
            <div style="width: 14px; height: 10px; display: inline-block;" />
            <span class="material-symbols-outlined cursor_pointer" @click="onPasteTextIconClick" :class="{ 'is-active': editor.isActive('content_paste_go') }"> content_paste_go </span>
            <span class="material-symbols-outlined cursor_pointer" @click="editor.chain().focus().toggleBulletList().run()" :class="{ 'is-active': editor.isActive('bulletList') }"> format_list_bulleted </span>
            <span class="material-symbols-outlined cursor_pointer" @click="onImageIconClick" > image </span>
            <input type="file" style="display: none;" ref="uploadButton" @change="changeUploadFile" />
            <span class="material-symbols-outlined cursor_pointer" @click="addVideo" > youtube_activity </span>
        </floating-menu>


    <editor-content ref="editor" :editor="editor" class="templateFullEditorsEditor" :class="{'label-editor-disabled': disabled, 'viewerFullEditorsEditor': disabled}" @dragover.prevent="onDrag('over')"  @drop.prevent="onDrop($event)" 
        @paste.prevent="onPaste($event)"

      @keydown.ctrl.v.exact="onPaste"
      @paste="onPaste"
        @click="onEditorContentClick" />
        <i v-if="isEditor4Applicant" style="position: absolute; bottom: 10px; left: 10px; " v-tooltip="$t('xtype.editor4Applicant.iconText')">
            <span class="material-symbols-outlined" style="font-size: 16px;" > edit </span>
        </i>
        <i v-else-if="!applicantMode" style="position: absolute; bottom: 10px; left: 10px; " v-tooltip="$t('xtype.editor.iconText')">
            <span class="material-symbols-outlined" style="font-size: 16px;" > edit_off </span>
        </i >
    <EditorImagePopup :imgs="src" :index="imageIndex" :visibleProps="isShowPreview" @hide="isShowPreview=false" />
    </div>
</template>

<script>
import fileUploadHelper from "@/mixins/fileUploadHelper"
import EditIcon from "@/components/Icons/editIcon";
import EditUserIcon from "@/components/Icons/userEditIcon";
import EditorImagePopup from "@/components/EditorImagePopup";

import StarterKit from '@tiptap/starter-kit'
import Link from '@/components/tiptap-extensions/link'
import Underline from '@/components/tiptap-extensions/underline'
import UnderlineSquiggle from '@/components/tiptap-extensions/underline-squiggle'
import UnderlineStrike from '@/components/tiptap-extensions/strike'
import BackgroundColorExtension from '@/components/tiptap-extensions/background-color'
import FontFamily from '@tiptap/extension-font-family'
import { Color } from '@tiptap/extension-color'
import TextStyle from '@tiptap/extension-text-style'
import FontSize from '@/components/tiptap-extensions/font-size'
import TextAlign from '@tiptap/extension-text-align'
import Heading from '@/components/tiptap-extensions/heading'

import Document from '@tiptap/extension-document'
import Dropcursor from '@tiptap/extension-dropcursor'
import Image from '@tiptap/extension-image'
import Paragraph from '@tiptap/extension-paragraph'
import Text from '@tiptap/extension-text'
import Youtube from '@tiptap/extension-youtube'
import { Editor, EditorContent, BubbleMenu, FloatingMenu } from '@tiptap/vue-3'
import Tooltip from 'primevue/tooltip'
import Dropdown from 'primevue/dropdown';
import helper from "@/mixins/helper";


export default {
    name: 'fullEditorsEditor',
    props: ['disabled', 'content', 'templateId', 'isEditor4Applicant', 'applicantMode'],
    emits: ['setDataInItem'],
    components: {
        EditorContent,
        EditIcon,
        EditUserIcon,
        EditorImagePopup,
        Dropdown,
        BubbleMenu,
        FloatingMenu,
    },
    data() {
        return {
            editor: null,
            isShowPreview: false,
            src: "",
            fontFamilyList: this.$constants.tiptapEditorsFontFamilyList,
            fontSizeList: this.$constants.tiptapEditorsFontSizeList,
            colorList: this.$constants.tiptapEditorsColorList,
            fontColorList: this.$constants.tiptapEditorsFontColorList,
        }
    },
    watch: {
    },
    mounted() {
        const self = this
        this.editor = new Editor({
            extensions: [
                Document,
                Paragraph,
                Text,
                Image,
                Dropcursor,
                StarterKit.configure({
                    strike: false,
                }),
                Link.configure({
                    openOnClick: false,
                }),
                Underline,
                UnderlineSquiggle,
                UnderlineStrike,
                BackgroundColorExtension,
                TextStyle,
                FontFamily,
                Color,
                FontSize,
                TextAlign.configure({
                    types: ['heading', 'paragraph'],
                }),
                Heading.configure({
                }),
                Youtube,
            ],
            content: this.content,
            editable: !this.disabled,
            onUpdate({ editor, event }) {
                if (!self.disabled) {
                    self.input({html: editor.getHTML(), text: editor.getText()})
                }
            },
        })
    },

    beforeUnmount() {
        this.editor.destroy()
    },

    computed: {
        selectedTextFontFamily: {
            get() {
                for (let i = 0; i < this.fontFamilyList.length; i++) {
                    const isActiveFont = this.editor.isActive('textStyle', { fontFamily: this.fontFamilyList[i].value })
                    if (isActiveFont) {
                        return this.fontFamilyList[i].value
                    }
                }
                return this.fontFamilyList[0].value // default
            },
            set(value) {
            },
        },
        selectedTextFontSize: {
            get() {
                let defaultValue
                for (let i = 0; i < this.fontSizeList.length; i++) {
                    if (this.fontSizeList[i].isDefault) {
                        defaultValue = this.fontSizeList[i].value
                    }
                }
                
                const textStyle = this.editor.getAttributes('textStyle')
                const fontSizeText = textStyle.fontSize ? textStyle.fontSize : defaultValue + "px"
                const fontSize = Number(fontSizeText.replace("px", ""));
                for (let i = 0; i < this.fontSizeList.length; i++) {
                    if (this.fontSizeList[i].value == fontSize) {
                        return this.fontSizeList[i].value
                    }
                }
            },
            set(value) {
            },
        },
        selectedHighlight: {
            get() {
                for (let i = 0; i < this.colorList.length; i++) {
                    const isActiveColor = this.editor.isActive('textStyle', { backgroundColor: this.colorList[i].value })
                    if (isActiveColor) {
                        return this.colorList[i].value
                    }
                }
                return this.colorList[0].value
            },
            set(value) {
            },
        },
        selectedTextFontColor: {
            get() {
                for (let i = 0; i < this.fontColorList.length; i++) {
                    const isActiveFont = this.editor.isActive('textStyle', { color: this.fontColorList[i].value })
                    if (isActiveFont) {
                        return this.fontColorList[i].value
                    }
                }
                return this.fontColorList[0].value
            },
            set(value) {
            },
        },
    },
    methods: {
        input: function(e={}) {
            const data = {
                html: e && e.html ? e.html : this.item.Placeholder,
            }

            this.$emit("setDataInItem", data)
        },
        onDrag(e) {
        },

        async onDrop(e) {
            const files = e.dataTransfer.files;
            await this.uploadImages(files)
        },

        uploadImages(files) {
            const self = this
            if (!this.templateId && !this.isEditor4Applicant) return

            for (let i = 0; i < files.length; i++) {
                const file = files[i]
                const reader = new FileReader()
                reader.onload = async (event) => {
                    const base64Text = event.currentTarget.result
                    const res = await fileUploadHelper.image64UploadInTemplate(base64Text, file, self.templateId)
                    if (res.Success) {
                        self.editor.chain().focus().setImage({ src: res.url }).run()
                    }
                    
                }
                reader.readAsDataURL(file)
            }
        },

        onEditorContentClick(e, t) {
            const target = e.target
            if (target.localName == "img") {
                this.src = target.currentSrc
                this.isShowPreview = true
                this.imageIndex = 0
            }
        },

        setLink() {
            // const previousUrl = this.editor.getAttributes('link').href
            const previousUrl = this.editor.getAttributes('link').originalHref
            const url = window.prompt('URL', previousUrl)

            // cancelled
            if (url === null) {
                return
            }

            // empty
            if (url === '') {
                this.editor
                    .chain()
                    .focus()
                    .extendMarkRange('link')
                    .unsetLink()
                    .run()

                return
            }
            this.onSetLink(url)
        },

        onSetLink(url) {
            const self = this
            this.editor
                .chain()
                .focus()
                .run()

            setTimeout(() => {
                const link = self.$constants.local + "/inputforms_redirect?redirectUrl=" + encodeURIComponent(url) + "&redirectUrlText=" + encodeURIComponent(window.getSelection().toString())
                self.editor
                    .chain()
                    .focus()
                    .extendMarkRange('link')
                    .setLink({ href: link, originalHref: url })
                    .run()

            }, 100)
        },
        changeFontFamily(e) {
            const fontFamily = e.value
            if(fontFamily && fontFamily != "Defalt") {
                this.editor.chain().focus().setFontFamily(fontFamily).run()
            } else {
                this.editor.chain().focus().unsetFontFamily().run()
                this.selectedTextFontFamily = "Defalt"
            }
        },
        changeHighlight(e) {
            const color = this.colorList.find(x => x.labelValue == e.value)
            const highLight = color.value
            if(highLight) {
                this.editor.chain().focus().setBackgroundColor(highLight).run()
            } else {
                this.editor.chain().focus().unsetBackgroundColor().run()
            }
        },
        changeFontSize(e) {
            const fontSize = e.value
            if(fontSize) {
                this.editor.chain().focus().setFontSize(fontSize + "px").run()
            } else {
                this.editor.chain().focus().unsetFontSize().run()
                // this.selectedTextFontSize = ""
            }
        },
        changeFontColor(e) {
            const color = e.value
            if(color) {
                this.editor.chain().focus().setColor(color).run()
            } else {
                this.editor.chain().focus().unsetColor().run()
            }
        },
        onHighlightIconClick(e) {
            this.$refs.hightLightColorDropdown.show()
            this.editor.chain().focus().run()
        },
        onHighlightIconLeave(e) {
            // this.$refs.hightLightColorDropdown.hide()
        },
        onFontColorIconClick(e) {
            this.$refs.fontColorDropdown.show()
            this.editor.chain().focus().run()
        },

        onFontSizeIconClick() {
            this.$refs.labelEditorFontSizeSelect.show()
            this.editor.chain().focus().run()

        },

        toggleUnderline() {
            const textStyle = this.editor.getAttributes('textStyle')
            if(textStyle.underline) {
                this.editor.chain().focus().unsetUnderline('underline').run()
            } else {
                this.editor.chain().focus().setUnderline('underline').run()
            }
        },

        toggleUnderlineSquiggle() {
            const textStyle = this.editor.getAttributes('textStyle')
            if(textStyle.underlineWavy) {
                this.editor.chain().focus().unsetUnderlineSquiggle('underline wavy').run()
            } else {
                this.editor.chain().focus().setUnderlineSquiggle('underline wavy').run()
            }
        },
        toggleUnderlineStrike() {
            const textStyle = this.editor.getAttributes('textStyle')
            if(textStyle.underlineStrike) {
                this.editor.chain().focus().unsetUnderlineStrike('line-through').run()
            } else {
                this.editor.chain().focus().setUnderlineStrike('line-through').run()
            }
        },
        onImageIconClick() {
            this.$refs.uploadButton.click();
        },
        onFormatAlignClick(align) {
            this.editor.chain().focus().setTextAlign(align).run()
        },
        changeUploadFile(e) {
            this.uploadImages(e.target.files)
        },
        shouldShow({ editor, view, state, oldState, from, to }) {
            if (from == to) return false;
            if (state.selection.node && state.selection.node.content && state.selection.node.content.content.length == 0) return 
            if (state.selection.node && state.selection.node.type.name == "image") return false; // nodeは普通はないらしい
            return true;
        },
        getNextFontSize(is_large, value) {
            const fontSizeList = this.fontSizeList
            for (let i = 0; i < fontSizeList.length; i++) {
                if(value == fontSizeList[i].value) {
                    if (is_large) {
                        if (i == 0) return value
                        return fontSizeList[i-1].value
                    } else {
                        if (i == fontSizeList.length - 1) return value
                        return fontSizeList[i+1].value
                    }
                }
            }
        },

        onFontSizeIncIconClick() {
            const textStyle = this.editor.getAttributes('textStyle')
            const fontSizeText = textStyle.fontSize ? textStyle.fontSize : "14px"
            const fontSize = Number(fontSizeText.replace("px", ""));
            this.changeFontSize({value: this.getNextFontSize(true, fontSize)})
        },
        onFontSizeDecIconClick() {
            const textStyle = this.editor.getAttributes('textStyle')
            const fontSizeText = textStyle.fontSize ? textStyle.fontSize : "14px"
            const fontSize = Number(fontSizeText.replace("px", ""));
            this.changeFontSize({value: this.getNextFontSize(false, fontSize)})
        },
        async onPaste(event) {
            const result = await helper.getDataListFromClipboard(event)
            if(!result.Success) {
                return 
            }
            if (result.DataType == "file") {
                const fileList = result.Data
                if (fileList.length) {
                    event.preventDefault()
                } else {
                    return 
                }
                await this.uploadImages(fileList)
            }
        },
        addVideo() {
            const url = prompt('Enter YouTube URL')
            this.editor.commands.setYoutubeVideo({
                src: url,
                // width: Math.max(320, parseInt(this.width, 10)) || 640,
                width: "100%",
                height: Math.max(180, parseInt(this.height, 10)) || 480,
            })
        },
        onCopyTextIconClick() {
            const { from, to } = this.editor.state.selection
            const text = this.editor.state.doc.textBetween(from, to, ' ')
            helper.copyTextToClipboard(text ? text : " ")
        },
        async onPasteTextIconClick(e) {
            const text = await navigator.clipboard.readText();
            const { from } = this.editor.state.selection
            this.editor.commands.insertContentAt(from, text)
        },


    },
    directives: {
        'tooltip': Tooltip
    },
}
</script>

<style scoped>
</style>
