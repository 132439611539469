<template>
    <template v-if="Type == 'Template'">
        <template v-if="Status == 0">
            <FormDataTableDraft :GridData="gridData" @selectedRow="selectedRow($event)" @linkToDocumentList="linkToDocumentList({}, $event.record, $event.isCompany)" 
                  :Status="Status"
                  :rows="rows"
                  :totalRecords="totalRecords"
                  @onPage="onPage($event)"
                  @onSort="onSort($event)"
                  @onFilter="onFilter($event)"
                  @mouseover="pagingMouseOver"
                  @mousedown="startTopSplitterPanel($event)"
                  @touchstart="touchStartTopSplitterPanel($event)"
                  @touchmove="moveTopSplitterPanel($event)"
                  @touchend="endTopSplitterPanel($event)"
            />
        </template>
        <template v-else>
            <FormDataTableCreated 
                  :GridData="gridData" 
                  :totalRecords="totalRecords"
                  :showColumn="['DocumentName', 'DepartmentName', 'ConfirmedUserDisplayInCreated', 'CompanyName', 'TemplateNote', 'OpenBegin', 'OpenEnd']"
                  :Status="Status"
                  :rows="rows"
                  @selectedRow="selectedRow($event)" 
                  @linkToDocumentList="linkToDocumentList({}, $event.record, $event.isCompany)"  
                  @showFormMemberListDialogClick="showFormMemberListDialogClick($event.e, $event.record, $event.Type)"
                  @onPage="onPage($event)"
                  @onSort="onSort($event)"
                  @onFilter="onFilter($event)"
                  @mouseover="pagingMouseOver"
                  @mousedown="startTopSplitterPanel($event)"
                  @touchstart="touchStartTopSplitterPanel($event)"
                  @touchmove="moveTopSplitterPanel($event)"
                  @touchend="endTopSplitterPanel($event)"
                  @reloadTable="onReloadFormDataColumnButtonClick"
              />
        </template>
    </template>
    <template v-else-if="Type == 'Form'">
        <DataView v-if="isMobile.isMobile()" 
            class="p-dataview-sm postedFormDataTable width-100"
            :value="gridData" :layout="DataViewLayout"  >
                <template #header>
                    <div class="p-d-flex  p-jc-between">
                        <div style="place-self: center;">
                            <i class="pi pi-refresh cursor_pointer" @click="onReloadFormDataColumnButtonClick" />
                        </div>
                        <div class="text-right">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText style="padding: 5px 2rem; "  v-model="filters1['global'].value" placeholder="Keyword Search" @change="filters1['global'].value = filters1['global'].value.trim()" />
                            </span>
                            <Button type="button" icon="pi pi-filter-slash" label="Clear" class="p-button-outlined" @click="clearFilter1()"/>
                        </div>
                    </div>
                </template>
                <template #empty>
                    No customers found.
                </template>
                <template #loading>
                    Loading customers data. Please wait.
                </template>

			<template #list="slotProps">
				<Card class="p-ripple cursor_pointer p-col-12 card-content-padding-0" @click="selectedRow(slotProps)">
                    <template #content >
                        <div class="">
                            <div class="width-100" style="display: flex; flex-direction: row; justify-content: space-between;">
                                <div class="text-left text-ellipsis">
                                    <span style="font-size: 17px; font-weight: 300; line-height: 24px;">
                                        {{slotProps.data.CompanyName}} 
                                    </span>
                                </div>
                                <div class="text-right">
                                    <span class="" style="font-size: 12px; white-space: nowrap;">
                                    {{$filters.formatDateAtDate(slotProps.data.SendDate)}} 
                                    </span>
                                </div>
                            </div>
                            <div class="">{{slotProps.data.DocumentName}}</div>
                            <div class="">{{"(" + FormStatusInSenderText(slotProps.data.FormStatus) + ")"}} <i> {{(slotProps.data.FormNote ? slotProps.data.FormNote : "")}} </i></div>
                        </div>
                    </template>
				</Card>
			</template>
        </DataView>
        <DataTable
            v-else
            :scrollable="true"
            scrollHeight="flex"
            v-model:value="gridData" 
            v-model:selection="selectedData" 
            selectionMode="single" 
            dataKey="_id" 
            @row-select="selectedRow"
            class="p-datatable-sm postedFormDataTable  datatable-width-100"
            v-model:filters="filters1" 
            filterDisplay="menu"
            :lazy="true" :paginator="true"
            :rows="rows"
            ref="formDataTableKey"
            :globalFilterFields="[ 'DocumentName', 'DepartmentName', 'FromEmail', 'CompanyName', 'SenderFormNote', 'SendDate', '_id', 'TemplateId']" 
            :totalRecords="totalRecords" :loading="loading" @page="onPage($event)" @sort="onSort($event, 'formDataTableKey')" @filter="onFilter($event)"
            @mouseover="pagingMouseOver" @mousedown="startTopSplitterPanel($event)"
            :rowsPerPageOptions="$constants.rowsPerPageOptions"
            @touchstart="touchStartTopSplitterPanel($event)"
            @touchmove="moveTopSplitterPanel($event)"
            @touchend="endTopSplitterPanel($event)"
            :key="formDataTableKey"
            removableSort 
            :sortField="dynamicSortField"
            :sortOrder="dynamicSortOrder"
            >
            <!-- sortMode="multiple" -->

                <template #header>
                    <div class="p-d-flex  p-jc-between">
                        <div style="place-self: center;" class="p-d-flex ">
                            <Button icon="pi pi-refresh" class="p-button-rounded p-button-text p-button-plain refreshIcon" @click="onReloadFormDataColumnButtonClick" v-tooltip="$t('utils.refresh')" />

                                <div>
                                    <Button icon="pi pi-ellipsis-v" class="p-button-rounded p-button-text p-button-plain ellipsisIcon" @click="onEllipsisFormDataButtonClick" />
                                    <Menu :model="ellipsisMenu" ref="ellipsisMenu" :popup="true" />
                                        <span>
                                            <template v-if="BulkCheckFormList.length">
                                                {{(isSelectedAll ? totalRecords : BulkCheckFormList.length) + '/' + totalRecords}}
                                            </template>
                                        </span>
                                </div>
                        </div>
                        <div style="align-self: center; padding-right: 14px;">
                            <Button type="button" icon="pi pi-filter-slash" :label="$t('utils.clear')" class="p-button-secondary p-button-outlined p-mr-2" @click="clearFilter1()" :class="{'fillInGlobalSearchButton': isGridFiltering}" :disabled="!isGridFiltering" />
                            <span class="p-input-icon-left p-input-icon-right">
                                <i class="pi pi-search" />
                                    <InputText style="padding: 5px 2rem; width: 260px;"  v-model="filters1['global'].value" placeholder="Keyword Search" @focus="onFocusGlobalFilter" @keydown.enter="globalFilterEnter" @change="filters1['global'].value = filters1['global'].value.trim()"  v-tooltip.bottom.focus="$t('formDataTable.globalSearchInputTextTooltip')" />
                                <i class="pi pi-info-circle" v-tooltip="$t('formDataTable.globalSearch')" />
                            </span>
                        </div>
                        
                    </div>
                </template>
                <template #empty>
                    {{$t('formDataTable.ForgetFilter')}}
                </template>
                <template #loading>
                    Please wait.
                </template>

            <Column headerStyle="width: 8px; padding: 0px;" style="max-width: 40px;" >
                <template #header="slotProps">
                    <Checkbox :modelValue="(BulkCheckFormList && gridData && gridData.length) ? gridData.filter((g) => BulkCheckFormList.some((b) => b._id == g._id)).length == gridData.length : false" :binary="true" @input="onBulkCheckBoxInput" style="margin-left: 8px;" />
                    <Button icon="pi pi-download" class="p-mt-2 p-mb-2 p-ml-2 p-button-rounded p-button-secondary p-button-text no_padding no_margin" :disabled="BulkCheckFormList.length == 0" @click="clickDownloadCsvButtonClick"> </Button>
                    <DownloadCsvDialog 
                        :displayDownloadCsvOptionDialog="displayDownloadCsvOptionDialog" 
                        :BulkCheckFormList="BulkCheckFormList"
                        :isSelectedAll="isSelectedAll"
                        :formStatus="Status"
                        @updateDisplayDownloadCsvOptionDialog="displayDownloadCsvOptionDialog = $event" 
                    />
                </template>
                <template #body="slotProps">
                    <Checkbox :value="slotProps.data._id" :modelValue="BulkCheckFormList ? BulkCheckFormList.map(x => x._id) : []" @change="onBulkCheckBoxChangeOne($event, slotProps.data)" @click.stop="BulkCheckFormOneClick" />
                </template>
            </Column>

            <Column headerStyle="width: 8px" class="no_padding no_margin" style="max-width: 40px;" :showFilterMatchModes="false">
                <template #body="slotProps">
                    <template v-if="slotProps.data.HasFile">
                        <i class="pi pi-paperclip"></i>
                    </template>
                </template>
            </Column>
            <Column :style="defaultMaxPixel" filterField="DocumentName"  :showFilterMatchModes="false" headerStyle="flex: 2" style="flex: 2;">
                <template #header="slotProps">
                    {{$t('formDataTable.DocumentName')}} <span class='gridControlText'> ({{$t('formDataTable.ControlText')}}) </span>
                </template>

                <template #body="slotProps">
                    <span class="text-ellipsis" v-bind:class="{'unReadDocumentName': !slotProps.data.IsRead}" >
                        {{slotProps.data.DocumentName}}
                        <span class="gridControlText" v-if="slotProps.data.ControlText " >({{slotProps.data.ControlText }})</span>
                    </span>
                    <div style="flex: 1;" />
                    <!-- <template v-if="slotProps.data.IsNotification"> -->
                    <!--     <i class="pi pi-bell" v-tooltip="$t('formDataTable.IsBell')"  style="font-size: 0.6rem; margin-left: 2px;"  /> -->
                    <!-- </template> -->
                    <!-- <template v-if="slotProps.data.IsShared"> -->
                    <!--     <i class="pi pi-share-alt" style="font-size: 0.6rem; margin-left: 2px;" v-tooltip="$t('formDataTable.IsShare')"  /> -->
                    <!-- </template> -->
                </template>
                <template #filter="{filterModel}">
                    <InputText v-on="filterOn()"  ref="filterInputRef"  v-model="filterModel.value" placeholder="Keyword Search" :class="{'p-invalid': filterModel.invalid}" @change="filterModel.value = filterModel.value.trim()" />
                </template>
                    <template #filterapply="{filterModel, filterCallback}">
                        <Button class="p-button-outlined p-button-info"  @click="gridFilterCallback('OpenEnd', filterModel, filterCallback)" > {{$t('formDataTable.Filter')}} </Button>
                    </template>
                    <template #filterclear="{filterModel, filterCallback}">
                        <Button class="p-button-outlined p-button-info"  @click="gridFilterClearCallback('OpenBegin', filterModel, filterCallback)" > {{$t('formDataTable.Clear')}} </Button>
                    </template>
            </Column>
            <Column 
                :header="$t('formDataTable.ToDepartment')" 
                :style="defaultMaxPixel"
                filterField="DepartmentName" :showFilterMatchModes="false">
                <template #body="slotProps">
                    <span class="text-ellipsis underline"
                          v-bind:class="{'unReadDocumentName': !slotProps.data.IsRead}"
                        @click.stop="linkToDocumentList($event, slotProps.data)"
                    >
                        {{slotProps.data.DepartmentName}}
                    </span>
                </template>
                <template #filter="{filterModel}">
                    <InputText v-on="filterOn()"  ref="filterInputRef"  v-model="filterModel.value" placeholder="Keyword Search" :class="{'p-invalid': filterModel.invalid}" @change="filterModel.value = filterModel.value.trim()" />
                </template>
                    <template #filterapply="{filterModel, filterCallback}">
                        <Button class="p-button-outlined p-button-info"  @click="gridFilterCallback('OpenEnd', filterModel, filterCallback)" > {{$t('formDataTable.Filter')}} </Button>
                    </template>
                    <template #filterclear="{filterModel, filterCallback}">
                        <Button class="p-button-outlined p-button-info"  @click="gridFilterClearCallback('OpenBegin', filterModel, filterCallback)" > {{$t('formDataTable.Clear')}} </Button>
                    </template>
            </Column>
            <Column  :header="$t('formDataTable.ToCompanyName')" :style="defaultMaxPixel" filterField="CompanyName"  :showFilterMatchModes="false">
                <template #body="slotProps">
                    <span class="text-ellipsis underline"
                          v-bind:class="{'unReadDocumentName': !slotProps.data.IsRead}"
                            @click.stop="linkToDocumentList($event, slotProps.data, true)"
                    >
                        {{slotProps.data.CompanyName}}
                    </span>
                </template>
                <template #filter="{filterModel}">
                    <InputText v-on="filterOn()"  ref="filterInputRef"  v-model="filterModel.value" placeholder="Keyword Search" :class="{'p-invalid': filterModel.invalid}" @change="filterModel.value = filterModel.value.trim()" />
                </template>
                    <template #filterapply="{filterModel, filterCallback}">
                        <Button class="p-button-outlined p-button-info"  @click="gridFilterCallback('OpenEnd', filterModel, filterCallback)" > {{$t('formDataTable.Filter')}} </Button>
                    </template>
                    <template #filterclear="{filterModel, filterCallback}">
                        <Button class="p-button-outlined p-button-info"  @click="gridFilterClearCallback('OpenBegin', filterModel, filterCallback)" > {{$t('formDataTable.Clear')}} </Button>
                    </template>
            </Column>
            <Column :style="defaultMaxPixel" filterField="FromEmail" :showFilterMatchModes="false">
            <template #header>
                <div class="p-d-flex">
                    <i class="pi pi-info-circle" v-tooltip="$t('formDataTable.FromEmailInfo')"  />
                    <span class="p-ml-1" >{{$t('formDataTable.FromEmail')}}</span>
                </div>
            </template>
                <template #body="slotProps">
                    <sentPaperIcon v-if="slotProps.data.SendAcceptedMail" :tooltipText="$t('formDataTable.SentEmailTooltipText')" />
                    <rejectPaperIcon v-if="slotProps.data.SendRejectedMail"  :tooltipText="$t('formDataTable.SentEmailTooltipText')" />
                    <donePaperIcon v-if="slotProps.data.SendDoneMail"  :tooltipText="$t('formDataTable.SentEmailTooltipText')" />
                    <SentNormalBulkPaperIcon v-if="slotProps.data.SendNormalBulkMail"  :tooltipText="$t('formDataTable.SentEmailTooltipText')" />
                    <span class="text-ellipsis" style="white-space:pre"
                          v-bind:class="{'unReadDocumentName': !slotProps.data.IsRead}"
                        >
                        {{slotProps.data.FromEmail ? (" " + slotProps.data.FromEmail) : $t("formDataTable.NotShowFromEmail")}}
                    </span>
                </template>
                <template #filter="{filterModel}">
                    <InputText v-on="filterOn()"  ref="filterInputRef"  v-model="filterModel.value" placeholder="Keyword Search" :class="{'p-invalid': filterModel.invalid}" @change="filterModel.value = filterModel.value.trim()" />
                </template>
                    <template #filterapply="{filterModel, filterCallback}">
                        <Button class="p-button-outlined p-button-info"  @click="gridFilterCallback('OpenEnd', filterModel, filterCallback)" > {{$t('formDataTable.Filter')}}</Button>
                    </template>
                    <template #filterclear="{filterModel, filterCallback}">
                        <Button class="p-button-outlined p-button-info"  @click="gridFilterClearCallback('OpenBegin', filterModel, filterCallback)" > {{$t('formDataTable.Clear')}} </Button>
                    </template>
            </Column>
            <Column :header="$t('formDataTable.ConfirmedUserDisplayInReceived')"  :headerStyle="avatarPixel" :style="avatarMaxPixel">
                <template #body="slotProps">
                    <AvatarGroup class="">
                            <template v-for="ConfirmedUserDisplay in slotProps.data.ConfirmedUserDisplayList">
                                <Avatar
                                    :label="ConfirmedUserDisplay.Email.slice(0, 1)"
                                    class="p-mr-2 border-none" 
                                    shape="circle" 
                                    v-tooltip="ConfirmedUserDisplay.Email + '\n' + $filters.formatDate(ConfirmedUserDisplay.StampedDate, null, 'HH:mm:ss')"
                                />
                            </template >
                            <template v-if="slotProps.data.ConfirmedUserExcessNumberText">
                                <Avatar 
                                    :label="'+' + slotProps.data.ConfirmedUserExcessNumberText" 
                                    shape="circle" 
                                    style="border: none; color: #172b4d;" 
                                    class="avatar-icon" 
                                    @click.stop="showFormMemberListDialogClick($event, slotProps.data, 'Form')"
                                    v-tooltip="$t('utils.details')"
                                />
                            </template >
                            <template v-else>
                                <Avatar
                                    icon="pi pi-users" 
                                    class="p-mr-2 avatar-icon" 
                                    shape="circle" 
                                    style="border: none; color: #172b4d;" 
                                    @click.stop="showFormMemberListDialogClick($event, slotProps.data, 'Form')"
                                    v-tooltip="$t('utils.details')"
                                />
                            </template >
                            <StampedUserListOverPanel ref="overlayPanel" :Type="StampedUserListOverPanelType"  :FormId="StampedUserListOverPanelId" :TemplateId="StampedUserListOverPanelTemplateId" />
                    </AvatarGroup>
                </template >
            </Column>
            <Column  :header="$t('formDataTable.SendDate')" dataType="date" :headerStyle="fullDatePixel" :style="fullDateMaxPixel" filterField="SendDate" sortField="SendDate" :sortable="true" :showFilterOperator=false  >
                <template #body="{data}">
                    <span class="text-ellipsis" v-bind:class="{'unReadDocumentName': !data.IsRead}" >{{$filters.formatDate(data.SendDate, null, "HH:mm:ss")}}</span>
                </template>
                <template #filter="{filterModel}">
                    <Calendar ref="filterInputRef"  v-model="filterModel.value" :dateFormat="dateFormat" :placeholder="dateFormat" :class="{'p-invalid': filterModel.invalid}" />
                </template>
                <template #filterapply="{filterModel, filterCallback}">
                    <Button class="p-button-outlined p-button-info"  @click="gridFilterCallback('OpenEnd', filterModel, filterCallback)" > {{$t('formDataTable.Filter')}} </Button>
                </template>
                <template #filterclear="{filterModel, filterCallback}">
                    <Button class="p-button-outlined p-button-info"  @click="gridFilterClearCallback('OpenBegin', filterModel, filterCallback)" > {{$t('formDataTable.Clear')}} </Button>
                </template>

            </Column>
            <Column  :header="$t('formDataTable.FormNote')"  filterField="FormNote" :showFilterMatchModes="false" sortField="FormNote" :sortable="true" headerStyle="width: 120px;" style="max-width: 120px;">
                <template #body="slotProps">
                    <span class="text-ellipsis" v-tooltip="{ value: slotProps.data.FormNote}" >{{slotProps.data.FormNote}}</span>
                </template>
                <template #filter="{filterModel}">
                    <InputText v-on="filterOn()"  ref="filterInputRef"  v-model="filterModel.value" placeholder="Keyword Search" :class="{'p-invalid': filterModel.invalid}" />
                </template>
                <template #filterapply="{filterModel, filterCallback}">
                    <Button class="p-button-outlined p-button-info"  @click="gridFilterCallback('OpenEnd', filterModel, filterCallback)" > {{$t('formDataTable.Filter')}} </Button>
                </template>
                <template #filterclear="{filterModel, filterCallback}">
                    <Button class="p-button-outlined p-button-info"  @click="gridFilterClearCallback('OpenBegin', filterModel, filterCallback)" > {{$t('formDataTable.Clear')}} </Button>
                </template>
            </Column>
            <template #additionTable>
                <template v-if="gridData && gridData.length" >
                    <Button class="p-button-text focus-box-shadow-none" @click="onNextPageButtonClick($event, 'formDataTableKey')" v-if="!isLastPage" > {{$t('utils.next')}} > </Button>
                    <template v-else >
                        {{$t('formDataTable.rowEnd')}}
                    </template>
                </template>
            </template>

            <template #paginatorend>
                <div class="page-bottom-form-info">
                    {{$t('postedFormScreen.header.sendDate')}}{{$filters.formatDate(SendDate, null, "HH:mm:ss")}} 
                    {{$t('postedFormScreen.header.templateId')}} <i v-tooltip="$t('draft.header.templateIdInfo')" class="pi pi-info-circle"></i>: {{TemplateId}}
                    {{$t('postedFormScreen.header.formId')}} <i v-tooltip="$t('draft.header.formIdInfo')" class="pi pi-info-circle"></i>: {{FormId}}
                </div>
            </template>

        </DataTable>
    </template>
    <template v-else-if="Type == 'Sent'">
        <DataView v-if="isMobile.isMobile()" 
            class="p-dataview-sm sentFormDataTable width-100"
            :value="gridData" :layout="DataViewLayout"  >
            <template #header>
                <div class="p-grid p-nogutter">
                    <div class="p-col-6" style="text-align: left">
                        {{$t('formDataTable.SenderScreen')}}
                    </div>
                    <!-- <div class="p-col-6" style="text-align: right"> -->
                    <!--     <DataViewLayoutOptions v-model="DataViewLayout" /> -->
                    <!-- </div> -->
                </div>
			</template>
            <template #empty>
                <div class="p-ml-3">
                    {{$t('utils.notFound')}} 
                </div>
            </template>

			<template #list="slotProps">
				<Card class="p-ripple cursor_pointer p-col-12 card-content-padding-0" @click="selectSentDataRow(slotProps)">
                    <template #content >
                        <div class="">
                            <div class="width-100" style="display: flex; flex-direction: row; justify-content: space-between;">
                                <div class="text-left text-ellipsis">
                                    <span style="font-size: 17px; font-weight: 300; line-height: 24px;">
                                        {{slotProps.data.CompanyName}} 
                                    </span>
                                </div>
                                <div class="text-right">
                                    <span class="" style="font-size: 12px; white-space: nowrap;">
                                    {{$filters.formatDateAtDate(slotProps.data.SendDate)}} 
                                    </span>
                                </div>
                            </div>
                            <div class="">{{slotProps.data.DocumentName}}</div>
                            <div class="">{{"(" + FormStatusInSenderText(slotProps.data.FormStatus) + ")"}} <i> {{(slotProps.data.SenderFormNote ? slotProps.data.SenderFormNote : "")}} </i></div>
                        </div>
                    </template>
				</Card>
			</template>
        </DataView>
        <DataTable
            v-else
            :scrollable="true"
            scrollHeight="flex"
            v-model:value="gridData" 
            v-model:selection="selectedData" 
            selectionMode="single" 
            dataKey="_id" 
            @row-select="selectSentDataRow"
            class="p-datatable-sm sentFormDataTable  datatable-width-100"
            v-model:filters="filters1" 
            :rowsPerPageOptions="$constants.rowsPerPageOptions"
            filterDisplay="menu"
            :globalFilterFields="[ 'DocumentName', 'DepartmentName', 'FromEmail', 'CompanyName', 'FormNote', 'SendDate', 'FormStatusInSender', 'SenderFormNote']" 
            :lazy="true" :paginator="true" :rows="10"
            :totalRecords="totalRecords" :loading="loading" @page="onPage($event)" @sort="onSort($event)" @filter="onFilter($event)"
            removableSort
            :sortField="dynamicSortField"
            :sortOrder="dynamicSortOrder"
            >
                <template #empty>
                    {{$t('formDataTable.ForgetFilter')}}
                </template>
                <template #loading>
                    Please wait.
                </template>
            <Column headerStyle="width: 8px"  style="max-width: 40px;" :showFilterMatchModes="false">
                <template #body="slotProps">
                    <template v-if="slotProps.data.HasFile">
                        <i class="pi pi-paperclip"></i>
                    </template>
                </template>
            </Column>
            <Column  :header="$t('formDataTable.SentDocumentName')" :style="defaultMaxPixel" filterField="DocumentName"  :showFilterMatchModes="false">
                <template #body="slotProps">
                    <span class="text-ellipsis">
                        {{slotProps.data.DocumentName}}
                    </span>
                </template>
                <template #filter="{filterModel}">
                    <InputText v-on="filterOn()"  ref="filterInputRef"  v-model="filterModel.value" placeholder="Keyword Search" :class="{'p-invalid': filterModel.invalid}" />
                </template>
                    <template #filterapply="{filterModel, filterCallback}">
                        <Button class="p-button-outlined p-button-info"  @click="gridFilterCallback('DocumentName', filterModel, filterCallback)" > {{$t('formDataTable.Filter')}} </Button>
                    </template>
                    <template #filterclear="{filterModel, filterCallback}">
                        <Button class="p-button-outlined p-button-info"  @click="gridFilterClearCallback('DocumentName', filterModel, filterCallback)" > {{$t('formDataTable.Clear')}} </Button>
                    </template>
            </Column>
            <Column 
                :header="$t('formDataTable.ToDepartment')" 
                 :style="defaultMaxPixel"
                filterField="DepartmentName" :showFilterMatchModes="false">
                <template #body="slotProps">
                    <span class="text-ellipsis"
                    >
                        {{slotProps.data.DepartmentName}}
                    </span>
                </template>
                <template #filter="{filterModel}">
                    <InputText v-on="filterOn()"  ref="filterInputRef"  v-model="filterModel.value" placeholder="Keyword Search" :class="{'p-invalid': filterModel.invalid}" />
                </template>
                    <template #filterapply="{filterModel, filterCallback}">
                        <Button class="p-button-outlined p-button-info"  @click="gridFilterCallback('DepartmentName', filterModel, filterCallback)" > {{$t('formDataTable.Filter')}} </Button>
                    </template>
                    <template #filterclear="{filterModel, filterCallback}">
                        <Button class="p-button-outlined p-button-info"  @click="gridFilterClearCallback('DepartmentName', filterModel, filterCallback)" > {{$t('formDataTable.Clear')}} </Button>
                    </template>
            </Column>
            <Column  :header="$t('formDataTable.ToCompanyName')" :style="defaultMaxPixel" filterField="CompanyName"  :showFilterMatchModes="false">
                <template #body="slotProps">
                    <span class="text-ellipsis"
                    >
                        {{slotProps.data.CompanyName}}
                    </span>
                </template>
                <template #filter="{filterModel}">
                    <InputText v-on="filterOn()"  ref="filterInputRef"  v-model="filterModel.value" placeholder="Keyword Search" :class="{'p-invalid': filterModel.invalid}" />
                </template>
                    <template #filterapply="{filterModel, filterCallback}">
                        <Button class="p-button-outlined p-button-info"  @click="gridFilterCallback('CompanyName', filterModel, filterCallback)" > {{$t('formDataTable.Filter')}} </Button>
                    </template>
                    <template #filterclear="{filterModel, filterCallback}">
                        <Button class="p-button-outlined p-button-info"  @click="gridFilterClearCallback('CompanyName', filterModel, filterCallback)" > {{$t('formDataTable.Clear')}} </Button>
                    </template>
            </Column>
            <Column :header="$t('formDataTable.FormStatusInSender')"  filterField="FormStatus" :showFilterMatchModes="false"  headerStyle="width: 120px;" style="max-width: 120px;">
                <template #body="slotProps">
                    <span class="text-ellipsis" v-tooltip="FormStatusInSenderText(slotProps.data.FormStatus)">{{FormStatusInSenderText(slotProps.data.FormStatus)}}</span>
                </template>
                <template #filter="{filterModel}">
                    <!-- <InputText v-on="filterOn()"  ref="filterInputRef"  v-model="filterModel.value" placeholder="Keyword Search" /> -->
                    <Dropdown v-on="filterOn()"  ref="filterInputRef" v-model="filterModel.value" :options="FormStatusInSenderTextList" optionLabel="label" optionValue="value" placeholder="Keyword Search" :class="{'p-invalid': filterModel.invalid}" />
                </template>
                <template #filterapply="{filterModel, filterCallback}">
                    <Button class="p-button-outlined p-button-info"  @click="gridFilterCallback('FormStatus', filterModel, filterCallback)" > {{$t('formDataTable.Filter')}} </Button>
                </template>
                <template #filterclear="{filterModel, filterCallback}">
                    <Button class="p-button-outlined p-button-info"  @click="gridFilterClearCallback('FormStatus', filterModel, filterCallback)" > {{$t('formDataTable.Clear')}} </Button>
                </template>
            </Column>
            <Column  :header="$t('formDataTable.SendDate')" dataType="date" :headerStyle="fullDatePixel" :style="fullDateMaxPixel" filterField="SendDate" :showFilterOperator=false sortField="SendDate" :sortable="true" >
                <template #body="{data}">
                    <!-- <span class="text-ellipsis" v-tooltip="$filters.formatDate(data.SendDate, null, 'HH:mm:ss')" v-bind:class="{'unReadDocumentName': !data.IsRead}" >{{$filters.formatDate(data.SendDate, null, "HH:mm:ss")}}</span> -->
                    <span class="text-ellipsis" v-tooltip="$filters.formatDate(data.SendDate, null, 'HH:mm:ss')">{{$filters.formatDate(data.SendDate, null, "HH:mm:ss")}}</span>
                </template>
                <template #filter="{filterModel}">
                    <Calendar ref="filterInputRef"  v-model="filterModel.value" :dateFormat="dateFormat" :placeholder="dateFormat" :class="{'p-invalid': filterModel.invalid}" />
                </template>
                <template #filterapply="{filterModel, filterCallback}">
                    <Button class="p-button-outlined p-button-info"  @click="gridFilterCallback('SendDate', filterModel, filterCallback)" > {{$t('formDataTable.Filter')}} </Button>
                </template>
                <template #filterclear="{filterModel, filterCallback}">
                    <Button class="p-button-outlined p-button-info"  @click="gridFilterClearCallback('SendDate', filterModel, filterCallback)" > {{$t('formDataTable.Clear')}} </Button>
                </template>
            </Column>
            <Column :header="$t('formDataTable.SenderFormNote')"  filterField="SenderFormNote" :showFilterMatchModes="false"  headerStyle="width: 120px;" style="max-width: 120px;" sortField="SenderFormNote" :sortable="true">
                <template #body="slotProps">
                    <span class="text-ellipsis" v-tooltip="{ value: slotProps.data.SenderFormNote}">{{slotProps.data.SenderFormNote}}</span>
                </template>
                <template #filter="{filterModel}">
                    <InputText v-on="filterOn()"  ref="filterInputRef"  v-model="filterModel.value" placeholder="Keyword Search" :class="{'p-invalid': filterModel.invalid}" />
                </template>
                <template #filterapply="{filterModel, filterCallback}">
                    <Button class="p-button-outlined p-button-info"  @click="gridFilterCallback('SenderFormNote', filterModel, filterCallback)" > {{$t('formDataTable.Filter')}} </Button>
                </template>
                <template #filterclear="{filterModel, filterCallback}">
                    <Button class="p-button-outlined p-button-info"  @click="gridFilterClearCallback('SenderFormNote', filterModel, filterCallback)" > {{$t('formDataTable.Clear')}} </Button>
                </template>
            </Column>
        </DataTable>
    </template>
    <div v-if="Loading" class="progressSpinnerContainer">
        <ProgressSpinner />
    </div>
    <mailDialog :displayDialog="showMailDialog" @closeDialog="closeMailDialog($event)" :beforeSendView=true :OPE="OPE" />
    <SelectMovedDetailFormStatusDialog 
                        @updateSelectMovedDetailFormStatusDialog="updateSelectMovedDetailFormStatusDialog($event)" 
                        :displaySelectMovedDetailFormStatusDialog="displaySelectMovedDetailFormStatusDialog"  
                        :targetRouteCode="NextRouteDetailFormStatusCode"
                        :nextStatusAction="NextStatusAction"
                        :isBulk="true"
                        />
</template>

<script>
import DataTable from 'primevue/datatable';
import DataView from 'primevue/dataview';
// import DataViewLayoutOptions from 'primevue/dataviewlayoutoptions';
import Card from 'primevue/card';

import Column from 'primevue/column';
import Avatar from 'primevue/avatar';
import AvatarGroup from 'primevue/avatargroup';
import Checkbox from 'primevue/checkbox';
import Button from 'primevue/button';
import Calendar from 'primevue/calendar'
import ProgressSpinner from 'primevue/progressspinner';
import Tooltip from 'primevue/tooltip'
import Ripple from 'primevue/ripple';
import StampedUserListOverPanel from '@/components/StampedUserListOverPanel'




import InputText from 'primevue/inputtext';
import {FilterMatchMode, FilterOperator} from 'primevue/api';
import http from '@/mixins/api'

import isMobile from '@/mixins/device'

import SentPaperIcon from "@/components/Icons/sentPaperIcon";
import RejectPaperIcon from "@/components/Icons/rejectPaperIcon";
import DonePaperIcon from "@/components/Icons/donePaperIcon";
import DownloadCsvDialog from '@/components/DownloadCsvDialog.vue';
import SentNormalBulkPaperIcon from "@/components/Icons/sentNormalBulkPaperIcon";


import FormDataTableDraft from '@/components/FormDataTableRow/Draft.vue'
import FormDataTableCreated from '@/components/FormDataTableRow/Created.vue'
import Menu from 'primevue/menu'
import Dropdown from 'primevue/dropdown'
import mailDialog from '@/components/mailDialog'
import SelectMovedDetailFormStatusDialog from "@/components/SelectMovedDetailFormStatusDialog";


export default {
    name: 'FormDataTable',
    props: [ "Type", "Status", "FormStatus", "Loading"],
    emits: ["editableForm", "selectRow", "reloadTable", "moveTopSplitterPanel", "endTopSplitterPanel", "touchStartTopSplitterPanel", "startTopSplitterPanel", "pagingMouseOver"],
    components: {
        DataTable,
        DataView,
        // DataViewLayoutOptions,
        Column,
        Avatar,
        AvatarGroup,
        Checkbox,
        Button,
        Calendar,
        StampedUserListOverPanel,
        ProgressSpinner, 
        InputText,
        Card,
        SentPaperIcon,
        RejectPaperIcon,
        DonePaperIcon,
        SentNormalBulkPaperIcon,
        DownloadCsvDialog,
        FormDataTableDraft,
        FormDataTableCreated,
        Menu,
        Dropdown,
        mailDialog,
        SelectMovedDetailFormStatusDialog,
    },
    data: function() {
        return {
            DataViewLayout: "list",
            displayDownloadCsvOptionDialog: false,
            isMobile,
            // gridData: [],
            delay: 200,
            clicks: 0,
            timer: null,
            editForm: false,
            displayConfirmPopupDialog: false,
            filters1: null,
            showFormMemberListDialog: false,
            StampedUserListOverPanelType: "",
            StampedUserListOverPanelId: "",
            StampedUserListOverPanelTemplateId: "",
            defaultMaxPixel: "max-width: 400px",
            datePixel: "width: 110px",
            dateMaxPixel: "max-width: 110px",
            fullDatePixel: "width: 130px",
            fullDateMaxPixel: "max-width: 130px",
            avatarPixel: "width: 122px;",
            avatarMaxPixel: "max-width: 122px;",
            ellipsisMenu: [],
            isSelectedAll: false,
            loading: false,
            showMailDialog: false,
            OPE: "NormalBulk",
            displaySelectMovedDetailFormStatusDialog: false,
            NextRouteDetailFormStatusCode: "",
            NextStatusAction: "",
            dynamicSortField: "",
            dynamicSortOrder: 0,
            // multiSortMeta: [
            //     {field: 'SendDate', order: 1},
            //     {field: 'FormNote', order: 1}
            // ],
            // multiSortMeta: [],
            // selectedData: null,
            
            isLastPage: false,

        }
    },
    watch: {
        gridData: function(value, oldValue)  {
            const self = this;
            this.$nextTick(function() {
                if(self.$refs && self.$refs['formDataTableKey'] && self.$refs['formDataTableKey'].$refs)
                    self.isLastPage = self.$refs['formDataTableKey'].$refs.dtPaginator.isLastPage
                else
                    self.isLastPage = false
            })
        },
    },
    created() {
        this.initFilters()
        this.initSorts()
    },
    computed: {
        dateFormat: {
            get() {
                const userInfo = this.$store.getters["auth/getUserInfo"];
                return userInfo && userInfo.dateformat ? userInfo.dateformat.toLowerCase().replace("yyyy", "yy") : "yy/mm/dd";
            }
        },
        gridData: {
            get: function() {
                if(this.Type == "Form") {
                    this.$store.commit("viewPostedForm/setStatus", this.Status)
                    if(this.Status == this.$constants.FormStatus.Received) {
                        return this.$store.getters["gridDataStore/getReceivedGridDataList"]
                    }
                    else if(this.Status == this.$constants.FormStatus.Accept) {
                        return this.$store.getters["gridDataStore/getAcceptedGridDataList"]
                    }
                    else if(this.Status == this.$constants.FormStatus.Reject) {
                        return this.$store.getters["gridDataStore/getRejectedGridDataList"]
                    }
                    else if(this.Status == this.$constants.FormStatus.Storage) {
                        return this.$store.getters["gridDataStore/getStorageGridDataList"]
                    }
                    else if(this.Status == this.$constants.FormStatus.Deleted) {
                        return this.$store.getters["gridDataStore/getDeletedGridDataList"]
                    }
                    else if(this.Status == this.$constants.FormStatus.Dropped) {
                        return this.$store.getters["gridDataStore/getDroppedGridDataList"]
                    }
                }
                else if(this.Type == "Template") {
                    if(this.Status == this.$constants.Status.Draft) {
                        return this.$store.getters["gridDataStore/getDraftGridDataList"]
                    }
                    else if(this.Status == this.$constants.Status.Public) {
                        return this.$store.getters["gridDataStore/getPublicGridDataList"]
                    }
                    else if(this.Status == this.$constants.Status.Garbage) {
                        return this.$store.getters["gridDataStore/getGarbageGridDataList"]
                    }
                }
                else if(this.Type == "Sent" && this.Status == this.$constants.SentStatus.Sent) {
                    return this.$store.getters["gridDataStore/getSentGridDataList"]
                }
                return []
            },
        },

        formDataTableKey: {
            get: function() {
                const keyCount = this.$store.getters["auth/getKeyCount"]
                return "formDataTableKey" + "_" + keyCount
            }
        },

        totalRecords: {
            get: function() {
                const gridTotalRecords = this.$store.getters["gridDataStore/getGridDataTotalRecord"]
                if(gridTotalRecords[this.Type]) {
                    return gridTotalRecords[this.Type][this.Status] || 0
                }
                return 0
            },
        },


        rows: {
            get: function() {
                const gridPageData = this.$store.getters["gridDataStore/getGridPageData"]
                if(gridPageData[this.Type] && gridPageData[this.Type][this.Status]) {
                    return gridPageData[this.Type][this.Status].rows || this.$constants.DefaultFormListRows
                }
                return this.$constants.DefaultFormListRows
            },
        },

        FormStatusInSenderTextList: {
            get: function() {
                const self = this;
                const data = []
                const ButtonTextStatusList = [
                    {
                        text: "Sent",
                        value: [1]
                    },
                    // {
                    //     text: "Accepted",
                    //     value: [2, 4]
                    // },
                    // {
                    //     text: "Rejected",
                    //     value: [3, 5]
                    // },
                    {
                        text: "Accepted",
                        value: [2, 3, 4, 5]
                    },
                    {
                        text: "AlreadyWithdrawal",
                        value: [6]
                    },
                ]
                for(let i = 0; i < ButtonTextStatusList.length; i++) {
                    const FormStatus = i
                    const ButtonTextStatus = ButtonTextStatusList[i].text
                    const label = this.$i18n.tc("postedFormScreen.ButtonStatusText." + ButtonTextStatus)
                    data.push({
                        label: label,
                        value: ButtonTextStatusList[i].value
                    })
                }
                return data
            }
        },

        FormStatusInSenderText: {
            get: function() {
                const self = this;
                return function(FormStatus) {
                    let ButtonTextStatus = ""
                    if(FormStatus == 1) {
                        ButtonTextStatus = "Sent"
                    } else if(FormStatus == 2 || FormStatus == 4 ) {
                        ButtonTextStatus = "Accepted"
                    } else if(FormStatus == 3 || FormStatus == 5 ) {
                        ButtonTextStatus = "Rejected"
                    } else if(FormStatus == 6) {
                        ButtonTextStatus = "AlreadyWithdrawal"
                    }
                    return self.$i18n.tc("postedFormScreen.ButtonStatusText." + ButtonTextStatus) 
                }
            }
        },
        
        selectedData: {
            get: function() {
                const data = this.$store.getters["gridDataStore/getSelectedData"]
                return data
            },
        },

        isGridFiltering: {
            get: function() {
                const filterData = this.$store.getters["gridDataStore/getIsFiltering"] || {}
                if(filterData[this.Type] && filterData[this.Type][this.Status]) {
                    return true
                }
                return false
            }
        },

        BulkCheckFormList: {
            get: function() {
                return this.$store.getters["gridDataStore/getSelectedGridDataList"] || []
            },
            set: function(data) {
                this.$store.commit("gridDataStore/setSelectedGridDataList", data)
            },
        },
        TemplateId: {
            get: function() {
                return this.$store.getters["viewPostedForm/getTemplateId"]
            }
        },
        FormId: {
            get: function() {
                return this.$store.getters["viewPostedForm/getFormId"]
            }
        },
        SendDate: {
            get: function() {
                return this.$store.getters["viewPostedForm/getSendDate"]
            }
        },
    },

    methods: {
        filterOn: function() {
            const self = this
            this.$nextTick(function() {
                if(self.$refs.filterInputRef.show) {
                    self.$refs.filterInputRef.show()
                } else if(self.$refs.filterInputRef && self.$refs.filterInputRef.$el ) {
                    if(self.$refs.filterInputRef.$el.children && self.$refs.filterInputRef.$el.children.length)
                        self.$refs.filterInputRef.$el.children[0].focus()
                    else
                        self.$refs.filterInputRef.$el.focus()
                }
            })
        },

        initSorts() {
            this.dynamicSortField = ""
            this.dynamicSortOrder = 0
            const sortData = this.$store.getters["gridDataStore/getGridSortData"] || {}
            if(sortData[this.Type] && sortData[this.Type][this.Status]) {
                this.dynamicSortField = Object.keys(sortData[this.Type][this.Status])[0];
                this.dynamicSortOrder = sortData[this.Type][this.Status][this.dynamicSortField];
                return;
            }
        },

        clearFilter1() {
            this.initFilters(true);
            this.globalFilterEnter();
        },

        initFilters(forceReset=false) {
            if(!forceReset) {
                const filterData = this.$store.getters["gridDataStore/getGridFilterData"] || {}
                if(filterData[this.Type] && filterData[this.Type][this.Status]) {
                    this.filters1 = filterData[this.Type][this.Status];
                    return;
                }
            }
            const DateFilterList = [
                {value: null, matchMode: FilterMatchMode.DATE_IS},
            ]
            // const DateFilter = {operator: FilterOperator.OR, constraints: DateFilterList};
            const DateFilter = {operator: FilterOperator.AND, constraints: DateFilterList};
            this.filters1 = {
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'SortNumberByTemplate': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'DocumentName': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'DepartmentName': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'FromEmail': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'CompanyName': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'CreatorName': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'TemplateNote': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'FormNote': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'OpenBegin': DateFilter,
                'SendDate': DateFilter,
                'date': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.DATE_IS}]},
                'OpenEnd': DateFilter,
                'FormStatus': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'SenderFormNote': {value: null, matchMode: FilterMatchMode.CONTAINS},
                '_id': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'TemplateId': {value: null, matchMode: FilterMatchMode.CONTAINS},
            }
        },


        selectedRow(event) {
            this.clicks++
            if(this.clicks === 1) {
                var self = this
                this.editForm = false
                this.timer = setTimeout(function() {
                    self.clicks = 0
                    self.editForm = true
                    self.$store.commit('setSelectedRowId', {id: event.data._id, type: self.Type})
                    const edited = self.$store.getters['editTemplate/getEdited']
                    if(edited) {
                        const beforeTemplateId = self.$store.getters['editTemplate/getTemplateId']
                        if(beforeTemplateId != event.data._id) {
                            self.displayConfirmPopupDialog = true
                        }
                    }
                    self.emitEditableForm(event.data._id)
                    self.$emit("selectRow", true)
                }, this.delay);
            } else{
                clearTimeout(this.timer);
                this.editForm = false
                this.clicks = 0;
            }
        },

        selectSentDataRow(event) {
            this.emitSentForm(event.data._id)
        },

        emitSentForm: async function(_id) {
            const res = await http.post('/form/GetPostedFormScreenLink', {FormId: _id});
            if(res.Success) {
                if(isMobile.isMobile()) {
                    location.href = res.URL
                } else {
                    window.open(res.URL, '_blank')
                }
            }
        },

        emitEditableForm: async function(_id) {
            let loaded = false
            let Message = ""

            if(this.Type == "Template") {
                if(this.$route.meta.loadTemplateFormView)
                    this.$router.push({ name: this.$route.name, params: {templateFormId: _id}})
                else
                    this.$router.push({ name: this.$route.name + "Info", params: {templateFormId: _id}})

                
                loaded = true
            } else if(this.Type == "Form") {
                if(this.$route.meta.loadFormView)
                    this.$router.push({ name: this.$route.name, params:{formId:  _id}})
                else
                    this.$router.push({ name: this.$route.name + "FormId", params:{formId:  _id}})
                loaded = true
            }
            if(!loaded) {
                this.$store.commit('gridDataStore/setEditableForm', false)
                this.$toast.add({severity:'error', summary: Message, life: this.$constants.successHelperLife, group: this.$constants.defaultToastGroup});
            }

            // this.$emit('editableForm', this.editForm)
        },


        async showFormMemberListDialogClick(e, record, Type) {
            if(Type == "Template") {
                await(this.StampedUserListOverPanelType = Type)
                await(this.StampedUserListOverPanelId = record._id)
                await(this.StampedUserListOverPanelTemplateId = record._id)
            } else if(Type == "Form") {
                await(this.StampedUserListOverPanelType = Type)
                await(this.StampedUserListOverPanelId = record._id)
                await(this.StampedUserListOverPanelTemplateId = record.TemplateId)
            }
            if(this.$refs.overlayPanel)
                this.$refs.overlayPanel.toggle(e, record.ConfirmedUserDisplayList, true)
        },

        async linkToDocumentList(e, record, isCompany=false) {
            const self = this;
            if(!isCompany) {
                this.$confirm.require({
                    message: self.$i18n.tc("FormDataTable.LinkDepartmentToAllInfo"),
                    accept: function() {
                        const DepartmentList = self.$store.getters["userBranchInfo/getDepartmentList"]
                        const DepartmentId = record.DepartmentId
                        const DepartmentName = record.DepartmentName
                        const NowDepartment = DepartmentList.find(x => x._id == DepartmentId)
                        if(NowDepartment) {
                            self.$router.push({ name: 'changeRegistrationInfo', params: {departmentId: self.$route.params.departmentId}, query: { activeIndex: 0, activeDepartmentId: NowDepartment._id, activeCompanyId: NowDepartment.CompanyId } });
                        } else {
                            self.$toast.add({severity:'error', summary: self.$i18n.tc("FormDataTable.dontExistDepartment"), life: self.$constants.successHelperLife, group: self.$constants.defaultToastGroup});
                        }
                    },
                    reject: () => {
                        self.selectedRow({data: record})
                    }
                })
            } else {
                this.$confirm.require({
                    message: self.$i18n.tc("FormDataTable.LinkCompanyToAllInfo"),
                    accept: function() {
                        const CompanyList = self.$store.getters["userBranchInfo/getCompanyList"]
                        const CompanyId = record.CompanyId
                        const CompanyName = record.CompanyName
                        const NowCompany = CompanyList.find(x => x._id == CompanyId)
                        if(NowCompany) {
                            self.$router.push({ name: 'changeRegistrationInfo', params: {departmentId: self.$route.params.departmentId}, query: { activeIndex: 0, activeDepartmentId: "", activeCompanyId: NowCompany._id} });
                        } else {
                            self.$toast.add({severity:'error', summary: self.$i18n.tc("FormDataTable.dontExistCompany"), life: self.$constants.successHelperLife, group: self.$constants.defaultToastGroup});
                        }
                    },
                    reject: () => {
                        self.selectedRow({data: record})
                    }
                })
            }
        },

        async clickDownloadCsvButtonClick() {
            // const DepartmentIdList = this.selectedGridDataList.map(x => x.DepartmentId)
            // const res_check = await this.$store.dispatch("planedStore/checkValidFunction", {validFunc: this.$constants.ValidFunctionList.CSVDownload, DepartmentIdList: DepartmentIdList})
            this.displayDownloadCsvOptionDialogOn()
        },
        
        async displayDownloadCsvOptionDialogOn() {
            this.displayDownloadCsvOptionDialog = true
        },

        onBulkCheckBoxChangeOne(event, data) {
            const value = event.value
            const BulkCheckFormList = this.BulkCheckFormList
            const index = BulkCheckFormList.findIndex(x => x._id == data._id)
            if(index != -1) {
                BulkCheckFormList.splice(index, 1)
            } else {
                BulkCheckFormList.push(data)
            }
            this.$store.commit("gridDataStore/setSelectedGridDataList", BulkCheckFormList)
            this.isSelectedAll = false
        },

        onBulkCheckBoxInput(value) {
            if(value) {
                const BulkCheckFormList = [...this.BulkCheckFormList, ...this.gridData].reduce((acc, cur) => [...acc, ...(acc.some((item) => item._id === cur._id) ? [] : [cur])],[])
                this.$store.commit("gridDataStore/setSelectedGridDataList", BulkCheckFormList)
            } else {
                this.$store.commit("gridDataStore/setSelectedGridDataList", [])
            }
            this.isSelectedAll = false

        },

        BulkCheckFormOneClick(e) {
        },

        onReloadFormDataColumnButtonClick() {
            this.$emit("reloadTable", true)
        },

        async onBulkAccept() {
            this.NextStatusAction = "Accept"
            this.NextRouteDetailFormStatusCode = this.$constants.routeIndexStatus.AcceptBox
            this.moveDetailStatusForm()
        },

        async bulkAccept(DetailFormStatusId) {
            const res = await this.$store.dispatch("gridDataStore/bulkAcceptForm", {isSelectedAll: this.isSelectedAll, detailFormStatusId: DetailFormStatusId})
            if(res.Success) {
                this.$toast.add({severity: 'success', summary: res.Message, life: this.$constants.successHelperLife, group: this.$constants.defaultToastGroup});
                this.onBulkCheckBoxInput(false)
                this.onPage({ page: 0})
            }
        },

        async onBulkReject() {
            this.NextStatusAction = "Reject"
            this.NextRouteDetailFormStatusCode = this.$constants.routeIndexStatus.RejectedBox
            this.moveDetailStatusForm()
        },

        async bulkReject(DetailFormStatusId) {
            const res = await this.$store.dispatch("gridDataStore/bulkRejectForm", {isSelectedAll: this.isSelectedAll, detailFormStatusId: DetailFormStatusId})
            if(res.Success) {
                this.$toast.add({severity: 'success', summary: res.Message, life: this.$constants.successHelperLife, group: this.$constants.defaultToastGroup});
                this.onBulkCheckBoxInput(false)
                this.onPage({ page: 0})
            }
        },

        async onBulkStorage() {
            if (await this.checkConfirmRequire(this.$i18n.tc("postedFormScreen.BeforeDoneForm"))) {
                this.NextStatusAction = "Storage"
                this.NextRouteDetailFormStatusCode = this.$constants.routeIndexStatus.StorageBox
                this.moveDetailStatusForm()
            }
        },

        async bulkStorage(DetailFormStatusId) {
            const res = await this.$store.dispatch("gridDataStore/bulkStorageForm", {isSelectedAll: this.isSelectedAll, detailFormStatusId: DetailFormStatusId})
            if(res.Success) {
                this.$toast.add({severity: 'success', summary: res.Message, life: this.$constants.successHelperLife, group: this.$constants.defaultToastGroup});
                this.onBulkCheckBoxInput(false)
                this.onPage({page: 0})
            }
        },

        async onBulkDelete() {
            if (await this.checkConfirmRequire(this.$i18n.tc("postedFormScreen.BeforeDeleteForm"))) {
                this.NextStatusAction = "Deleted"
                this.NextRouteDetailFormStatusCode = this.$constants.routeIndexStatus.Deleted
                this.moveDetailStatusForm()
            }
        },

        async bulkDelete(DetailFormStatusId) {
            const res = await this.$store.dispatch("gridDataStore/bulkDeleteForm", {isSelectedAll: this.isSelectedAll, detailFormStatusId: DetailFormStatusId})
            if(res.Success) {
                this.$toast.add({severity: 'success', summary: res.Message, life: this.$constants.successHelperLife, group: this.$constants.defaultToastGroup});
                this.onBulkCheckBoxInput(false)
                this.onPage({ page: 0})
            }
        },

        onEllipsisFormDataButtonClick(e) {
            const self = this;
            const Status = self.$store.getters["viewPostedForm/getFormStatus"]
            const opeMenu = [
                {
                    label: Status == self.$constants.FormStatus.Received ? this.$i18n.tc("operationButtonBar.accept") : this.$i18n.tc("operationButtonBar.toAccept"),
                    command: () => {
                        this.onBulkAccept()
                    },
                    visible: function() {
                        return Status == self.$constants.FormStatus.Reject || Status == self.$constants.FormStatus.Received
                    }
                },
                {
                    label: Status == self.$constants.FormStatus.Received ? this.$i18n.tc("operationButtonBar.reject") : this.$i18n.tc("operationButtonBar.toReject"),
                    command: () => {
                        this.onBulkReject()
                    },
                    visible: function() {
                        return Status == self.$constants.FormStatus.Accept || Status == self.$constants.FormStatus.Received
                    },
                },
                {
                    label: this.$i18n.tc("operationButtonBar.toDone"),
                    command: () => {
                        this.onBulkStorage()
                    },
                    visible: function() {
                        return Status == self.$constants.FormStatus.Accept
                    }
                },
                {
                    label: this.$i18n.tc("operationButtonBar.toDeleted"),
                    command: () => {
                        this.onBulkDelete()
                    },
                    visible: function() {
                        return Status == self.$constants.FormStatus.Reject
                    }
                },
                {
                    label: this.$i18n.tc("operationButtonBar.sentNormalBulk"),
                    command: () => {
                        this.onSentNormalMailBulk()
                    },
                    visible: function() {
                        return true
                    }
                },
                {
                    label: this.$i18n.tc("operationButtonBar.moveDetailStatusForm"),
                    command: () => {
                        this.onMoveDetailStatusForm()
                    },
                    visible: function() {
                        return self.$route.params.departmentId != "@"
                    }
                },
            ]

            this.ellipsisMenu = []
            if(!this.isSelectedAll) {
                this.ellipsisMenu.push({
                    label: this.$i18n.tc("formDataTable.selectAll"),
                    command: () => {
                        this.selectAllFormList()
                    },
                    info: this.$i18n.tc("formDataTable.selectAllInfo"),
                }, ...opeMenu)
            } else {
                this.ellipsisMenu.push({
                    label: this.$i18n.tc("formDataTable.unSelectAll"),
                    command: () => {
                        this.unSelectAllFormList()
                    }
                }, ...opeMenu)
            }
            this.$refs.ellipsisMenu.toggle(e)
        },

        selectAllFormList() {
            this.onBulkCheckBoxInput(true)
            this.isSelectedAll = true
        },

        unSelectAllFormList() {
            this.onBulkCheckBoxInput(false)
            this.isSelectedAll = false
        },

        onNextPageButtonClick(event, ref) {
            this.$refs[ref].$refs.dtPaginator.changePageToNext(event)
        },

        onPrevPageButtonClick(event, ref) {
            this.$refs[ref].$refs.dtPaginator.changePageToPrev(event)
        },

        onPage(e, load=true) {
            this.$store.commit("gridDataStore/setGridPageData", {Type: this.Type, Status: this.Status, gridPageData: e.page, gridRowsInPageData: e.rows})
            if(this.isSelectedAll){ 
                this.unSelectAllFormList()
            }
            if(load)
                this.$emit("reloadTable", true)
        },

        onSort(e, ref) {
            this.$store.commit("gridDataStore/clearGridSortData", {Type: this.Type, Status: this.Status})
            // if(ref) {
            //     this.$store.commit("gridDataStore/clearGridSortData", {Type: this.Type, Status: this.Status})
            //     for (let i = 0; i < e.multiSortMeta.length; i++) {
            //         const meta = e.multiSortMeta[i]
            //         this.$store.commit("gridDataStore/setGridSortData", {Type: this.Type, Status: this.Status, gridSortField: meta.field, gridSortOrder: meta.order})
            //     }
            // } else {
            //     this.$store.commit("gridDataStore/setGridSortData", {Type: this.Type, Status: this.Status, gridSortField: e.sortField, gridSortOrder: e.sortOrder})
            // }
            if(e.sortOrder)
                this.$store.commit("gridDataStore/setGridSortData", {Type: this.Type, Status: this.Status, gridSortField: e.sortField, gridSortOrder: e.sortOrder})
            if(this.isSelectedAll){ 
                this.unSelectAllFormList()
            }
            this.$emit("reloadTable", true)
        },

        onFilter(e) {
            this.$store.commit("gridDataStore/setGridFilterData", {Type: this.Type, Status: this.Status, gridFilterData: e.filters})

            if(this.isSelectedAll){ 
                this.unSelectAllFormList()
            }

            this.onPage({page: 0})
        },

        globalFilterEnter() {
            this.$store.commit("editTemplate/clear")
            this.$store.commit("viewPostedForm/clear")
            this.$store.commit('gridDataStore/setEditableForm', false)

            this.$store.commit("gridDataStore/setGridFilterData", {Type: this.Type, Status: this.Status, gridFilterData: this.filters1})

            if(this.isSelectedAll){ 
                this.unSelectAllFormList()
            }

            this.onPage({page: 0})
        },

        onFocusGlobalFilter(event) {
            event.target.select()
        },

        gridFilterCallback(type, filterModel, filterCallback) {
            if(filterModel.constraints) {
                let clear = true
                for(let i = 0; i < filterModel.constraints.length; i++) {
                    if(!filterModel.constraints[i].value) {
                        filterModel.constraints[i].invalid = true
                        clear = false
                    } else {
                        filterModel.constraints[i].invalid = false
                    }
                }
                if(clear) {
                    filterCallback()
                }
            // } else {
            //     filterModel.invalid = false
            //     filterModel.target = true
            //     filterCallback()
            // }
            } else if(filterModel.value) {
                filterModel.invalid = false
                filterModel.target = true
                filterCallback()
            } else {
                filterModel.invalid = true
            }
        },

        gridFilterClearCallback(type, filterModel, filterCallback) { 
            if(filterModel.constraints) {
                for(let i = 0; i < filterModel.constraints.length; i++) {
                    filterModel.constraints[i].invalid = false
                }
            } else {
                filterModel.invalid = false
            }
            filterModel.target = false
            filterCallback()
        },

        pagingMouseOver(e) {
            if (e && e.target && /.*page-bottom-form-info.*/.test(e.target.className)) {
                document.getElementsByClassName("mainFormViewSplitterPanel").item(0).getElementsByClassName("p-paginator").item(0).classList.remove("cursor_ns-resize")
                document.getElementsByClassName("mainFormViewSplitterPanel").item(0).getElementsByClassName("p-paginator").item(0).classList.add("cursor_ns-text")
            } else {
                document.getElementsByClassName("mainFormViewSplitterPanel").item(0).getElementsByClassName("p-paginator").item(0).classList.remove("cursor_ns-text")
                document.getElementsByClassName("mainFormViewSplitterPanel").item(0).getElementsByClassName("p-paginator").item(0).classList.add("cursor_ns-resize")
            }
        },

        startTopSplitterPanel(e, t) {
            const targets = document.getElementsByClassName("mainFormViewSplitterPanel")[0].getElementsByClassName("p-splitter-gutter");
            document.getElementsByClassName("mainFormViewSplitterPanel").item(0).getElementsByClassName("p-paginator").item(0).classList.add("cursor_pointer")

            const event = {
                currentTarget: targets[0],
                target: e.target,
                pageX: e.pageX,
                pageY: e.pageY,
            }
            this.$emit('startTopSplitterPanel', event)
        },

        touchStartTopSplitterPanel(e) {
            const targets = document.getElementsByClassName("mainFormViewSplitterPanel")[0].getElementsByClassName("p-splitter-gutter");

            const event = {
                currentTarget: targets[0],
                target: e.target,
                pageX: e.pageX,
                pageY: e.pageY,
            }
            this.$emit('touchStartTopSplitterPanel', event)
        },

        moveTopSplitterPanel(e) {
            const targets = document.getElementsByClassName("mainFormViewSplitterPanel")[0].getElementsByClassName("p-splitter-gutter");

            const event = {
                currentTarget: targets[0],
                target: e.target,
                pageX: e.pageX,
                pageY: e.pageY,
            }
            this.$emit('moveTopSplitterPanel', event)
        },

        endTopSplitterPanel(e) {
            const targets = document.getElementsByClassName("mainFormViewSplitterPanel")[0].getElementsByClassName("p-splitter-gutter");

            const event = {
                currentTarget: targets[0],
                target: e.target,
                pageX: e.pageX,
                pageY: e.pageY,
            }
            this.$emit('endTopSplitterPanel', event)
        },

        async onSentNormalMailBulk() {
            const res = await this.$store.dispatch("sentMailStore/getBulkMailTemplate", {OPE: this.OPE})
            if(res.Success) {
                if (this.BulkCheckFormList.length == 1) {
                    this.$store.commit("sentMailStore/setTo", this.BulkCheckFormList[0].FromEmail)
                } else {
                    this.$store.commit("sentMailStore/setTo", undefined)
                }
                this.showMailDialog = true
            } else {
                this.$toast.add({
                    severity:'error', 
                    summary: this.$i18n.tc("utils.sendMailFailed"),
                    detail: this.$i18n.tc("utils.sendMailFailedByForm"), 
                    life: this.$constants.successHelperLife,
                    group: this.$constants.defaultToastGroup
                });
            }
        },

        async closeMailDialog(e={}) {
            if(e.sent) {
                const subjectText = this.$store.getters['sentMailStore/getSubject']
                const defaultText = this.$store.getters['sentMailStore/getDefaultText']
                const CCGroupEmails = this.$store.getters["sentMailStore/getCCGroupEmails"]
                const BCCGroupEmails = this.$store.getters["sentMailStore/getBCCGroupEmails"]
                const FromCompanyName = this.$store.getters["sentMailStore/getFromCompanyName"]
                const ReplyToAddress = this.$store.getters["sentMailStore/getReplyToAddress"]
                const params = {
                    subjectText,
                    defaultText,
                    CCGroupEmails,
                    BCCGroupEmails,
                    FromCompanyName,
                    ReplyToAddress,
                }
                this.sendBulkMail(params)
            } else {
                this.showMailDialog = false
            }
        },

        async onMoveDetailStatusForm() {
            const routeList = this.$store.getters["routeStore/getRouteList"]
            const activeRouteCode = this.$store.getters["routeStore/getActiveRouteCode"]
            const route = routeList.find(x => x.index == activeRouteCode)
            await this.$store.dispatch("detailFormStatus/setDetailFormStatusList", {DepartmentId: this.$route.params.departmentId, route: route})
            const detailFormStatusList = this.$store.getters["detailFormStatus/getDetailFormStatusList"].filter(x => x.IsVisible)

            if(detailFormStatusList.length == 0) {
                this.$toast.add({severity:'error', summary: this.$i18n.tc("operationButtonBar.cantMoveDetailStatus"), life: this.$constants.successHelperLife, group: this.$constants.defaultToastGroup});

                return ;
            }
            this.moveDetailStatusForm()
        },

        async moveDetailStatusForm() {
            this.displaySelectMovedDetailFormStatusDialog = true
        },

        async updateSelectMovedDetailFormStatusDialog(e) {
            this.displaySelectMovedDetailFormStatusDialog = false
            if(e !== false) {
                await this.onMoveDetailStatus({DetailFormStatusId: e})
            } else {
                this.NextStatusAction = ""
                this.NextRouteDetailFormStatusCode = ""
            }
            this.BulkCheckFormList = []
        },

        async onMoveDetailStatus(params) {
            const DetailFormStatusId = params.DetailFormStatusId
            console.log(DetailFormStatusId)
            this.$store.commit('detailFormStatus/setLastBulkOperationDetailFormIdInfo', {departmentId: this.$route.params.departmentId, detailFormStatusId: DetailFormStatusId, "status": this.NextStatusAction})
            if(this.NextStatusAction == "Accept") {
                await this.bulkAccept(DetailFormStatusId)
            } else if(this.NextStatusAction == "Reject") {
                await this.bulkReject(DetailFormStatusId)
            } else if(this.NextStatusAction == "Storage") {
                await this.bulkStorage(DetailFormStatusId)
            } else if(this.NextStatusAction == "Deleted") {
                await this.bulkDelete(DetailFormStatusId)
            } else {
                const DetailFormStatusId = params.DetailFormStatusId
                const BulkCheckFormList = this.BulkCheckFormList.map(x => x._id)
                const isSelectedAll = this.isSelectedAll
                const Status = this.$store.getters["viewPostedForm/getFormStatus"]
                const res = await this.$store.dispatch('viewPostedForm/moveDetailStatusFormBulk', {DetailFormStatusId: DetailFormStatusId, isSelectedAll: isSelectedAll, selectedFormId: BulkCheckFormList, formStatus: Status, DepartmentId: this.$route.params.departmentId})
                if(res.Success) {
                    this.$toast.add({severity:'success', summary: res.Message, life: this.$constants.successHelperLife, group: this.$constants.defaultToastGroup});
                } else {
                    this.$toast.add({severity:'error', summary: res.Message, life: this.$constants.successHelperLife, group: this.$constants.defaultToastGroup});
                }
                this.onBulkCheckBoxInput(false)
                this.$emit("reloadTable", true)
            }
            this.NextStatusAction = ""
            this.NextRouteDetailFormStatusCode = ""
            this.$store.commit("gridDataStore/setSelectedGridDataList", [])
        },

        async sendBulkMail(params) {
            params.BulkCheckFormList = this.BulkCheckFormList.map(x => x._id)
            params.isSelectedAll = this.isSelectedAll
            params.Status = this.$store.getters["viewPostedForm/getFormStatus"]
            params.OPE = this.OPE
            const res = await this.$store.dispatch('sentMailStore/sendBulkMail', params)

            if(res.Success) {
                this.$toast.add({severity:'success', summary: res.Message, life: this.$constants.successHelperLife, group: this.$constants.defaultToastGroup});
                this.showMailDialog = false
            } else if(res.ConfirmMessage) {
                this.$confirm.require({
                    group: "break",
                    isShowRejectButton: false,
                    header: this.$i18n.tc("utils.sendError"),
                    message: res.ConfirmMessage,
                    accept: function() {
                    },
                    reject: () => {
                    }
                })
            } else {
                this.$toast.add({severity:'error', summary: this.$i18n.tc("utils.sendMailFailed"), detail: this.$i18n.tc("utils.sendMailFailedByForm"), life: this.$constants.successHelperLife, group: this.$constants.defaultToastGroup});
                this.showMailDialog = false
            }
            this.$emit("reloadTable", true)
        },

        checkConfirmRequire: async function(message) {
            const self = this
            return new Promise(async function(resolve, reject) {
                self.$confirm.require({
                    message: message,
                    accept: function() {
                        resolve(true)
                    },
                    reject: () => {
                        resolve(false)
                    },
                })
            })
        },
    },
    directives: {
        'tooltip': Tooltip,
        'ripple': Ripple
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.unReadDocumentName {
    font-weight: bold;
}
.ellipsisIcon:focus,
.refreshIcon:focus {
    box-shadow: none;
}
</style>
