<template>

    <div class="customScrollPanel" style="position: relative;height: 100%; width:100%;">
        <ScrollPanel class="customScrollPanel trelloBoardScrollPanel" style="position: relative;height: 100%; width:100%;">
        <div class="p-d-flex p-flex-row" style="height: 100%;">
            <BlockUI :blocked=blockedDisplay class="p-d-flex p-flex-row" style="height: 100%;" @click="onBlockUIClick" @contextmenu="onBlockUIContextMenu">
            <template v-for="board in Boards" :key="board._id" >
                <!-- <div class="p-d-flex p-flex-column" style="height: 100%;  margin: 4px; padding: 4px; background-color: #fafbfc;"> -->
                <div class="p-d-flex p-flex-column" style="height: 100%;">
                    <span class="p-d-flex  align-item-content-center" style="width: 280px; height: 30px;" >
                        <span class="text-ellipsis  p-col" style="" v-tooltip="BoardTypeText(board)" >
                            {{BoardTypeText(board)}}
                        </span>
                        <i v-if="BoardTypeTextToolTip(board)" v-tooltip="BoardTypeTextToolTip(board)" class="pi pi-question-circle " style="" />
                    </span>
                    <div class="p-col p-d-flex p-flex-row" style="max-height: calc(100% - 30px); border: 1px;  align-items: flex-start; padding-left: 0px; padding-right: 0px;">
                        <template v-for="list in board.Lists" :key="list._id">
                            <div class="p-col p-d-flex p-flex-column" style="max-height: 100% ; width: 280px; background-color: #ebecf0; margin: 4px; border-radius: 6px;">
                                <div class=" p-d-flex p-flex-row" style="">
                                    <div class="p-col p-pr-0 p-pt-0 p-pb-0">
                                        <TrelloTitleInplace :BoardListId="list._id" :Title="list.Title" :ref="'title_inplate_' + list._id" />
                                    </div>
                                    <div class="p-ml-1" style="margin: 4px;">
                                        <i class="pi pi-file listOverlayPanelIcon" style="padding: 6px;" @click="onListFileIconClick($event, list, board)"></i>
                                        <i class="pi pi-ellipsis-v listOverlayPanelIcon" style="padding: 6px;" @click="onListOverlayPanelClick($event, list, board)"></i>
                                        <OverlayPanel :ref="'op_' + list._id"  :dismissable="true"  class="boardListHeadOverlayPanel">
                                            <Menu :model="boardListMenuItems">
                                                <template #item="{ item }">
                                                    <li class="pop-list" @click="onBoardListMenuItems($event, item.command, list._id)">
                                                        <a class="" href="#" >{{item.label}}</a>
                                                    </li>
                                                </template>
                                            </Menu>
                                        </OverlayPanel>
                                    </div>
                                </div>
                                <div class=" trelloBoardListScrollPanel " style="overflow:auto;">
                                    <draggable
                                        data-source="juju"
                                        v-bind="dragOptions"
                                        :list="list.Cards"
                                        class="list-group "
                                        style="height: 100%; width:100%;"
                                        :move="onMoveDragCard"
                                        @change="onMoveCallback($event, list)"
                                        group="a"
                                        delay=100
                                        item-key="name"
                                        >
                                        <template #item="{ element, index }">
                                            <div :style="{'position': element.isDragOver ? 'relative' : 'inherit'}">
                                            <Card 
                                                @click.stop="displayEditCardDialogClick($event, element, list)" 
                                                @click.right.prevent.stop="displayEditCardDialogRightClick($event, element, list)" 
                                                @mouseover="onEditCardDialogMouseOver($event, element)"
                                                @mouseleave="onEditCardDialogMouseLeave($event, element)"
                                                class="p-ripple cursor_pointer text-left editCardDialogSummary list-group-item"
                                                :class="{IsDirectEditCard: element.IsDirectEdit}"
                                                style="user-select: none"
                                                :ref="'cardRef_'+element._id"
                                                @dragover.prevent="onDrag('over', element)"  @drop.prevent="onDrop($event, element._id, element, list.BoardId)"
                                            >
                                            <template #header>
                                                <template v-if="element.IsHover" >
                                                    <div 
                                                          class="p-col-fixed text-right " 
                                                          style="position: absolute; right: 8px; width: 30px; height: 30px; z-index: 2;" 
                                                          @click.prevent="onEditCardDialogPencilClick($event, element, list)">
                                                        <i class="pi pi-pencil editCardDialogPencil" style=" " />
                                                    </div>
                                                </template>
                                                <div :class="{IsHoverEditCard: element.IsHover}">
                                                <template v-if="element.IsPinned" >
                                                    <div class=" text-right" style="padding-right: 6px;">
                                                        <i class="" style="fontSize: 0.6rem" >
                                                            <ThumbtackIcon class=" fa-rotate-45" />
                                                        </i>
                                                    </div>
                                                </template>
                                                <template v-if="$constants.imageExtList.includes(element.CoverFileNameExt)">
                                                    <div class="cursor_pointer coverImage" v-bind:style="{'backgroundImage': coverImageBackgroundURL(element.CoverImageUrl)}" />
                                                </template>
                                                <template v-else>
                                                    <a class="coverImage" >
                                                        <span class="attachmentThumbPreviewExt">{{element.CoverFileName}}</span>
                                                    </a>
                                                </template>
                                                    </div>


                                            </template>
                                                <template #content>
                                                    <TagLineField :BoardId="list.BoardId" :TagIdList="element.TagIdList ? element.TagIdList : []" IsSlim=true :class="{IsHoverEditCard: element.IsHover}" />
                                                    <template v-if="element.IsDirectEdit">
                                                        <Textarea 
                                                              type="text"
                                                              style=" width: 100%; height: 100%;padding: 5px;"
                                                              class="textfield IsDirectEditCardTextArea" 
                                                              v-model="element.Title"
                                                              ref="DirectEditRef"
                                                              autoResize
                                                            />
                                                    </template>
                                                    <template v-else>
                                                        <div class="p-grid" style="position: relative;" >
                                                        <div class="p-col" style="margin-top: 0.5rem;"  :class="{IsHoverEditCard: element.IsHover}">
                                                        {{element.Title}}
                                                        </div>
                                                        </div>
                                                    </template>
                                                </template>
                                                <template #footer>
                                                    <div style="width: 100%; text-align: left;padding-left: 0.4rem;" class="" :class="{IsHoverEditCard: element.IsHover}">
                                                        <span style="text-align: left" >{{index + 1}}</span>
                                                        <i class="p-pl-2 pi pi-list" style="fontSize: 0.73rem" v-if="element.HaveContent"></i>
                                                        <i class="p-pl-2 pi pi-file" style="fontSize: 0.73rem" v-if="element.HaveAttachment"></i>
                                                    </div>
                                                </template>
                                            </Card>
                                            <div v-if="element.isDragOver" class="droppingZone" @dragover.prevent="onDropzoneEnter" @dragleave.prevent="onDrag('leave', element)"  @drop.prevent="onDrop($event, element._id, element, list.BoardId)"> 
                                                <div class="droppingZoneContent">drop</div>
                                            </div>
                                            </div>
                                        </template>
                                    </draggable>
                                </div>
                                <div class="btn-group list-group-item" role="group" style="margin: 0.5rem 0.5rem  0rem 0.5rem;" v-if="!list.Cards.find(x => x.IsDirectEdit && x.IsNew)">
                                    <Button class="createNewCardButton" @click.stop="createNewCardButtonClick($event, list)" >{{$t("top.createNewCard")}}</Button>
                                </div>
                                <div class="btn-group list-group-item p-d-flex" role="group" style="margin: 0.5rem 0.5rem  0rem 0.5rem;" v-else>
                                    <Button class="createNewCardButton activeCreateNewCardButton" @click="activeCreateNewCardButtonClick($event, list)" >{{$t("top.createNewCard")}}</Button>
                                    <Button icon="pi pi-times" class="p-button-rounded p-button-text p-button-plain p-ml-2" @click="cancelCreateNewCardButtonClick($event, list)" style="font-size: 0.6rem" />
                                </div>
                            </div>
                        </template>
                        <div>



                        <div class="p-col-fixed p-d-flex p-flex-column newBoardListButton" style="" @click="createNewBoardListButtonClick($event, board)" v-if="board.Lists.length == 0"  v-tooltip="$t('top.createNewBoardListInfo')" >
                            <span style=""> 
                                <i class="pi pi-plus" style="" />
                                {{$t('top.createNewBoardList')}} 
                            </span>
                        </div>

                        </div>
                    </div>
                </div>
            </template >
            </BlockUI>

            <QuickEditCard
                    :blockedDisplay=blockedDisplay 
                    :quickEditCardElement=quickEditCardElement
                    @quickEditSave="quickEditSave($event)"
                    @openEditCard="openEditCard($event)"
                    :BoardId="quickEditCardElement.BoardId"
                    :BoardListId="quickEditCardElement.BoardListId"
                    :BoardCardId="quickEditCardElement.BoardCardId"
                    @updateDisplayEditCardDialog="blockedDisplay=$event"
                    ref="quickEditCardRef"
                    :saveQuickButtonLoading="saveQuickButtonLoading"
                    />





        </div>
        </ScrollPanel>
        <div v-if="progressing" class="progressSpinnerContainer" style="z-index:2;">
            <ProgressSpinner />
        </div>
    </div>
        <EditCardDialog 
            :displayEditCardDialog="displayEditCardDialog"
            :BoardCardId="BoardCardId"
            :BoardListId="BoardListId"
            :BoardId="BoardId"
            @updateDisplayEditCardDialog="displayEditCardDialog=$event"
        />
        <EditListCardDialog 
            :displayEditListCardDialog="displayEditListCardDialog"
            :BoardListId="BoardListId"
            @updateDisplayEditListCardDialog="displayEditListCardDialog=$event"
        />


</template>

<script>
// @ is an alias to /src

import Card from 'primevue/card';
import EditCardDialog from '@/components/board/EditCardDialog';
import EditListCardDialog from '@/components/EditListCardDialog';
import ScrollPanel from 'primevue/scrollpanel';
import TrelloTitleInplace from '@/components/board/TrelloTitleInplace';

import OverlayPanel from 'primevue/overlaypanel';
import Menu from 'primevue/menu';



import Button from 'primevue/button';
import Ripple from 'primevue/ripple';
import isMobile from '@/mixins/device'
import draggable from 'vuedraggable';

import Tooltip from 'primevue/tooltip'
import ThumbtackIcon from "@/components/Icons/thumbtackIcon"
import Textarea from 'primevue/textarea';
import TagLineField from "@/components/board/TagLineField"
import BlockUI from "primevue/blockui"
import QuickEditCard from "@/components/board/QuickEditCard"
import ProgressSpinner from 'primevue/progressspinner';



export default {
    name: 'Top',
    components: {
        ScrollPanel,
        Card,
        Button,
        OverlayPanel,
        Menu,
        EditCardDialog,
        EditListCardDialog,
        draggable,
        TrelloTitleInplace,
        ThumbtackIcon,
        Textarea,
        TagLineField,
        BlockUI,
        QuickEditCard,
        ProgressSpinner,
    },
    data: function() {
        return {
            isMobile,
            displayEditCardDialog: false,
            displayEditListCardDialog: false,
            BoardListId: "",
            BoardId: "",
            activeInPlace: false,
            overlayPanelTargetList: "",
            overlayPanelTargetBoard: "",
            boardListMenuItems: [
                {
                    label: this.$i18n.tc("top.createNewBoardList"),
                    icon: 'pi pi-plus',
                    command: (e, list, board) => {
                        this.createNewBoardListButtonClick(e, board)
                    }
                },
                {
                    label: this.$i18n.tc("top.deleteBoardList"),
                    icon: 'pi pi-trash',
                    command: (e, list) => {
                        this.deleteBoardList(e, list)
                    }
                },
                {
                    label: this.$i18n.tc("top.createNewCard"),
                    icon: 'pi pi-plus',
                    command: (e, list) => {
                        this.createNewCardButtonClick(e, list)
                        // this.$toast.add({severity:'success', summary:'Updated', detail:'Data Updated', life: 3000});
                    }
                }
            ],
            can_enter: false,
            blockedDisplay: false,
            quickEditCardElement: {},
            isDragOver: false,
            progressing: false,
            saveQuickButtonLoading: false,
        }
    },

    async beforeRouteEnter(route, redirect, next) {
        if(isMobile.isMobile()) {
            next("/admin/sent")
        } else {
            next()
        }
    },

    created() {
        this.initBoard()
    },
    computed: {
        Boards: {
            get: function() {
                const AllBoards = this.$store.getters["trello/getAllBoards"]
                return AllBoards
            }
        },
        dragOptions: {
            get: function() {
                return {
                    animation: 400,
                    group: {
                        name: "g1"
                    },
                    scrollSensitivity: 200,
                    forceFallback: true,
                    disabled: false,
                    ghostClass: "ghost"
                }
            }
        },
    },
    methods: {
        async initBoard() {
            this.progressing = true
            await this.$store.dispatch("trello/getAllBoards")
            await this.$store.dispatch("trello/getAllBoardTagList")
            this.progressing = false
        },
        BoardTypeText: function(board) {
            const BoardType = board.BoardType
            const Label = board.Label
            if (BoardType == "Company") {
                return Label
                // return this.$i18n.tc("top.Company") + " (" + Label + ")"
            } else if (BoardType == "Department") {
                return Label
                // return this.$i18n.tc("top.Department") + " (" + Label + ")"
            } else if (BoardType == "Personal") {
                return this.$i18n.tc("top.Personal")
            }
        },

        BoardTypeTextToolTip: function(board) {
            const BoardType = board.BoardType
            if (BoardType == "Company") {
                return this.$i18n.tc("top.BoardTypeTextToolTip.Company")
            } else if (BoardType == "Department") {
                return this.$i18n.tc("top.BoardTypeTextToolTip.Department")
            } else {
                return this.$i18n.tc("top.BoardTypeTextToolTip.Personal")
            }
        },

        async quickEditSave(e) {
            this.saveQuickButtonLoading = true
            await this.DirectEditCardBlur(e.e, e.element)
            this.blockedDisplay = false
            this.$refs.quickEditCardRef.switchSaving(false)
            this.saveQuickButtonLoading = false

        },

        onBlockUIContextMenu(e) {
            if(this.blockedDisplay) {
                e.preventDefault()
            }
        },

        async onBlockUIClick(e) {
            if(this.blockedDisplay) {
                this.quickEditCardElement.IsDirectEdit = true
                this.quickEditCardElement.editDone = false
                // await this.DirectEditCardBlur(e.e, this.quickEditCardElement, true)
                await this.$store.dispatch("trello/getCardInfoByIdAndUpdateBack", {_id: this.quickEditCardElement._id, BoardId: this.quickEditCardElement.BoardId})
                this.blockedDisplay = false
            }
        },

        DirectEditCardBlur: async function(e, element, afterLoad=false) {
            const self = this;
            if(element.IsNew) {
                const Board = this.Boards.find(b => b._id == element.BoardId)
                const List = Board.Lists.find(l => l._id == element.BoardListId)
                List.Cards = List.Cards.filter(x => !x.IsNew) 
                const value = element.Title ? element.Title.trim() : ""
                const res = await this.createNewCard(value, element)
                if(res) {
                    await this.$store.dispatch("trello/getBoardListCards", {BoardListId: List._id, BoardId: List.BoardId })
                    this.blockedDisplay = false
                }
            } else {
                setTimeout(async function() {
                    if(element && element.IsDirectEdit && !element.editDone) {
                        const value = element.Title ? element.Title.trim() : ""
                        const Board = self.Boards.find(b => b._id == element.BoardId)
                        const List = Board.Lists.find(l => l._id == element.BoardListId)
                        for (let i = 0; i < List.Cards.length; i++) {
                            const Card = List.Cards[i]
                            if(Card.IsDirectEdit) {
                                Card.Title = value
                                Card.IsDirectEdit = false
                            }
                        }
                        await self.createNewCard(value, element)
                        await self.$store.dispatch("trello/getBoardListCards", {BoardListId: List._id, BoardId: List.BoardId })
                        // const res = await self.createNewCard(value, element, List)
                        // let loaded = false
                        // if(res) {
                        //     await self.$store.dispatch("trello/getBoardListCards", {BoardListId: List._id, BoardId: List.BoardId })
                        //     self.blockedDisplay = false
                        //     loaded = true 
                        // } else {
                        //     self.$toast.add({
                        //         severity: 'error',
                        //         summary: res.Message ? res.Message : self.$i18n.tc("utils.failed"),
                        //         life: self.$constants.successHelperLife,
                        //         group: self.$constants.defaultToastGroup
                        //     });
                        // }
                        // if (!loaded && afterLoad) {
                        //     await self.$store.dispatch("trello/getBoardListCards", {BoardListId: List._id, BoardId: List.BoardId })
                        //     self.blockedDisplay = false
                        // }
                    }
                }, 100);
            }

        },

        displayEditCardDialogRightClick: async function(e, card, list) {
            card.IsDirectEdit = true
            const ref = 'cardRef_' + card._id
            const element = this.$refs[ref][0].$el
            const offsetTop = card.IsNew ? this.$refs[ref][0].$el.offsetTop : element.getBoundingClientRect().top - 45
            const offsetLeft = this.$refs[ref][0].$el.offsetLeft
            const offsetWidth = this.$refs[ref][0].$el.offsetWidth
            const clientWidth = document.body.clientWidth
            const elementClientLeft = this.$refs[ref][0].$el.getBoundingClientRect().left + 100
            card.quickCardTop = offsetTop
            card.quickCardLeft = offsetLeft
            card.quickCardWidth = offsetWidth
            card.quickCardZIndex = 10000
            this.quickEditCardElement = {
                ...card,
                subItemPosition: (elementClientLeft + offsetWidth >= clientWidth) ? "right" : "left",
                quickCardTop: offsetTop,
                quickCardLeft: offsetLeft,
                quickCardWidth: offsetWidth,
                quickCardZIndex: 10000,
                BoardId: list.BoardId,
                BoardListId: list._id,
                BoardCardId: card._id,
                _id: card._id,
                Title: card.Title,
            }
            this.$store.commit("activeBoardCard/clear")
            this.$store.commit("activeBoardCard/setId", card._id)
            this.$store.commit("activeBoardCard/setTitle", card.Title)
            this.$store.commit("activeBoardCard/setContent", card.Content)
            this.$store.commit("activeBoardCard/setUpdatedDate", card.UpdatedDate)
            this.$store.commit("activeBoardCard/setIsPinned", card.IsPinned)
            this.$store.commit("activeBoardCard/setCoverImageUrl", card.CoverImageUrl)
            this.$store.commit("activeBoardCard/setAttachmentList", card.AttachmentList)
            this.$store.commit("activeBoardCard/setCoverFileNameExt", card.CoverFileNameExt)
            this.$store.commit("activeBoardCard/setCoverFileName", card.CoverFileName)
            this.$store.commit("activeBoardCard/setNo", card.No)
            this.$store.commit("activeBoardCard/setBelongToTitle", card.BelongToTitle)
            this.$store.commit("activeBoardCard/setListName", card.ListName)
            this.$store.commit("activeBoardCard/setTagIdList", card.TagIdList)

            this.blockedDisplay = true
            if (!card.IsNew) {
                const res = await this.$store.dispatch("activeBoardCard/getCardInfoById", card._id)
                if(res.Success) {
                    const id = this.$store.getters["activeBoardCard/getId"]
                    if(card._id == id) {
                        this.$store.dispatch('activeBoardCard/setCardInfo', res)
                    }
                }
            }
        },

        displayEditCardDialogClick: async function(e, card, list) {
            if(card.IsDirectEdit) return;

            
            this.$store.commit("activeBoardCard/clear")
            this.$store.commit("activeBoardCard/setId", card._id)
            this.$store.commit("activeBoardCard/setTitle", card.Title)
            this.$store.commit("activeBoardCard/setContent", card.Content)
            this.$store.commit("activeBoardCard/setUpdatedDate", card.UpdatedDate)
            this.$store.commit("activeBoardCard/setIsPinned", card.IsPinned)
            this.$store.commit("activeBoardCard/setCoverImageUrl", card.CoverImageUrl)
            this.$store.commit("activeBoardCard/setCoverFileNameExt", card.CoverFileNameExt)
            this.$store.commit("activeBoardCard/setCoverFileName", card.CoverFileName)
            this.$store.commit("activeBoardCard/setAttachmentList", card.AttachmentList)
            this.$store.commit("activeBoardCard/setNo", card.No)
            this.$store.commit("activeBoardCard/setBelongToTitle", card.BelongToTitle)
            this.$store.commit("activeBoardCard/setListName", card.ListName)
            this.$store.commit("activeBoardCard/setTagIdList", card.TagIdList)
            

            this.BoardCardId = card._id
            this.BoardListId = list._id
            this.BoardId = list.BoardId

            this.displayEditCardDialog = true
            const res = await this.$store.dispatch("activeBoardCard/getCardInfoById", card._id)
            if(res.Success) {
                const id = this.$store.getters["activeBoardCard/getId"]
                if(card._id == id) {
                    this.$store.dispatch('activeBoardCard/setCardInfo', res)
                }
            }
        },

        async openEditCard(e) {
            if(this.blockedDisplay) {
                const element = e.element
                const value = element.Title ? element.Title.trim() : ""
                const Board = this.Boards.find(b => b._id == element.BoardId)
                const List = Board.Lists.find(l => l._id == element.BoardListId)
                for (let i = 0; i < List.Cards.length; i++) {
                    const Card = List.Cards[i]
                    if(Card.IsDirectEdit) {
                        Card.Title = value
                        Card.IsDirectEdit = false
                        List.Cards.splice(i, 1, Card)
                    }
                }
                const success = await this.createNewCard(value, element)
                if(success) {
                    this.blockedDisplay = false
                    this.displayEditCardDialog = true
                }
            }
        },

        cancelCreateNewCardButtonClick: function(e, list) {
            const index = list.Cards.findIndex(c => c.IsDirectEdit)
            if(index != -1) {
                list.Cards[index].editDone = true
                list.Cards.splice(index, 1)
            }
        },

        createNewCard: async function(value, card) {
                let res = {}
                card.IsDirectEdit = false
                if(card.IsNew) {
                    res = await this.$store.dispatch("trello/createNewCard", {BoardListId: card.BoardListId, Title: value})
                    if (res.Success) {
                        if(card.attachment4New) {
                            card.attachment4New.append("_id", res.CardId)
                            await this.$store.dispatch("activeBoardCard/addCardCoverImage", {form: card.attachment4New, _id: res.CardId})
                        }
                        if (res.Message) {
                            this.$toast.add({
                                severity: 'success',
                                summary: res.Message,
                                life: this.$constants.successHelperLife,
                                group: this.$constants.defaultToastGroup
                            });
                        }
                    } else {
                        this.$toast.add({
                            severity: 'error',
                            summary: res.Message ? res.Message : this.$i18n.tc("utils.failed"),
                            life: this.$constants.successHelperLife,
                            group: this.$constants.defaultToastGroup
                        });
                    }
                } else {
                    res = await this.$store.dispatch("trello/updateCardTitle", {_id: card._id, Title: value})
                    if (!res.Success) {
                        this.$toast.add({
                            severity: 'error',
                            summary: res.Message ? res.Message : this.$i18n.tc("utils.failed"),
                            life: this.$constants.successHelperLife,
                            group: this.$constants.defaultToastGroup
                        });
                    }
                }

                return res.Success
        },

        activeCreateNewCardButtonClick: async function(e, list) {
            const card = list.Cards.find(x => x.IsDirectEdit)
            if(card) {
                card.editDone = true
                const value = card.Content ? card.Content.trim() : ""
                this.createNewCard(value, card)
            }

        },

        createNewCardButtonClick: async function(e, list) {
            this.$store.commit("trello/clear")
            this.addNewCard(e, list)
            return

            const res = await this.$store.dispatch("trello/createNewCard", {BoardListId: list._id})
            if(res.Success) {
                await this.$store.dispatch("activeBoardCard/getCardInfoById", res.CardId)
                this.BoardCardId = res.CardId
                this.BoardListId = list._id
                this.BoardId = list.BoardId
                this.displayEditCardDialog = true
                await this.$store.dispatch("trello/getBoardListCards", {BoardListId: list._id, BoardId: list.BoardId })
            }
        },

        addNewCard: async function(e, list) {
            const Board = this.Boards.find(b => b._id == list.BoardId)
            if(Board) {
                const List = Board.Lists.find(l => l._id == list._id)
                if(List) {

                    const card = this.$store.getters["activeBoardCard/getInitData"]
                    // card.IsDirectEdit = true
                    card.IsNew = true
                    card.Title = ""
                    card.Content = ""
                    card.BoardId = list.BoardId
                    card.BoardListId = list._id
                    card._id = ""


                    List.Cards.unshift(card)

                    const self = this
                    this.$nextTick(function() {
                        self.displayEditCardDialogRightClick(e, card, list)
                        // if(this.$refs.DirectEditRef && this.$refs.DirectEditRef.$el)
                        //     this.$refs.DirectEditRef.$el.focus()
                    });
                }
            }
        },

        async blurEditContentTextarea(e, list) {
            const self = this;
            setTimeout(async function() {
                if(self.activeInPlace) {
                    await self.$store.dispatch("trello/setBoardListTitle", {value: e.target.value, BoardListId: list._id})
                    self.activeInPlace = false
                }
            }, 200);
        },

        async createNewBoardListButtonClick(e, board) {
            this.progressing = true
            const res = await this.$store.dispatch("trello/createNewBoardList", {BoardId: board._id})
            if(res.Success) {
                await this.$store.dispatch("trello/getBoardLists", {BoardId: board._id})
                const _id = res._id
                if (this.$refs["title_inplate_" + _id] && this.$refs["title_inplate_" + _id].length) {
                    this.$refs["title_inplate_" + _id][0].openInplace()
                }
            }
            this.progressing = false

        },

        async deleteBoardList(e, list) {
            const self = this;
            const BoardId = list.BoardId
            const BoardListId = list._id

            this.$confirm.require({
                message: self.$i18n.tc('top.deleteBoardListConfirm'),
                autoFocus: "reject",
                accept: async function () {
                    const res = await self.$store.dispatch("trello/deleteBoardList", {BoardListId: BoardListId, BoardId: BoardId})
                    if(res.Success) {
                        await self.$store.dispatch("trello/getBoardLists", {BoardId: BoardId})
                    }
                },
                reject: () => {
                }
            })
        },

        openInplace() {
            this.activeInPlace = true
        },

        async onListFileIconClick (e, list, board) {
            this.$store.commit("trello/setListContent", "")
            this.$store.commit("trello/setListUpdatedDate", null)
            await this.$store.dispatch("trello/getListContentInfoById", list._id)
            this.BoardListId = list._id
            this.BoardId = list.BoardId
            this.displayEditListCardDialog = true
        },

        onListOverlayPanelClick(e, list, board) {
            this.overlayPanelTargetList = list
            this.overlayPanelTargetBoard = board
            const refId = "op_" + list._id
            this.$refs[refId][0].toggle(e);
        },

        onBoardListMenuItems(e, command, listId) {
            command({}, this.overlayPanelTargetList, this.overlayPanelTargetBoard)
            const refId = "op_" + listId
            this.$refs[refId][0].toggle(e);
        },

        onMoveDragCard(e){ 
            return !e.draggedContext.element.IsPinned && !e.draggedContext.element.IsDirectEdit;
        },

        onMoveCallback: async function(e, list){ 
            const pinnedMaxNumber = Math.max(...list.Cards.filter(x => x.IsPinned).map(y => y.No))
            if(e.added) {
                const card = e.added.element
                let newIndex = e.added.newIndex + 1
                const direction = "INSERT"
                if(newIndex <= pinnedMaxNumber ) {
                    newIndex = pinnedMaxNumber + 1
                }
                const res = await this.$store.dispatch("trello/insertedCard", {Card: card, newIndex: newIndex, OldBoardListId: card.BoardListId, NewBoardListId: list._id, Direction: direction})
                if(!res.Success && res.Message) {
                    this.$toast.add({
                        severity: 'error',
                        summary: res.Message,
                        life: this.$constants.successHelperLife,
                        group: this.$constants.defaultToastGroup
                    });
                }
                await this.$store.dispatch("trello/getBoardListCards", {BoardListId: list._id, BoardId: list.BoardId })

                let oldList = undefined
                for (let i = 0; i < this.Boards.length; i++) {
                    oldList = this.Boards[i].Lists.find(x => x._id == card.BoardListId)
                    if (oldList) {
                        await this.$store.dispatch("trello/getBoardListCards", {BoardListId: card.BoardListId, BoardId: oldList.BoardId })
                        break
                    }
                }

            } else if(e.removed) {
                // const card = e.removed.element
                // const oldIndex = e.removed.oldIndex + 1

                // const res = await this.$store.dispatch("trello/insertedCard", {Card: card, oldIndex: oldIndex , NewBoardListId: list._id, Direction: "DELETE"})
                // if(!res.Success && res.Message) {
                //     this.$toast.add({
                //         severity: 'error',
                //         summary: res.Message,
                //         life: this.$constants.successHelperLife,
                //         group: this.$constants.defaultToastGroup
                //     });
                // }
                // await this.$store.dispatch("trello/getBoardListCards", {BoardListId: list._id, BoardId: list.BoardId })
            } else if(e.moved) {
                const card = e.moved.element
                let newIndex = e.moved.newIndex + 1
                const oldIndex = e.moved.oldIndex + 1



                if(newIndex <= pinnedMaxNumber ) {
                    newIndex = pinnedMaxNumber + 1
                }

                const direction =  newIndex >= oldIndex ? "DOWN" : "UP"
                const res = await this.$store.dispatch("trello/insertedCard", {Card: card, newIndex: newIndex , oldIndex: oldIndex, NewBoardListId: list._id, Direction: direction})
                if(!res.Success && res.Message) {
                    this.$toast.add({
                        severity: 'error',
                        summary: res.Message,
                        life: this.$constants.successHelperLife,
                        group: this.$constants.defaultToastGroup
                    });
                }
                await this.$store.dispatch("trello/getBoardListCards", {BoardListId: list._id, BoardId: list.BoardId })
            }
        },

        onDirectEditCardKeyDown: async function() {
            this.$refs.DirectEditRef.$el.blur()
        },

        enable_enter: function(e) {
            this.can_enter = true;
        },

        enter: function(e) {
            if (!this.can_enter) return;
            this.onDirectEditCardKeyDown()
            this.can_enter = false;

        },

        coverImageBackgroundURL: function(url) {
            if(url) {
                return `url(${url})`
            }

            return ""
        },

        onEditCardDialogMouseOver: function(e, element) {
            element.IsHover = true
        },

        onEditCardDialogMouseLeave: function(e, element) {
            element.IsHover = false
        },

        onEditCardDialogPencilClick: function(e, element, list) {
            this.displayEditCardDialogRightClick(e, element, list)
        },

        onDrag(type, element) {
            element.isDragOver = type === "over";
        },

        onDropzoneEnter(e){ 
            e.preventDefault()
        },

        async onDrop(event, _id, element, BoardId) {
            element.isDragOver = false;
            const files = event.dataTransfer.files;

            for (let i = 0; i < files.length; i++) {
                let form = new FormData()
                form.append('file', files[i])
                // this.isUploading = true
                await this.$store.dispatch("activeBoardCard/addCardCoverImage", {form: form, _id: _id})
            }
            await this.$store.dispatch("trello/getCardInfoByIdAndUpdateBack", {_id: _id, BoardId: BoardId})
            
            // this.isUploading = false
        },
    },
    directives: {
        'ripple': Ripple,
        'tooltip': Tooltip
    }
}
</script>

<style scoped>
.pop-list {
}
.pop-list > a{
    color: #172b4d;
    cursor: pointer;
    display: block;
    font-weight: 400;
    margin: 0 -12px;
    padding: 6px 12px;
    position: relative;
    text-decoration: none;
}
.pop-list > a:hover{
    background-color: #091e420a;
}
.p-overlaypanel-content {
    border: none;
}
.listOverlayPanelIcon:hover {
    cursor: pointer;
    background-color: #091e420a;
}
.newBoardListButton {
    width: 280px;
    background-color: #ebecf0; 
    margin: 4px;
    border-radius: 6px;
    text-align: center;
    opacity: 0.8;
}
.newBoardListButton:hover {
    cursor: pointer;
    opacity: 1;
}
.newBoardListButton span {
    margin: 0 auto;
}
.newBoardListButton i {
    height: 100%;
    vertical-align: middle;
}
.createNewCardButton {
    width: 100%;
    border-color: #ebecf0;
    background-color: #ebecf0;
    border-radius: 3px;
    color: #5e6c84;
    display: block;
    position: relative;
    text-decoration: none;
    -webkit-user-select: none;
    user-select: none;
}
.createNewCardButton:hover {
    color: #172b4d;
    border-color: #091e4214;
    background-color: #091e4214;
}
.activeCreateNewCardButton {
    color: white;
    background-color: #0079bf;
    border: none;
}
.activeCreateNewCardButton:hover {
    color: white;
    background-color: #026aa7;
    border: none;
}
.coverImage {
    min-height: 120px;
    max-height: 120px;
    object-fit: contain;
    background-size: contain;
    background-origin: content-box;
    padding: 0px;
    box-sizing: border-box;
    background-position: center center;
    background-repeat: no-repeat;
}
.attachmentThumbPreviewExt {
    color: #5e6c84;
    display: block;
    font-size: 18px;
    font-weight: 700;
    height: 100%;
    line-height: 20px;
    padding-left: 10px;
    padding-right: 10px;
    word-break: break-all;
    word-wrap: break-word;
    text-align: left;
    text-decoration: none;
    width: 100%;
}
.editCardDialogPencil {
    font-size: 0.7rem;
    background-clip: padding-box;
    background-color: #f4f5f787;
    background-origin: initial;
    border-radius: 3px;
    padding: 8px;
}
.editCardDialogPencil:hover {
    color: #172b4d;
    background-color: #ebecf0;
}
.droppingZone {
    bottom: 0;
    font-size: 20px;
    font-weight: 700;
    height: 100%;
    left: 0;
    line-height: 30px;
    opacity: 1;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    width: auto;
    background: #ffffffb3;
    margin-top: 0px;
}
.droppingZoneContent {
    margin: 0;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
    margin-right: -50%;
}
</style>
